import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import {
  Button,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { theme } from "../../partials/utils/Contants";
import { ReactComponent as PdfIcon } from "../../partials/assets/icons/pdf-icon.svg";
import { HiOutlineSparkles } from "react-icons/hi2";
import { BiDownload } from "react-icons/bi";
import { addCommas } from "../../partials/utils/helper";
import ProposalCard from "../ProposalCard";

const Proposals = ({ id }: any) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [quoteList, setQuoteList] = useState<any>([]);
  const [showSelection, setShowSelection] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState({ id: null });
  function fetchBidData() {
    useFetch
      .get(`quotes/${id}`)
      .then((res) => {
        setQuoteList(res.data?.quote_files);
      })
      .catch((error) => {});
  }
  useEffect(() => {
    if (id) {
      fetchBidData();
    }
  }, [id]);
  const handleDownloadPDF = (
    id: string,
    type: "quote-file" | "policy-file"
  ) => {
    useFetch
      .get(`download-${type}/${id}`, { responseType: "blob" })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `policy_${id}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        toast({
          title: "Downloaded Successfully",
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.error("Error downloading the PDF:", error);
        toast({
          title: "Dowload failed!",
          description: `${
            error?.response?.data?.message ||
            "There was an issue Downloading pdf. Please try again."
          }`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };
  const handleAcceptQuote = () => {
    setIsSubmitting(true);
    useFetch
      .post(`quotes/${selectedQuote?.id}/accept`)
      .then((res) => {
        toast({
          title: "Quote accepted Successfully",
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        onClose();
        fetchBidData();
        setSelectedQuote({ id: null });
      })
      .catch((error) => {
        toast({
          title: "Something Went wrong",
          description: error?.response?.data?.message || "",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => setIsSubmitting(false));
  };

  const hasAccepted = quoteList?.find((x: any) => x?.is_accepted === 1);
  return (
    <>
      {!quoteList?.length ? (
        <Flex height="20vh" align="end">
          <Text color="white">No Proposals found for this bid</Text>
        </Flex>
      ) : (
        <Flex
          w="100%"
          gap="30px"
          direction="column"
          p="0px 14px 25px 0px"
          color="white"
        >
          {quoteList.map((data: any) => {
            const isAccepted = data?.is_accepted === 1;
            let status = "Select";
            if (hasAccepted && isAccepted) {
              if (!data?.policy_file) {
                status = "Policy Pending";
              } else {
                status = "Policy Complete";
              }
            } else if (hasAccepted && !isAccepted) {
              status = "Declined";
            }
            return (
              <ProposalCard
                key={data.id}
                data={data}
                handleDownloadPDF={handleDownloadPDF}
                onOpen={onOpen}
                setSelectedQuote={setSelectedQuote}
                setShowSelection={setShowSelection}
                showSelection={showSelection}
                status={status}
              />
            );
          })}
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent borderRadius="21px" pb="35px">
              <ModalCloseButton />
              <ModalBody pt="50px">
                <Flex direction="column" align="center">
                  <Text fontWeight="600">
                    Are you sure you want to Accept this Quote
                  </Text>
                </Flex>
                <Flex
                  align="center"
                  gap="20px"
                  w="full"
                  justify="center"
                  mt="20px"
                >
                  <Button
                    borderRadius="21px"
                    onClick={() => {
                      onClose();
                      setSelectedQuote({ id: null });
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    borderRadius="21px"
                    colorScheme="green"
                    background={theme.color.GREEN}
                    onClick={handleAcceptQuote}
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting}
                  >
                    Accept
                  </Button>
                </Flex>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Flex>
      )}
    </>
  );
};

export default Proposals;
