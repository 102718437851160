// Declare OTPlessSignin as a global variable of the OTPless type or `null` initially.
let OTPlessSignin: OTPless | null = null;

interface AuthenticateParams {
  channel?: 'EMAIL' | 'PHONE' | 'OAUTH';
  channelType?: string;
  phone?: string;
  countryCode?: string;
  email?: string;
}

interface VerifyOTPParams {
  channel?: 'EMAIL' | 'PHONE';
  otp: string;
  countryCode?: string;
  phone?: string;
  email?: string;
}

// Assuming OTPless is a class with an `initiate` and `verify` method.
// Define OTPless interface based on the methods used in your code.
interface OTPless {
  initiate(params: AuthenticateParams): Promise<any>;
  verify(params: VerifyOTPParams): Promise<any>;
}

/**
 * Creates a script element to load the OTPless SDK.
 *
 * @return {void} No return value
 */
const loadScript = (): void => {
  const script = document.createElement('script');
  script.id = 'otpless-sdk';
  script.type = 'text/javascript';
  script.src = 'https://otpless.com/v2/headless.js';
  // Get your app id from https://otpless.com/dashboard/customer/dev-settings
  script.setAttribute('data-appid', '50P8K0X5ANAFF74K36L2');
  // TODO: Add your app id here
  document.head.appendChild(script);
};

/**
 * Initializes the OTPless SDK and sets up a callback function.
 *
 * @param {Function} callback - The callback function to be executed after successful authentication.
 * @return {void} No return value.
 */
export const initOTPless = (callback: Function): void => {
  // Loading the script if it's not already loaded
  if (!document.getElementById('otpless-sdk')) loadScript();

  // Initializing the OTPless SDK after 1 second to allow the script to load.
  setTimeout(() => {
    // @ts-ignore: Assuming `window.OTPless` is the OTPless class constructor.
    OTPlessSignin = new window.OTPless(callback);
  }, 1000);
};

/**
 * Authenticates the user using any authentication method available.
 * Email / Phone, OTP / Magic Link / Social Authentications
 *
 * @param {AuthenticateParams} params - The parameters for primary authentication.
 * @return {Promise} A promise that resolves with the result of the authentication.
 */
export const Authenticate = ({
  channel = 'PHONE',
  channelType,
  phone,
  countryCode = '+91',
  email
}: AuthenticateParams): Promise<any> => {
  if (channel !== 'OAUTH' && channel !== 'EMAIL' && channel !== 'PHONE') {
    return Promise.reject({
      success: false,
      statusCode: 400,
      errorMessage: `Invalid channel ${channel}`
    });
  }
  if (channel === 'EMAIL' && !email) {
    return Promise.reject({
      success: false,
      statusCode: 400,
      errorMessage: 'Email is required'
    });
  }
  if (channel === 'PHONE' && !phone) {
    return Promise.reject({
      success: false,
      statusCode: 400,
      errorMessage: 'Phone is required'
    });
  }
  if (channel === 'OAUTH' && !channelType) {
    return Promise.reject({
      success: false,
      statusCode: 400,
      errorMessage: 'Channel type is required'
    });
  }
  return OTPlessSignin ? OTPlessSignin.initiate({
    channel,
    channelType,
    phone,
    email,
    countryCode,
  }) : Promise.reject({
    success: false,
    statusCode: 500,
    errorMessage: 'OTPlessSignin is not initialized'
  });
};

/**
 * Verifies the OTP (One-Time Password) for the given authentication channel.
 *
 * @param {VerifyOTPParams} params - The parameters for verifying the OTP.
 * @return {Promise} A promise that resolves with the result of the verification.
 */
export const verifyOTP = ({
  channel = 'PHONE',
  otp,
  countryCode = '+91',
  phone,
  email
}: VerifyOTPParams): Promise<any> => {
  if (channel !== 'EMAIL' && channel !== 'PHONE') {
    return Promise.reject({
      success: false,
      statusCode: 400,
      errorMessage: `Invalid channel ${channel}`
    });
  }
  if (channel === 'EMAIL' && !email) {
    return Promise.reject({
      success: false,
      statusCode: 400,
      errorMessage: 'Email is required'
    });
  }
  if (channel === 'PHONE' && !phone) {
    return Promise.reject({
      success: false,
      statusCode: 400,
      errorMessage: 'Phone is required'
    });
  }
  return OTPlessSignin ? OTPlessSignin.verify({
    channel,
    phone,
    email,
    otp,
    countryCode,
  }) : Promise.reject({
    success: false,
    statusCode: 500,
    errorMessage: 'OTPlessSignin is not initialized'
  });
};
