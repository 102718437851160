import { useRef, useEffect, useState } from "react";
import { Flex, Text, Button, Icon } from "@chakra-ui/react";
import { ReactComponent as PdfIcon } from "../../partials/assets/icons/pdf-icon.svg";
import { HiOutlineSparkles } from "react-icons/hi2";
import { BiDownload } from "react-icons/bi";
import { addCommas } from "../../partials/utils/helper";
import { theme } from "../../partials/utils/Contants";

interface CardData {
  id: string;
  insurance_company_name: string;
  bidder_name?: string;
  claim_amount: number;
  quote_file?: string;
  policy_file?: string;
}

// Define the type for the props
interface ProposalCardProps {
  data: CardData;
  showSelection: string | null;
  setShowSelection: (id: any) => void;
  status: string;
  setSelectedQuote: (quote: any) => void;
  onOpen: () => void;
  handleDownloadPDF: (id: string, type: "quote-file" | "policy-file") => void;
}
const ProposalCard = ({
  data,
  showSelection,
  setShowSelection,
  status,
  setSelectedQuote,
  onOpen,
  handleDownloadPDF,
}: ProposalCardProps) => {
  const cardRef = useRef<HTMLDivElement | null>(null);
  const [cardHeight, setCardHeight] = useState<number>(0);

  // Update the card height whenever the component mounts or data changes
  useEffect(() => {
    if (cardRef.current) {
      setCardHeight(cardRef.current.getBoundingClientRect().height - 1);
    }
  }, [data, showSelection]);

  return (
    <Flex
      align="center"
      key={data?.id}
      pos="relative"
      shadow="md"
      maxH="fit-content"
      direction="row"
    >
      <Flex
        ref={cardRef}
        zIndex="1"
        direction="column"
        gap="12px"
        width={showSelection === data?.id ? "90%" : "100%"}
        className="bid-card-selected"
        padding="12px"
        position="relative"
        height="100%"
        minH="130px"
        maxH="fit-content"
        borderRadius="21px"
        cursor="pointer"
        onClick={(e) => {
          e.stopPropagation();
          setShowSelection(data?.id);
        }}
      >
        <Flex width="100%" align="center" gap="8px" wrap="wrap">
          <Text fontSize="15px" fontWeight="700" color="white" cursor="default">
            {data?.insurance_company_name}
          </Text>
          {data?.bidder_name && (
            <Text
              cursor="default"
              fontSize="15px"
              fontWeight="700"
              color="rgba(255, 255, 255, 0.50)"
            >
              | {data?.bidder_name}
            </Text>
          )}
        </Flex>
        <Flex width="100%" align="center" justify="space-between">
          <Text
            fontSize="12px"
            fontWeight="700"
            color="rgba(131, 131, 131, 1)"
            whiteSpace="nowrap"
            cursor="default"
          >
            ₹{addCommas(data?.claim_amount)}
          </Text>
        </Flex>
        <Flex width="100%" justify="space-between" align="center">
          <Flex direction="column" gap="8px">
            <Button
              leftIcon={<PdfIcon />}
              size="xs"
              fontSize="12px"
              borderRadius="16px"
              onClick={() => handleDownloadPDF(data?.id, "quote-file")}
              colorScheme="green"
              bg={theme.color.GREEN}
            >
              Download Proposal
            </Button>
            {data?.policy_file && (
              <Button
                leftIcon={<PdfIcon />}
                size="xs"
                fontSize="12px"
                borderRadius="16px"
                onClick={() => handleDownloadPDF(data?.id, "policy-file")}
                colorScheme="green"
                bg={theme.color.GREEN}
              >
                Download Policy
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>

      {showSelection === data?.id && (
        <Flex
          height={`${cardHeight}px`}
          bg={status === "Declined" ? "red" : theme.color.GREEN}
          position="absolute"
          right="0px"
          w="100%"
          borderRadius="21px"
          align="center"
          justify="end"
          cursor="pointer"
          transition="background-color 1s ease-in, width 0.3s ease-in"
          padding="0px"
          onClick={() => {
            if (status === "Select") {
              setSelectedQuote({ id: data?.id });
              onOpen();
            }
          }}
        >
          <Flex
            transform="rotate(-90deg)"
            position="relative"
            align="end"
            justify="center"
          >
            <Text
              color="white"
              fontSize="12px"
              fontWeight="500"
              whiteSpace="nowrap"
              pos="absolute"
              textAlign="end"
              pb="5px"
            >
              {status}
            </Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default ProposalCard;
