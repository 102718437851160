import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
  Text,
  Icon,
  Button,
  useToast,
  Badge,
  Skeleton,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import moment from "moment";
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";
import { theme } from "../../partials/utils/Contants";
import { ReactComponent as PdfIcon } from "../../partials/assets/icons/pdf-icon.svg";

const BidsList = ({ id }: { id: string }) => {
  const toast = useToast();
  const [bidslIst, setBidsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  function fetchBidsList() {
    setIsLoading(true);
    useFetch
      .get(`bidslist/${id}`)
      .then((res) => {
        setBidsList(res?.data?.bid);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }
  const handleAction = (companyId: number, status: number) => {
    useFetch
      .get(`changestatus/${companyId}/${status}`)
      .then((res) => {
        fetchBidsList();
        toast({
          title: `${status === 1 ? "Accepted" : "Rejected"} Successfully`,
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: "Something went wrong",
          description: "",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };
  useEffect(() => {
    fetchBidsList();
  }, [id]);
  return (
    <Flex direction="column" align="center" justify="center">
      {isLoading ? (
        <Skeleton height="110px" w="100%" borderRadius="16px" />
      ) : bidslIst?.length ? (
        <Flex w="100%" direction="column" gap="20px">
          {bidslIst?.map((item: any) => (
            <Flex
              align="center"
              key={item?.id}
              minH="130px"
              pos="relative"
              shadow="md"
            >
              <Flex
                zIndex="1"
                direction="column"
                gap="12px"
                width={
                  item?.bid_status === 1 || item?.bid_status === 2
                    ? "90%"
                    : "100%"
                }
                className="bid-card-selected"
                padding="12px"
                position="relative"
                height="100%"
                borderRadius="21px"
                cursor="pointer"
              >
                <Flex width="100%" justify="space-between" align="center">
                  <Flex minW="20px" align="center" gap="8px" />

                  {item?.bid_status === 0 && (
                    <Button
                      colorScheme="green"
                      bg={theme.color.GREEN}
                      borderRadius="21px"
                      size="xs"
                      fontSize="10px"
                      px="15px"
                      onClick={() => handleAction(item?.id, 1)}
                    >
                      Approve
                    </Button>
                  )}
                </Flex>
                <Flex width="100%" align="center" gap="8px" wrap="wrap">
                  <Text
                    fontSize="15px"
                    fontWeight="700"
                    color="white"
                    whiteSpace="nowrap"
                    cursor="default"
                  >
                    {item?.company_name}
                  </Text>
                  {item?.bidder_name && (
                    <Text
                      cursor="default"
                      fontSize="15px"
                      fontWeight="700"
                      color="rgba(255, 255, 255, 0.5)"
                    >
                      | {item?.bidder_name}
                    </Text>
                  )}
                </Flex>
                <Text
                  fontSize="11px"
                  fontWeight="400"
                  color="rgba(255, 255, 255, 1)"
                >
                  {item?.start_date
                    ? moment(item?.start_date).format("D MMMM, yyyy")
                    : "N/A"}{" "}
                  -{" "}
                  {item?.end_date
                    ? moment(item?.end_date).format("D MMMM, yyyy")
                    : "N/A"}
                </Text>
                <Flex width="100%" justify="space-between" align="center">
                  {false ? (
                    <Flex
                      align="center"
                      gap="8px"
                      color="white"
                      fontSize="12px"
                    >
                      <PdfIcon />
                      <Text>RFQ</Text>
                    </Flex>
                  ) : (
                    <Flex minW="20px" />
                  )}
                  {item?.bid_status === 0 && (
                    <Button
                      colorScheme="white"
                      bg={theme.color.WHITE}
                      borderRadius="21px"
                      size="xs"
                      fontSize="10px"
                      px="15px"
                      color="black"
                      onClick={() => handleAction(item?.id, 2)}
                    >
                      Decline
                    </Button>
                  )}
                </Flex>
              </Flex>
              {(item?.bid_status === 1 || item?.bid_status === 2) && (
                <Flex
                  height="100%"
                  bg={item?.bid_status === 2 ? "red" : theme.color.GREEN}
                  position="absolute"
                  right="0px"
                  w="100%"
                  borderRadius="21px"
                  align="center"
                  justify="end"
                  cursor="pointer"
                  transition="background-color 1s ease-in, width 0.3s ease-in"
                  padding="0px"
                />
              )}
            </Flex>
          ))}
        </Flex>
      ) : (
        <Text color="white">No Records found</Text>
      )}
    </Flex>
  );
};

export default BidsList;
{
  /* <Tr key={item?.id}>
<Td>{id}</Td>
<Td>{item?.company_name}</Td>
<Td>{item?.product}</Td>
<Td>{item?.sum_insured}</Td>
<Td>{item?.bidder_name || " - "}</Td>
<Td>
  {item?.start_date
    ? moment(item?.start_date).format("MMMM DD,yyyy")
    : "-"}
</Td>
<Td>
  {item?.bid_status === 1 ? (
    <Badge colorScheme="green">Accepted</Badge>
  ) : item?.bid_status === 2 ? (
    <Badge colorScheme="red">Rejected</Badge>
  ) : (
    <Flex align="center" gap="8px">
      <Button
        bg="green"
        padding="5px"
        borderRadius="10px"
        colorScheme="green"
        onClick={() => handleAction(item?.id, 1)}
      >
        <Icon
          as={FaRegThumbsUp}
          fontSize="20px"
          color="white"
        />
      </Button>
      <Button
        bg="red"
        padding="5px"
        borderRadius="10px"
        colorScheme="red"
        onClick={() => handleAction(item?.id, 2)}
      >
        <Icon
          as={FaRegThumbsDown}
          fontSize="20px"
          color="white"
        />
      </Button>
    </Flex>
  )}
</Td>
</Tr> */
}
