import React, { useEffect, useState } from "react";
import SanbotLogo from "../../partials/assets/images/galaxy-logo.png";
import {
  Flex,
  Icon,
  Image,
  Text,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Button,
} from "@chakra-ui/react";
import { HiOutlineSparkles } from "react-icons/hi2";
import { theme } from "../../partials/utils/Contants";
import BidDetails from "../../pages/BidDetails";
import Proposals from "../Proposals";
import BidsList from "../../pages/BidsList";
const insightCardDummy = [
  "All 6 bidders have provided you with coverage's for your special requirement of maternity for your employee plus family group   policy. ",
  "However, Uniqus Broking has provided the best deal on maternity coverage with 100% of the sum insured amount per family which is 5 lakhs.",
  "However, Thames Broking gets the L1 position with lowest bid amount of ₹2,25,000 for the entire risk and is 12% more competitive than the nest best offer. ",
];
const SanbotAI = ({ selectedBid }: any) => {
  const getDefaultTabIndex = () => {
    if (selectedBid?.action === "requirements") {
      return 1;
    }
    if (selectedBid?.action === "proposals") {
      return 2;
    }
    if (selectedBid?.action === "requests") {
      return 0;
    }
    return 0;
  };

  const [tabIndex, setTabIndex] = useState(getDefaultTabIndex());

  useEffect(() => {
    setTabIndex(getDefaultTabIndex());
  }, [selectedBid?.id, selectedBid?.bidId]);

  const InsightCard = ({ text, themeMode, width }: any) => {
    return (
      <Flex
        padding="16px 12px"
        gap="10px"
        backdropBlur="15px"
        bg={themeMode === "dark" ? "rgb(255 255 255 / 10%)" : "#FFF"}
        color={
          themeMode === "dark"
            ? theme.color.GRAY_FONT
            : "rgba(115, 115, 115, 1)"
        }
        borderRadius="16px"
        mt="10px"
        width={width || "100%"}
      >
        {themeMode === "dark" ? (
          <Icon as={HiOutlineSparkles} fontSize="18px" />
        ) : (
          <Image src={SanbotLogo} height="24px" w="24px" alt="sanbot" />
        )}
        <Text fontSize="12px" fontWeight="500" color={theme.color.GRAY_FONT}>
          {text}
        </Text>
      </Flex>
    );
  };
  return (
    <Flex
      direction="column"
      padding={selectedBid?.data ? "0px" : "40px 25px 20px"}
      flex={1}
      bg="black"
      gap="40px"
      maxW="460px"
    >
      <Flex
        align="center"
        gap="19px"
        m={selectedBid?.data ? "16px 12px" : "0px"}
      >
        <Image src={SanbotLogo} alt="sanbot" height="60px" width="60px" />
        <Flex direction="column" gap="5px">
          <Text fontSize="24px" fontWeight="500" color="white">
            SanBotAI:{" "}
          </Text>
          <Text
            fontSize="24px"
            fontWeight="500"
            color="rgba(246, 246, 246, 0.60)"
          >
            Bid Intelligence{" "}
          </Text>
        </Flex>
      </Flex>
      {!selectedBid?.id ? (
        <InsightCard
          text="Click on a bid to view more details and get insights from SanbotAI"
          themeMode="dark"
        />
      ) : (
        <Tabs
          position="relative"
          index={tabIndex}
          onChange={(index) => setTabIndex(index)}
          variant="unstyled"
          width="100%"
        >
          <TabList gap="auto" p="0px">
            {/* <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              
              color="rgba(246, 246, 246, 0.50)"
              _selected={{
                color: "white",
              }}
            >
              <Flex align="center" gap="2px">
                <Icon as={HiOutlineSparkles} fontSize="12px" />
                <Text>Insights</Text>
              </Flex>
            </Tab> */}
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              color="rgba(246, 246, 246, 0.50)"
              _selected={{
                color: "white",
              }}
            >
              Requests
            </Tab>
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              color="rgba(246, 246, 246, 0.50)"
              _selected={{
                color: "white",
              }}
            >
              Requirements
            </Tab>

            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              color="rgba(246, 246, 246, 0.50)"
              _selected={{
                color: "white",
              }}
            >
              Proposals
            </Tab>
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              color="rgba(246, 246, 246, 0.50)"
              _selected={{
                color: "white",
              }}
            >
              Comments
            </Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="white"
            borderRadius="1px"
          />
          <TabPanels>
            <TabPanel px="4px">
              <Flex
                direction="column"
                w="100%"
                align="start"
                gap="5px"
                maxH="75vh"
                // maxH="85%"
                // flex={1}
                overflowY="auto"
                className="hide-scrollbar"
              >
                <Text color="white" fontWeight="600" fontSize="16px" py="10px">
                  Bidder Requests
                </Text>
                <BidsList id={selectedBid?.id} />
              </Flex>
            </TabPanel>
            {/* <TabPanel>
              <Flex
                direction="column"
                w="100%"
                align="center"
                gap="12px"
                height="auto"
                maxH="75vh"
                overflowY="auto"
                className="hide-scrollbar"
              >
                {insightCardDummy.map((item) => (
                  <InsightCard text={item} themeMode="light" />
                ))}
                <Flex direction="column" w="100%" align="end" gap="15px">
                  <InsightCard
                    themeMode="dark"
                    text="Chat with Sanbot AI to get more insights on your proposals for the Group Mediclaim bid by sending in your questions in the chat. "
                    width="75%"
                  />
                  <Button
                    borderRadius="24px"
                    colorScheme="white"
                    bg="#FFF"
                    color="black"
                    w="104px"
                    height="24px"
                  >
                    Send
                  </Button>
                </Flex>
              </Flex>
            </TabPanel> */}
            <TabPanel>
              <Flex
                direction="column"
                w="100%"
                align="center"
                gap="12px"
                maxH="75vh"
                // maxH="85%"
                // flex={1}
                overflowY="auto"
                className="hide-scrollbar"
              >
                <BidDetails id={selectedBid?.id} />
              </Flex>
            </TabPanel>
            <TabPanel>
              <Flex
                direction="column"
                w="100%"
                align="center"
                gap="12px"
                maxH="75vh"
                // maxH="85%"
                // flex={1}
                overflowY="auto"
                className="hide-scrollbar"
              >
                <Proposals id={selectedBid?.bidId} />
              </Flex>
            </TabPanel>
            <TabPanel>
              <Flex
                direction="column"
                w="100%"
                align="center"
                gap="12px"
                maxH="75vh"
                // maxH="85%"
                // flex={1}
                overflowY="auto"
                className="hide-scrollbar"
              >
                Comments Comming soon
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </Flex>
  );
};

export default SanbotAI;
