import {
  Flex,
  Skeleton,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import { theme } from "../../../partials/utils/Contants";
import BidderBidCard from "../../../components/BidderBidCard";
import BidderSanbotAI from "../../../components/Bidder/Bidder-Sanbot";
import { useFetchUser } from "../../../partials/utils/User";

const BidderBidCentre = () => {
  const user = useFetchUser();
  const [selectedBid, setSelectedBid] = useState({ id: null, action: "" });
  const [liveBidsLsit, setLiveBidsLsit] = useState([]);
  const [completedBidsList, setCompletedBidsList] = useState<any>([]);
  const [engagedBidsList, setEngagedBidsList] = useState([]);

  const [isLiveLoading, setIsLiveLoading] = useState(false);
  const [isCompletedLoading, setIsCompletedLoading] = useState(false);
  const [isEngagedLoading, setIsEngagedLoading] = useState(false);
  function fetchLiveBids() {
    setIsLiveLoading(true);
    useFetch
      .get("livebid")
      .then((response) => {
        setLiveBidsLsit(response?.data?.live?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLiveLoading(false));
  }

  function fetchCompletedBids() {
    setIsCompletedLoading(true);
    useFetch
      .get("completed-bids")
      .then((response) => {
        const array = Object.values(response?.data?.bid);
        setCompletedBidsList(array);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsCompletedLoading(false));
  }
  function fetchEngagedBids() {
    setIsEngagedLoading(true);
    useFetch
      .get("engagedbid")
      .then((response) => {
        setEngagedBidsList(response?.data?.engaged?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsEngagedLoading(false));
  }
  function fetchAll() {
    fetchLiveBids();
    fetchCompletedBids();
    fetchEngagedBids();
  }
  useEffect(() => {
    fetchAll();
  }, []);

  return (
    <Flex height="100%" width="100%">
      <Flex
        direction="column"
        padding="30px 80px 30px 30px"
        flex={2}
        gap="10px"
      >
        <Flex align="center" w="100%" justify="space-between">
          <Text as="h2" fontSize="24px" fontWeight="500">
            Bid Centre
          </Text>
        </Flex>
        <Tabs variant="unstyled" width="100%">
          <TabList gap="40px">
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              color={theme.color.GRAY_LABEL}
              _selected={{
                color: "black",
              }}
              onClick={() => setSelectedBid({ id: null, action: "" })}
            >
              Live Bids
            </Tab>
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              color={theme.color.GRAY_LABEL}
              _selected={{
                color: "black",
              }}
              onClick={() => setSelectedBid({ id: null, action: "" })}
            >
              Engaged Bids
            </Tab>
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              color={theme.color.GRAY_LABEL}
              _selected={{
                color: "black",
              }}
              onClick={() => setSelectedBid({ id: null, action: "" })}
            >
              Completed Bids
            </Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="black"
            borderRadius="1px"
          />
          <TabPanels>
            <TabPanel px="0px">
              {!isLiveLoading && liveBidsLsit?.length ? (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  height="82vh"
                  overflowY="auto"
                  className="hide-scrollbar"
                >
                  {liveBidsLsit?.map((item: any) => (
                    <BidderBidCard
                      fetchAll={fetchAll}
                      data={item?.bids}
                      type="live"
                      fetchData={fetchLiveBids}
                      setSelectedBid={setSelectedBid}
                      selectedBid={selectedBid}
                      bidId={item?.bids?.company_id}
                      bidderCount={item?.bidders}
                      completeData={item}
                    />
                  ))}
                </Flex>
              ) : (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  justify="center"
                  minH="200px"
                >
                  {isLiveLoading ? (
                    <Skeleton height="110px" w="100%" borderRadius="16px" />
                  ) : (
                    <Text>No Bids Aavailable</Text>
                  )}
                </Flex>
              )}
            </TabPanel>
            <TabPanel px="0px">
              {!isEngagedLoading && engagedBidsList?.length ? (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  height="82vh"
                  overflowY="auto"
                  className="hide-scrollbar"
                >
                  {engagedBidsList?.map((item: any) => {
                    const quote = item?.bids?.quote_files.find(
                      (q: any) =>
                        q?.user_id === user?.id && q?.is_accepted === 1
                    );
                    const isAccepted = item?.bids?.quote_files.find(
                      (q: any) => q.is_accepted === 1
                    );
                    const bidStatus = item?.bidders?.find(
                      (x: any) => x?.user_id === user?.id
                    );
                    const status =
                      quote && !isAccepted
                        ? "Waiting for Quote Approval"
                        : quote && isAccepted?.user_id === user.id
                        ? "Quote Accepted"
                        : bidStatus?.status === 1
                        ? "Accepted"
                        : "Pending";

                    return (
                      <BidderBidCard
                        fetchAll={fetchAll}
                        data={item?.bids}
                        type="engaged"
                        fetchData={fetchEngagedBids}
                        bidId={item?.bids?.company_id}
                        pdfFile={item?.pdf_file}
                        quoteFile={item?.policy_file}
                        setSelectedBid={setSelectedBid}
                        selectedBid={selectedBid}
                        quote={quote}
                        engagedStatus={status}
                        completeData={item}
                      />
                    );
                  })}
                </Flex>
              ) : (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  justify="center"
                  minH="200px"
                >
                  {isEngagedLoading ? (
                    <Skeleton height="110px" w="100%" borderRadius="16px" />
                  ) : (
                    <Text>No Bids Aavailable</Text>
                  )}
                </Flex>
              )}
            </TabPanel>
            <TabPanel px="0px">
              {!isCompletedLoading && completedBidsList?.length ? (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  height="82vh"
                  overflowY="auto"
                  className="hide-scrollbar"
                >
                  {completedBidsList?.map((item: any) => {
                    return (
                      <BidderBidCard
                        fetchAll={fetchAll}
                        data={item}
                        type="completed"
                        fetchData={fetchEngagedBids}
                        bidId={item?.company_id}
                        pdfFile={item?.quote_file?.file_path}
                        quoteFile={item?.quote_file?.policy_file}
                        setSelectedBid={setSelectedBid}
                        selectedBid={selectedBid}
                        bidderCount={item?.bidders}
                        completeData={item}
                        quote={item?.quote_file}
                      />
                    );
                  })}
                </Flex>
              ) : (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  justify="center"
                  minH="200px"
                >
                  {isCompletedLoading ? (
                    <Skeleton height="110px" w="100%" borderRadius="16px" />
                  ) : (
                    <Text>No Bids Aavailable</Text>
                  )}
                </Flex>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
      <BidderSanbotAI selectedBid={selectedBid} />
    </Flex>
  );
};

export default BidderBidCentre;
