import { Navigate, Outlet, useLocation } from "react-router-dom";
import { LinkItemsBidder } from "../../../routes";
import Sidebar from "../../../components/Sidebar";
import {
  fetchProfileStatus,
  useFetchtoken,
} from "../../../partials/utils/User";
import { useEffect, useState } from "react";
import LoadingScreen from "../../../pages/LoadingScreen";

const PrivateRoutes = () => {
  const { pathname } = useLocation();
  const token = useFetchtoken();
  const [isProfileComplete, setIsProfileComplete] = useState<boolean | null>(
    null
  );

  useEffect(() => {
    const checkProfileStatus = async () => {
      const status = await fetchProfileStatus();
      setIsProfileComplete(status);
    };

    if (token) {
      checkProfileStatus();
    } else {
      setIsProfileComplete(null);
    }
  }, [token, pathname]);

  if (token === null) {
    return <Navigate to="/login" />;
  }

  if (isProfileComplete === null) {
    return <LoadingScreen />;
  }

  return token ? (
    isProfileComplete ? (
      <Sidebar sidebaroptions={LinkItemsBidder}>
        <Outlet />
      </Sidebar>
    ) : (
      <Navigate to="/setupProfile" />
    )
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoutes;
