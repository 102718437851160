import {
  Button,
  Flex,
  Image,
  Select,
  Skeleton,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { TbFileStar } from "react-icons/tb";

import { theme } from "../../partials/utils/Contants";
import BidCard from "../../components/BidCard";
import { useNavigate } from "react-router-dom";
import SanbotAI from "../../components/SanbotAI";
import { useEffect, useState } from "react";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import noBidImg from "../../partials/assets/images/emoji-no-bid.png";

const BidCentre = () => {
  const navigate = useNavigate();
  const [selectedBid, setSelectedBid] = useState({
    id: null,
    bidId: null,
    action: "",
  });
  const [liveBidsLsit, setLiveBidsLsit] = useState([]);
  const [draftedBidsList, setDraftedBidsList] = useState([]);
  const [completedBidsList, setCompletedBidsList] = useState([]);
  const [engagedBidsList, setEngagedBidsList] = useState([]);

  const [isLiveLoading, setIsLiveLoading] = useState(false);
  const [isDraftedLoading, setIsDraftedLoading] = useState(false);
  const [isCompletedLoading, setIsCompletedLoading] = useState(false);
  const [isEngagedLoading, setIsEngagedLoading] = useState(false);
  function fetchLiveBids() {
    setIsLiveLoading(true);
    useFetch
      .get("livebid")
      .then((response) => {
        setLiveBidsLsit(response?.data?.live);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLiveLoading(false));
  }
  function fetchDraftedBids() {
    setIsDraftedLoading(true);
    useFetch
      .get("draftedbid")
      .then((response) => {
        setDraftedBidsList(response?.data?.data?.drafted);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsDraftedLoading(false));
  }

  function fetchCompletedBids() {
    setIsCompletedLoading(true);
    useFetch
      .get("completedbid")
      .then((response) => {
        setCompletedBidsList(response?.data?.data?.completed);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsCompletedLoading(false));
  }
  function fetchEngagedbidBids() {
    setIsEngagedLoading(true);
    useFetch
      .get("engagedbid")
      .then((response) => {
        setEngagedBidsList(response?.data?.data?.engaged);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsEngagedLoading(false));
  }
  function fetchAfterComplete() {
    fetchEngagedbidBids();
    fetchCompletedBids();
  }
  useEffect(() => {
    fetchLiveBids();
    fetchDraftedBids();
    fetchCompletedBids();
    fetchEngagedbidBids();
  }, []);

  return (
    <Flex height="100%" width="100%">
      <Flex
        direction="column"
        padding="30px 80px 30px 30px"
        flex={2}
        gap="10px"
      >
        <Flex align="center" w="100%" justify="space-between">
          <Text as="h2" fontSize="24px" fontWeight="500">
            Bid Centre
          </Text>
          <Button
            rightIcon={<TbFileStar />}
            borderRadius="24px"
            bg="black"
            colorScheme="black"
            color="#F6F6F6"
            w="143px"
            height="24px"
            fontSize="10px"
            fontWeight="500"
            onClick={() => navigate("/create-bid")}
          >
            Create New Bid
          </Button>
        </Flex>
        <Tabs variant="unstyled" width="100%">
          <TabList gap="40px">
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              color={theme.color.GRAY_LABEL}
              _selected={{
                color: "black",
              }}
              onClick={() =>
                setSelectedBid({ id: null, bidId: null, action: "" })
              }
            >
              Live Bids
            </Tab>
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              color={theme.color.GRAY_LABEL}
              _selected={{
                color: "black",
              }}
              onClick={() =>
                setSelectedBid({ id: null, bidId: null, action: "" })
              }
            >
              Draft Bids
            </Tab>
          
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              color={theme.color.GRAY_LABEL}
              _selected={{
                color: "black",
              }}
              onClick={() =>
                setSelectedBid({ id: null, bidId: null, action: "" })
              }
            >
              Engaged Bids
            </Tab>
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              color={theme.color.GRAY_LABEL}
              _selected={{
                color: "black",
              }}
              onClick={() =>
                setSelectedBid({ id: null, bidId: null, action: "" })
              }
            >
              Completed Bids
            </Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="black"
            borderRadius="1px"
          />
          <TabPanels>
            <TabPanel px="0px">
              {!isLiveLoading && liveBidsLsit?.length ? (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  height="82vh"
                  overflowY="auto"
                  className="hide-scrollbar"
                >
                  {liveBidsLsit?.map((item) => (
                    <BidCard
                      data={item}
                      type="live"
                      fetchData={fetchLiveBids}
                      setSelectedBid={setSelectedBid}
                      selectedBid={selectedBid}
                    />
                  ))}
                </Flex>
              ) : (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  justify="center"
                  minH="200px"
                >
                  {isLiveLoading ? (
                    <Skeleton height="110px" w="100%" borderRadius="16px" />
                  ) : (
                    <Flex
                      direction="column"
                      align="center"
                      gap="25px"
                      pt="40px"
                    >
                      <Text fontWeight="600">
                        No bids yet. Create your bid now
                      </Text>
                      <Image src={noBidImg} height="182px" width="207px" />
                      <Button
                        borderRadius="24px"
                        bg="black"
                        colorScheme="black"
                        color="white"
                        padding="4px 14px"
                        fontSize="12px"
                        fontWeight="500"
                        w="104px"
                        h="24px"
                        onClick={() => navigate("/create-bid")}
                      >
                        Create Bid
                      </Button>
                    </Flex>
                  )}
                </Flex>
              )}
            </TabPanel>
            <TabPanel px="0px">
              {!isDraftedLoading && draftedBidsList?.length ? (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  height="82vh"
                  overflowY="auto"
                  className="hide-scrollbar"
                >
                  {draftedBidsList?.map((item) => (
                    <BidCard
                      data={item}
                      type="draft"
                      fetchData={fetchDraftedBids}
                    />
                  ))}
                </Flex>
              ) : (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  justify="center"
                  minH="200px"
                >
                  {isDraftedLoading ? (
                    <Skeleton height="110px" w="100%" borderRadius="16px" />
                  ) : (
                    <Flex
                      direction="column"
                      w="100%"
                      align="center"
                      gap="12px"
                      justify="center"
                      minH="200px"
                    >
                      {isLiveLoading ? (
                        <Skeleton height="110px" w="100%" borderRadius="16px" />
                      ) : (
                        <Flex
                          direction="column"
                          align="center"
                          gap="25px"
                          pt="40px"
                        >
                          <Text fontWeight="600">
                            No bids yet. Create your bid now
                          </Text>
                          <Image src={noBidImg} height="182px" width="207px" />
                          <Button
                            borderRadius="24px"
                            bg="black"
                            colorScheme="black"
                            color="white"
                            padding="4px 14px"
                            fontSize="12px"
                            fontWeight="500"
                            w="104px"
                            h="24px"
                            onClick={() => navigate("/create-bid")}
                          >
                            Create Bid
                          </Button>
                        </Flex>
                      )}
                    </Flex>
                  )}
                </Flex>
              )}
            </TabPanel>
            
            <TabPanel px="0px">
              {!isEngagedLoading && engagedBidsList?.length ? (
                <Flex direction="column" w="100%" align="center" gap="12px">
                  {engagedBidsList?.map((item: any) => (
                    <BidCard
                      data={item}
                      type="engaged"
                      fetchData={fetchEngagedbidBids}
                      selectedBid={selectedBid}
                      setSelectedBid={setSelectedBid}
                      fetchAfterComplete={fetchAfterComplete}
                    />
                  ))}
                </Flex>
              ) : (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  justify="center"
                  minH="200px"
                >
                  {isEngagedLoading ? (
                    <Skeleton height="110px" w="100%" borderRadius="16px" />
                  ) : (
                    <Flex
                      direction="column"
                      align="center"
                      gap="25px"
                      pt="40px"
                    >
                      <Text fontWeight="600">
                        No bids yet. Create your bid now
                      </Text>
                      <Image src={noBidImg} height="182px" width="207px" />
                      <Button
                        borderRadius="24px"
                        bg="black"
                        colorScheme="black"
                        color="white"
                        padding="4px 14px"
                        fontSize="12px"
                        fontWeight="500"
                        w="104px"
                        h="24px"
                        onClick={() => navigate("/create-bid")}
                      >
                        Create Bid
                      </Button>
                    </Flex>
                  )}
                </Flex>
              )}
            </TabPanel>
            <TabPanel px="0px">
              {!isCompletedLoading && completedBidsList?.length ? (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  height="82vh"
                  overflowY="auto"
                  className="hide-scrollbar"
                >
                  {completedBidsList?.map((item: any) => (
                    <BidCard
                      data={item}
                      type="completed"
                      fetchData={fetchCompletedBids}
                      selectedBid={selectedBid}
                      setSelectedBid={setSelectedBid}
                    />
                  ))}
                </Flex>
              ) : (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  justify="center"
                  minH="200px"
                >
                  {isCompletedLoading ? (
                    <Skeleton height="110px" w="100%" borderRadius="16px" />
                  ) : (
                    <Flex
                      direction="column"
                      w="100%"
                      align="center"
                      gap="12px"
                      justify="center"
                      minH="200px"
                    >
                      {isLiveLoading ? (
                        <Skeleton height="110px" w="100%" borderRadius="16px" />
                      ) : (
                        <Flex
                          direction="column"
                          align="center"
                          gap="25px"
                          pt="40px"
                        >
                          <Text fontWeight="600">
                            No bids yet. Create your bid now
                          </Text>
                          <Image src={noBidImg} height="182px" width="207px" />
                          <Button
                            borderRadius="24px"
                            bg="black"
                            colorScheme="black"
                            color="white"
                            padding="4px 14px"
                            fontSize="12px"
                            fontWeight="500"
                            w="104px"
                            h="24px"
                            onClick={() => navigate("/create-bid")}
                          >
                            Create Bid
                          </Button>
                        </Flex>
                      )}
                    </Flex>
                  )}
                </Flex>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
      <SanbotAI selectedBid={selectedBid} />
    </Flex>
  );
};

export default BidCentre;
