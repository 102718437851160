import { Button, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import HomeBidCentre from "../../components/HomeBidCentre";
import SanbotAI from "../../components/SanbotAI";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import useLogout from "../../partials/utils/useLogout";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../store/store";
import CreateBidCard from "../../components/CreateBidCard/CreateBidCard";

const HomePage = () => {
  const navigate = useNavigate();
  const { setStep, setSelectedCategories, selectedCategories } = useStore();
  const { logoutHandler } = useLogout();
  const [selectedBid, setSelectedBid] = useState({ data: null, action: "" });
  const [liveBidsLsit, setLiveBidsLsit] = useState([]);
  const [draftedBidsList, setDraftedBidsList] = useState([]);
  const [completedBidsList, setCompletedBidsList] = useState([]);
  const [engagedBidsList, setEngagedBidsList] = useState([]);

  const [isLiveLoading, setIsLiveLoading] = useState(false);
  const [isDraftedLoading, setIsDraftedLoading] = useState(false);
  const [isCompletedLoading, setIsCompletedLoading] = useState(false);
  const [isEngagedLoading, setIsEngagedLoading] = useState(false);
  const showBidCentre =
    !!liveBidsLsit?.length ||
    !!draftedBidsList?.length ||
    !!completedBidsList?.length ||
    !!engagedBidsList?.length;

  function fetchLiveBids() {
    setIsLiveLoading(true);
    useFetch
      .get("livebid")
      .then((response) => {
        setLiveBidsLsit(response?.data?.live);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLiveLoading(false));
  }
  function fetchDraftedBids() {
    setIsDraftedLoading(true);
    useFetch
      .get("draftedbid")
      .then((response) => {
        setDraftedBidsList(response?.data?.data?.drafted);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsDraftedLoading(false));
  }

  function fetchCompletedBids() {
    setIsCompletedLoading(true);
    useFetch
      .get("completedbid")
      .then((response) => {
        setCompletedBidsList(response?.data?.data?.completed);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsCompletedLoading(false));
  }
  function fetchEngagedbidBids() {
    setIsEngagedLoading(true);
    useFetch
      .get("engagedbid")
      .then((response) => {
        setEngagedBidsList(response?.data?.data?.engaged);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsEngagedLoading(false));
  }
  useEffect(() => {
    fetchLiveBids();
    fetchDraftedBids();
    fetchCompletedBids();
    fetchEngagedbidBids();
  }, []);

  const CreateBidData = [
    {
      id: 1,
      title: "Group Health Insurance",
      desc: "A group mediclaim policy provides health insurance coverage to a collective group, such as employees or organization members, covering risks like hospitalization expenses and medical treatments at lower premiums through group bargaining.",
    },
    {
      id: 2,
      title: "Group Personal Accident",
      desc: "Group Personal Accident Insurance offers coverage to a collective group, typically employees or organization members, against accidental injuries resulting in disability or death. It provides financial benefits to the insured or their beneficiaries in such unfortunate events.",
    },
    {
      id: 3,
      title: "Group Term Life",
      desc: "Group Term Life Insurance provides coverage to a collective group, often employees or organization members, offering financial protection to their beneficiaries in the event of the insured individual's death. It serves as a form of financial security and support for the insured's dependents.",
    },
    {
      id: 4,
      title: "Employees/Workmen Compensation Insurance",
      desc: "Workmen's Compensation Insurance provides coverage for employees who suffer work-related injuries or illnesses. It offers financial protection by compensating for medical expenses, lost wages, and disability benefits. Employers obtain this insurance to fulfill legal obligations and ensure the well-being of their....",
    },
    {
      id: 5,
      title: "Professional Indemnity Insurance",
      desc: "Professional Indemnity Insurance, procured by companies for their employees, safeguards against legal claims arising from professional negligence, errors, or omissions. It covers legal costs and compensation payouts, offering financial protection and peace of mind to both employees and employers in....",
    },

    {
      id: 6,
      title: "Money",
      desc: "Money insurance is a specialized type of insurance that provides coverage for the loss or damage of money while in transit or within premises due to theft, accidents, or misfortune.It is help to protect the business that handle big amount of commercials.",
    },
  ];
  return (
    <Flex direction="column" gap="20px" w="100%" position="relative">
      {showBidCentre ? (
        <HomeBidCentre
          liveBidsLsit={liveBidsLsit}
          draftedBidsList={draftedBidsList}
          completedBidsList={completedBidsList}
          engagedBidsList={engagedBidsList}
          isLiveLoading={isLiveLoading}
          isDraftedLoading={isDraftedLoading}
          isCompletedLoading={isCompletedLoading}
          isEngagedLoading={isEngagedLoading}
          fetchLiveBids={fetchLiveBids}
          fetchCompletedBids={fetchCompletedBids}
          fetchDraftedBids={fetchDraftedBids}
          fetchEngagedbidBids={fetchEngagedbidBids}
          selectedBid={selectedBid}
          setSelectedBid={setSelectedBid}
        />
      ) : (
        <Flex height="100%" width="100%">
          <Flex
            direction="column"
            padding="70px 30px 30px 30px"
            flex={2}
            gap="40px"
          >
            <Flex w="100%" justify="space-between" align="center">
              <Text fontSize="24px" fontWeight="500" color="black">
                Create a new bid
              </Text>
              <Button
                borderRadius="24px"
                bg="white"
                colorScheme="white"
                color="black"
                border="1px solid #dadada"
                minW="104px"
                _hover={{ filter: "brightness(1.2)", color: "gray.600" }}
                onClick={() => {
                  setStep(1);
                  navigate("/create-bid");
                  setSelectedCategories([]);
                }}
              >
                View All
              </Button>
            </Flex>

            <Grid
              templateColumns="repeat(auto-fill, 295px)"
              w="100%"
              gap="20px 60px"
            >
              {CreateBidData.map((item) => (
                <GridItem w="100%" h="205px" key={item.id}>
                  <CreateBidCard
                    item={item}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                  />
                </GridItem>
              ))}
            </Grid>
            <Flex direction="column" w="100%">
              <Text fontSize="24px" fontWeight="500" color="black">
                We’d be glad to help
              </Text>
            </Flex>
          </Flex>
          <SanbotAI selectedBid={selectedBid} />
        </Flex>
      )}
    </Flex>
  );
};

export default HomePage;
