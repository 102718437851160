import React, { useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Select,
  Radio,
  RadioGroup,
  Stack,
  Text,
  HStack,
  Flex,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { useStore } from "../../../store/store";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import { flattenObject } from "../../../partials/utils/helper";
import { FaInfoCircle } from "react-icons/fa";
interface FormValues {
  insured: string;
  Want_to_cover_parents: string;
  employeenumber: number;
  spousenumber: number;
  kidsnumber: number;
  policytype: string;
  insurer: string;
  comp_location: string;
  sum_insured_category: string;
  base_sum_insured: string;
  Maternity_Benefits: string;
  Maternity_Benefits_other?: string;
  Pre_defined_room_rent_charges: string;
  room_rent_charges_other?: string;
  Cover_Pre_existing_Diseases: string;
  Cover_Pre_existing_Diseases_other?: string;
  Diseases_wise_capping: string;
  Ambulance_Charges: number;
  Age_Band: string;
  waiting_period: string;
  Hospitalisation_Expenses: string;
  New_born_baby: string;
  Day_care_procedure: string;
  Terrorism: string;
  Domiciliary_Hospitalisation: string;
  AYUSH_Treatment: string;
  Treatment_for_correction_of_eyesight: string;
  Pre_and_Post_Natal_expenses: string;
  Cataract_Surgery: string;
  Service_Category: string;
  start_date: string;
  end_date: string;
  policy_start_date?: string;
  policy_end_date?: string;
  expected_premium: number;
  sum_insured: number;
  claim_data?: FileList;
  emp_data?: FileList;
  Deductible_Amount: number;
  previous_insurer?: string;
  previous_policy_expiry?: string;
}

const GroupHealthInsurance = ({ product, editData, mode }: any) => {
  const { step, setStep } = useStore();
  const toast = useToast();
  const {
    handleSubmit,
    control,
    watch,
    register,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      Age_Band: "1 Day to 80 years",
      waiting_period: "Waived Off",
      Hospitalisation_Expenses: "Covered for 30 & 60 days respectively",
      New_born_baby: "Covered from day 1",
      Day_care_procedure: "Covered as per the standard list",
      Terrorism: "Covered under the policy",
      Domiciliary_Hospitalisation: "Included",
      AYUSH_Treatment: "Covered under the policy",
      Treatment_for_correction_of_eyesight:
        "if power of eye is above +/- 7.5, is payable",
      Pre_and_Post_Natal_expenses: "Covered within the maternity limit",
      Cataract_Surgery: "Up to 25% of Sum Insured, Per eye",
      Service_Category: "Both Cashless & Reimbursement",
      Ambulance_Charges: 1000,
      start_date: new Date().toISOString().split("T")[0],
      end_date: new Date(new Date().setDate(new Date().getDate() + 10))
        .toISOString()
        .split("T")[0],
    },
  });

  const onSubmit = async (data: any) => {
    const compId = editData?.id
      ? editData?.company_id
      : localStorage.getItem("comp_id");
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];

      if (value instanceof FileList) {
        Array.from(value).forEach((file) => {
          formData.append(key, file as Blob);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });

    formData.append("comp_id", compId.toString() || "");
    formData.append("product", product);

    try {
      // Use axios to post the FormData
      const response = await useFetch.post("bid", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast({
        title: `Bid ${editData?.id ? "updated" : "created"} successfully`,
        description: "Bid will be in draft till confirmation",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setStep(5);
    } catch (error) {
      console.error("Error:", error);

      toast({
        title: `Submission failed`,
        description: "There was an error creating the bid.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (editData) {
      const flattenedData = flattenObject(editData);
      for (let key in flattenedData) {
        if (flattenedData.hasOwnProperty(key)) {
          setValue(key as keyof FormValues, flattenedData[key]);
        }
      }
    }
  }, [editData]);
  const baseSumInsured = +watch("base_sum_insured");
  const employeeNumber = +watch("employeenumber");

  useEffect(() => {
    const sum = baseSumInsured * employeeNumber;
    setValue("sum_insured", sum);
  }, [baseSumInsured, employeeNumber]);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ display: "flex", flexDirection: "column", gap: "10px" }}
    >
      {/* Insured */}
      <HStack gap={4}>
        <FormControl id="insured" isInvalid={Boolean(errors?.insured)}>
          <FormLabel>Whom do you want to insure?*</FormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            {...register("insured", {
              required: "Insured is required",
            })}
            placeholder="Whom do you want to insure ?"
          >
            <option value="Employee">Employee</option>
            <option value="Employee & Spouse">Employee & Spouse</option>
            <option value="Employee ,Spouse & Kids">
              Employee ,Spouse & Kids
            </option>
          </Select>
          {errors?.insured && (
            <Text color="red.500" fontSize="sm">
              {errors?.insured?.message}
            </Text>
          )}
        </FormControl>

        {/* Want to cover parents */}
        <FormControl
          id="Want_to_cover_parents"
          isInvalid={Boolean(errors?.Want_to_cover_parents)}
        >
          <FormLabel>Want to cover parents?*</FormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            {...register("Want_to_cover_parents", {
              required: "This field is required",
            })}
            defaultValue="No"
          >
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </Select>
          {errors?.Want_to_cover_parents && (
            <Text color="red.500" fontSize="sm">
              {errors?.Want_to_cover_parents?.message}
            </Text>
          )}
        </FormControl>
      </HStack>

      <HStack gap={4}>
        {/* Employee Count */}
        <FormControl
          id="employeenumber"
          isInvalid={Boolean(errors?.employeenumber)}
        >
          <FormLabel>Employee Count*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min="0"
            defaultValue="0"
            {...register("employeenumber", {
              required: "Employee count is required",
            })}
          />
          {errors?.employeenumber && (
            <Text color="red.500" fontSize="sm">
              {errors?.employeenumber?.message}
            </Text>
          )}
        </FormControl>

        {/* Spouse Count */}
        <FormControl
          id="spousenumber"
          isInvalid={Boolean(errors?.spousenumber)}
        >
          <FormLabel>
            Spouse Count
            {watch("insured") === "Employee & Spouse" ||
            watch("insured") === "Employee ,Spouse & Kids"
              ? "*"
              : ""}
          </FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min="0"
            defaultValue="0"
            {...register("spousenumber", {
              required:
                watch("insured") === "Employee & Spouse" ||
                watch("insured") === "Employee ,Spouse & Kids"
                  ? "Spouse count is required"
                  : false,
            })}
          />
          {errors?.spousenumber && (
            <Text color="red.500" fontSize="sm">
              {errors?.spousenumber?.message}
            </Text>
          )}
        </FormControl>
      </HStack>

      <HStack gap={4}>
        {/* Kids Count */}
        <FormControl id="kidsnumber" isInvalid={Boolean(errors?.kidsnumber)}>
          <FormLabel>
            Kids {watch("insured") === "Employee ,Spouse & Kids" ? "*" : ""}
          </FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min="0"
            defaultValue="0"
            {...register("kidsnumber")}
            {...register("kidsnumber", {
              required:
                watch("insured") === "Employee ,Spouse & Kids"
                  ? "Spouse count is required"
                  : false,
            })}
          />
          {errors?.kidsnumber && (
            <Text color="red.500" fontSize="sm">
              {errors?.kidsnumber?.message}
            </Text>
          )}
        </FormControl>
        {/* Policy Type */}
        <FormControl id="policytype" isInvalid={Boolean(errors?.policytype)}>
          <FormLabel>Policy Type*</FormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            {...register("policytype", {
              required: "Policy type is required",
            })}
            placeholder="Select policy type"
          >
            <option value="New">New</option>
            <option value="Renewal">Renewal</option>
          </Select>
          {errors?.policytype && (
            <Text color="red.500" fontSize="sm">
              {errors?.policytype?.message}
            </Text>
          )}
        </FormControl>
      </HStack>
      <HStack gap={4}>
        {/* Bidder Preference */}
        <FormControl id="insurer" isInvalid={Boolean(errors?.insurer)}>
          <FormLabel>Bidder Preference*</FormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            {...register("insurer", {
              required: "Bidder preference is required",
            })}
            placeholder="Preference"
          >
            <option value="Both">Both</option>
            <option value="Brokers">Brokers</option>
            <option value="INS_COMP">Insurance Company</option>
          </Select>
          {errors?.insurer && (
            <Text color="red.500" fontSize="sm">
              {errors?.insurer?.message}
            </Text>
          )}
        </FormControl>
        {/* Company Location */}
        <FormControl
          id="comp_location"
          isInvalid={Boolean(errors?.comp_location)}
        >
          <FormLabel>Where is your company located?*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("comp_location", {
              required: "Company location is required",
            })}
          />
          {errors?.comp_location && (
            <Text color="red.500" fontSize="sm">
              {errors?.comp_location?.message}
            </Text>
          )}
        </FormControl>
      </HStack>

      <Box borderTop="1px solid" mt={6} pt={4}>
        <Text fontWeight="bold" color="darkorchid">
          Optional coverage
        </Text>
      </Box>

      <HStack gap={4} align="end">
        <FormControl isInvalid={Boolean()}>
          <FormLabel>Sum insured</FormLabel>
          <Controller
            name="sum_insured_category"
            control={control}
            rules={{ required: "This field is required" }}
            defaultValue="flat"
            render={({ field }) => (
              <RadioGroup {...field}>
                <Stack direction="row">
                  <Radio value="flat">Sum insured</Radio>
                  <Radio value="specific">Sum insured based on category</Radio>
                </Stack>
              </RadioGroup>
            )}
          />
          {watch("sum_insured_category") === "specific" ? (
            <Box style={{ marginTop: "7px" }}>
              <Input
                bg="white"
                borderRadius="21px"
                type="number"
                min={0}
                defaultValue={0}
                {...register("base_sum_insured")}
                isDisabled={true}
              />
              {errors?.base_sum_insured && (
                <Text color="red.500" fontSize="sm">
                  {errors?.base_sum_insured?.message}
                </Text>
              )}{" "}
            </Box>
          ) : (
            <Box style={{ marginTop: "7px" }}>
              <Controller
                name="base_sum_insured"
                rules={{ required: "This field is required" }}
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    bg="white"
                    borderRadius="21px"
                    {...field}
                    isDisabled={watch("sum_insured_category") === "specific"}
                  >
                    <option value="" disabled>
                      Select employee sum insured
                    </option>
                    <option value="100000">100000</option>
                    <option value="200000">200000</option>
                    <option value="300000">300000</option>
                    <option value="400000">400000</option>
                    <option value="500000">500000</option>
                  </Select>
                )}
              />
              {errors?.base_sum_insured && (
                <Text color="red.500" fontSize="sm">
                  {errors?.base_sum_insured?.message}
                </Text>
              )}
            </Box>
          )}
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Maternity_Benefits)}>
          <Flex align="start">
            <FormLabel>Maternity Benefits*</FormLabel>
            {mode === "Guided" && (
              <FaInfoCircle
                fontSize="11px"
                color="gray.500"
                title="It covers the costs of childbirth-related procedures and treatments (including miscarriages and complications after delivery)."
              />
            )}
          </Flex>
          <Controller
            name="Maternity_Benefits"
            control={control}
            rules={{ required: "This field is required" }}
            defaultValue=""
            render={({ field }) => (
              <Select bg="white" borderRadius="21px" {...field}>
                <option value="" disabled>
                  Select Medical expenses for normal & C-section deliveries
                </option>
                <option value="No Maternity benefit">
                  No Maternity benefit
                </option>
                <option value="For normal Rs.25,000, C section Rs.35,000">
                  For normal Rs.25,000, C section Rs.35,000
                </option>
                <option value="For normal Rs.35,000, C section Rs.50,000">
                  For normal Rs.35,000, C section Rs.50,000
                </option>
                <option value="For normal Rs.50,000 for both">
                  For normal Rs.50,000 for both
                </option>
                <option value="Others">Others</option>
              </Select>
            )}
          />
          {errors?.Maternity_Benefits && (
            <Text color="red.500" fontSize="sm">
              {errors?.Maternity_Benefits?.message}
            </Text>
          )}
        </FormControl>
        {watch("Maternity_Benefits") === "Others" && (
          <FormControl isInvalid={Boolean(errors?.Maternity_Benefits_other)}>
            <FormLabel>Value for Others*</FormLabel>
            <Input
              {...register("Maternity_Benefits_other", {
                required: "This field is required",
              })}
              borderRadius="16px"
              background="white"
            />
            {errors?.Maternity_Benefits_other && (
              <Text color="red.500" fontSize="sm">
                {errors?.Maternity_Benefits_other?.message}
              </Text>
            )}
          </FormControl>
        )}
      </HStack>

      <HStack gap={4}>
        <FormControl isInvalid={Boolean(errors?.Pre_defined_room_rent_charges)}>
          <Flex align="start">
            <FormLabel> Room rent*</FormLabel>
            {mode === "Guided" && (
              <FaInfoCircle
                fontSize="11px"
                color="gray.500"
                title="Room Rent charges mean expenses incurred on accommodation in a Hospital Room during the course of treatment for a Disease or Injury."
              />
            )}
          </Flex>

          <Controller
            name="Pre_defined_room_rent_charges"
            control={control}
            rules={{ required: "This field is required" }}
            defaultValue=""
            render={({ field }) => (
              <Select bg="white" borderRadius="21px" {...field}>
                <option value="" disabled>
                  Select Room rent limit
                </option>
                <option value="1% of Sum insured">1% of Sum insured</option>
                <option value="2% of Sum insured">2% of Sum insured</option>
                <option value="Others">Others</option>
              </Select>
            )}
          />
          {errors?.Pre_defined_room_rent_charges && (
            <Text color="red.500" fontSize="sm">
              {errors?.Pre_defined_room_rent_charges?.message}
            </Text>
          )}
        </FormControl>
        {watch("Pre_defined_room_rent_charges") === "Others" && (
          <FormControl isInvalid={Boolean(errors?.room_rent_charges_other)}>
            <FormLabel>Value for Others*</FormLabel>
            <Input
              {...register("room_rent_charges_other", {
                required: "This field is required",
              })}
              borderRadius="16px"
              background="white"
            />
            {errors?.room_rent_charges_other && (
              <Text color="red.500" fontSize="sm">
                {errors?.room_rent_charges_other?.message}
              </Text>
            )}
          </FormControl>
        )}
        <FormControl isInvalid={Boolean(errors?.Cover_Pre_existing_Diseases)}>
          <Flex align="start">
            <FormLabel> Cover Pre-existing Diseases*</FormLabel>
            {mode === "Guided" && (
              <FaInfoCircle
                fontSize="11px"
                color="gray.500"
                title="Pre-existing Disease means any condition, ailment, injury or disease that is diagnosed prior to issuing health insurance policy."
              />
            )}
          </Flex>
          <Controller
            name="Cover_Pre_existing_Diseases"
            control={control}
            rules={{ required: "This field is required" }}
            defaultValue=""
            render={({ field }) => (
              <Select
                bg="white"
                borderRadius="21px"
                {...field}
                placeholder="Select Pre-existing Disease Coverage"
              >
                <option value="Cover from day 1">Cover from day 1</option>
                <option value="Do not cover">Do not cover </option>
                <option value="Others">Others</option>
              </Select>
            )}
          />
          {errors?.Cover_Pre_existing_Diseases && (
            <Text color="red.500" fontSize="sm">
              {errors?.Cover_Pre_existing_Diseases?.message}
            </Text>
          )}
        </FormControl>
        {watch("Cover_Pre_existing_Diseases") === "Others" && (
          <FormControl
            isInvalid={Boolean(errors?.Cover_Pre_existing_Diseases_other)}
          >
            <FormLabel>Value for Others*</FormLabel>
            <Input
              {...register("Cover_Pre_existing_Diseases_other", {
                required: "This field is required",
              })}
              borderRadius="16px"
              background="white"
            />
            {errors?.Cover_Pre_existing_Diseases_other && (
              <Text color="red.500" fontSize="sm">
                {errors?.Cover_Pre_existing_Diseases_other?.message}
              </Text>
            )}
          </FormControl>
        )}
      </HStack>

      <HStack gap={4}>
        <FormControl isInvalid={Boolean(errors?.Diseases_wise_capping)}>
          <FormLabel>Diseases wise capping*</FormLabel>
          <Controller
            name="Diseases_wise_capping"
            control={control}
            rules={{ required: "This field is required" }}
            defaultValue=""
            render={({ field }) => (
              <Select
                bg="white"
                borderRadius="21px"
                {...field}
                placeholder="Select Diseases capping"
              >
                <option value="No capping">No capping</option>
                <option value="Capping">Capping </option>
              </Select>
            )}
          />
          {errors?.Diseases_wise_capping && (
            <Text color="red.500" fontSize="sm">
              {errors?.Diseases_wise_capping?.message}
            </Text>
          )}
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Ambulance_Charges)}>
          <FormLabel>Ambulance Charges (Per person)*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            {...register("Ambulance_Charges", {
              required: "Ambulance Charges is required",
            })}
          />

          {/* <Controller
           rules={{ required: "This field is required" }}
            name="Ambulance_Charges"
            control={control}
            render={({ field }) => (
              <NumberInput min={0} bg='white' borderRadius='21px'>
                <NumberInputField {...field}  borderRadius='21px'/>
              </NumberInput>
            )}
          /> */}
          {errors?.Ambulance_Charges && (
            <Text color="red.500" fontSize="sm">
              {errors?.Ambulance_Charges?.message}
            </Text>
          )}
        </FormControl>
      </HStack>
      <Box borderTop="1px solid" mt={6} pt={4}>
        <Text fontWeight="bold" color="darkorchid">
          Base coverage
        </Text>
      </Box>

      <HStack gap={4}>
        <FormControl isInvalid={Boolean(errors?.Age_Band)}>
          <Flex align="start">
            <FormLabel> Age Band*</FormLabel>
            {mode === "Guided" && (
              <FaInfoCircle
                fontSize="11px"
                color="gray.500"
                title="Coverage for parents and children of employees upto the age limit."
              />
            )}
          </Flex>
          <Controller
            name="Age_Band"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Select bg="white" borderRadius="21px" {...field}>
                <option value="" disabled>
                  Select Age Band
                </option>
                <option value="0-25">0-25</option>
                <option value="26-35">26-35</option>
                <option value="36-45">36-45</option>
                <option value="46-55">46-55</option>
                <option value="56-60">56-60</option>
              </Select>
            )}
          />
          {errors?.Age_Band && (
            <Text color="red.500" fontSize="sm">
              {errors?.Age_Band?.message}
            </Text>
          )}
        </FormControl>
        <FormControl isInvalid={Boolean(errors?.waiting_period)}>
          <FormLabel>30 Days,9 months waiting period*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("waiting_period", {
              required: "Waiting period is required",
            })}
          />
          {errors?.waiting_period && (
            <Text color="red.500" fontSize="sm">
              {errors?.waiting_period?.message}
            </Text>
          )}
        </FormControl>
        <FormControl isInvalid={Boolean(errors?.Hospitalisation_Expenses)}>
          <FormLabel>Pre & Post-Hospitalisation Expenses*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Hospitalisation_Expenses", {
              required: "Hospitalisation Expenses is required",
            })}
          />
          {errors?.Hospitalisation_Expenses && (
            <Text color="red.500" fontSize="sm">
              {errors?.Hospitalisation_Expenses?.message}
            </Text>
          )}
        </FormControl>
      </HStack>

      <HStack gap={4}>
        <FormControl isInvalid={Boolean(errors?.New_born_baby)}>
          <FormLabel>New born baby cover</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("New_born_baby", {
              required: "New born baby cover is required",
            })}
          />
          {errors?.New_born_baby && (
            <Text color="red.500" fontSize="sm">
              {errors?.New_born_baby?.message}
            </Text>
          )}
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Day_care_procedure)}>
          <Flex align="start">
            <FormLabel> Day care procedure</FormLabel>
            {mode === "Guided" && (
              <FaInfoCircle
                fontSize="11px"
                color="gray.500"
                title="Medical procedures which finished in less than twenty-four hours"
              />
            )}
          </Flex>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Day_care_procedure", {
              required: "Day care procedure is required",
            })}
          />
          {errors?.Day_care_procedure && (
            <Text color="red.500" fontSize="sm">
              {errors?.Day_care_procedure?.message}
            </Text>
          )}
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Terrorism)}>
          <FormLabel>Terrorism*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Terrorism", {
              required: "This field is required",
            })}
          />
          {errors?.Terrorism && (
            <Text color="red.500" fontSize="sm">
              {errors?.Terrorism?.message}
            </Text>
          )}
        </FormControl>
      </HStack>

      <HStack gap={4}>
        <FormControl isInvalid={Boolean(errors?.Domiciliary_Hospitalisation)}>
          <Flex align="start">
            <FormLabel> Domiciliary Hospitalisation</FormLabel>
            {mode === "Guided" && (
              <FaInfoCircle
                fontSize="11px"
                color="gray.500"
                title="Medical treatment receives at home due to the non-availability of nearby hospital."
              />
            )}
          </Flex>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Domiciliary_Hospitalisation", {
              required: "Domiciliary Hospitalisation is required",
            })}
          />
          {errors?.Domiciliary_Hospitalisation && (
            <Text color="red.500" fontSize="sm">
              {errors?.Domiciliary_Hospitalisation?.message}
            </Text>
          )}
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.AYUSH_Treatment)}>
          <FormLabel>AYUSH Treatment*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("AYUSH_Treatment", {
              required: "Day care procedure is required",
            })}
          />
          {errors?.AYUSH_Treatment && (
            <Text color="red.500" fontSize="sm">
              {errors?.AYUSH_Treatment?.message}
            </Text>
          )}
        </FormControl>

        <FormControl
          isInvalid={Boolean(errors?.Treatment_for_correction_of_eyesight)}
        >
          <FormLabel>Treatment for correction of eyesight*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Treatment_for_correction_of_eyesight", {
              required: "This field is required",
            })}
          />
          {errors?.Treatment_for_correction_of_eyesight && (
            <Text color="red.500" fontSize="sm">
              {errors?.Treatment_for_correction_of_eyesight?.message}
            </Text>
          )}
        </FormControl>
      </HStack>
      <HStack gap={4}>
        <FormControl isInvalid={Boolean(errors?.Pre_and_Post_Natal_expenses)}>
          <FormLabel>Pre and Post Natal expenses*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Pre_and_Post_Natal_expenses", {
              required: "Pre and Post Natal expenses is required",
            })}
          />
          {errors?.Pre_and_Post_Natal_expenses && (
            <Text color="red.500" fontSize="sm">
              {errors?.Pre_and_Post_Natal_expenses?.message}
            </Text>
          )}
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Cataract_Surgery)}>
          <FormLabel>Cataract Surgery*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Cataract_Surgery", {
              required: "Pre and Post Natal expenses is required",
            })}
          />
          {errors?.Cataract_Surgery && (
            <Text color="red.500" fontSize="sm">
              {errors?.Cataract_Surgery?.message}
            </Text>
          )}
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Service_Category)}>
          <FormLabel>Service Category*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Service_Category", {
              required: "Pre and Post Natal expenses is required",
            })}
          />
          {errors?.Service_Category && (
            <Text color="red.500" fontSize="sm">
              {errors?.Service_Category?.message}
            </Text>
          )}
        </FormControl>
      </HStack>

      <Box borderTop="1px solid " mt={4} pt={2}>
        {/* Bid Dates */}
        <HStack gap={4}>
          {/* Bid Start Date */}
          <FormControl id="start_date" isInvalid={Boolean(errors?.start_date)}>
            <FormLabel>Bid's Start Date*</FormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              {...register("start_date", {
                required: "Bid's start date is required",
              })}
            />
            {errors?.start_date && (
              <Text color="red.500" fontSize="sm">
                {errors?.start_date?.message}
              </Text>
            )}
          </FormControl>

          {/* Bid End Date */}
          <FormControl id="end_date" isInvalid={Boolean(errors?.end_date)}>
            <FormLabel>Bid's End Date*</FormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              {...register("end_date", {
                required: "Bid's end date is required",
              })}
            />
            {errors?.end_date && (
              <Text color="red.500" fontSize="sm">
                {errors?.end_date?.message}
              </Text>
            )}
          </FormControl>
        </HStack>

        {/* Policy Dates */}
        <HStack gap={4}>
          {/* Policy Start Date */}
          <FormControl id="policy_start_date">
            <FormLabel>Policy Start Date</FormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              {...register("policy_start_date")}
            />
          </FormControl>

          {/* Policy End Date */}
          <FormControl id="policy_end_date">
            <FormLabel>Policy End Date</FormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              {...register("policy_end_date")}
            />
          </FormControl>
        </HStack>
        <HStack gap={4}>
          {/* Anticipated Premium */}
          <FormControl
            id="expected_premium"
            isInvalid={Boolean(errors?.expected_premium)}
          >
            <Flex align="start">
              <FormLabel> Anticipated premium*</FormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Anticipated premium for acquiring this policy."
                />
              )}
            </Flex>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              min="0"
              defaultValue="0"
              {...register("expected_premium", {
                required: "Anticipated premium is required",
              })}
            />
            {errors?.expected_premium && (
              <Text color="red.500" fontSize="sm">
                {errors?.expected_premium?.message}
              </Text>
            )}
          </FormControl>

          {/* Total Coverages */}
          <FormControl
            id="sum_insured"
            isInvalid={Boolean(errors?.sum_insured)}
          >
            <FormLabel>Total coverages*</FormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              min="0"
              defaultValue="0"
              {...register("sum_insured", {
                required: "Total coverages is required",
              })}
            />
            {errors?.sum_insured && (
              <Text color="red.500" fontSize="sm">
                {errors?.sum_insured?.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        {/* File Uploads */}
        <HStack gap={4} my="3">
          {/* Claim Data */}
          <FormControl id="claim_data" flex={1}>
            <FormLabel>Claim Data of last year</FormLabel>

            <Input
              bg="white"
              borderRadius="21px"
              type="file"
              // display="none"
              pt="5px"
              {...register("claim_data")}
            />
          </FormControl>

          {/* Employee Data */}
          <HStack flex={1} gap={4} align="center">
            {" "}
            <FormControl id="emp_data" flex={2}>
              <FormLabel>Employee Information</FormLabel>

              <Input
                bg="white"
                borderRadius="21px"
                type="file"
                pt="5px"
                accept=".xlsx"
                {...register("emp_data")}
              />
            </FormControl>
            <FormControl flex={1}>
              <Button
                as="a"
                href="/sampleFiles/MemberData.xlsx"
                download
                leftIcon={<FaCloudDownloadAlt />}
                colorScheme="green"
                bg="green.500"
                borderRadius="21px"
                size="sm"
              >
                <Text>Sample File</Text>
              </Button>
            </FormControl>
          </HStack>
        </HStack>

        {/* Previous Insurer and Policy Expiry */}
        {watch("policytype") === "Renewal" && (
          <HStack gap={4}>
            {/* Previous Insurer */}
            <FormControl
              id="previous_insurer"
              isInvalid={Boolean(errors?.previous_insurer)}
            >
              <FormLabel>Previous Insurer*</FormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                {...register("previous_insurer", {
                  required: "Previous insurer is required",
                })}
              />
              {errors?.previous_insurer && (
                <Text color="red.500" fontSize="sm">
                  {errors?.previous_insurer?.message}
                </Text>
              )}
            </FormControl>

            {/* Previous Policy Expiry */}
            <FormControl
              id="previous_policy_expiry"
              isInvalid={Boolean(errors?.previous_policy_expiry)}
            >
              <FormLabel>Previous policy expiry*</FormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="date"
                {...register("previous_policy_expiry", {
                  required: "Previous policy expiry is required",
                })}
              />
              {errors?.previous_policy_expiry && (
                <Text color="red.500" fontSize="sm">
                  {errors?.previous_policy_expiry?.message}
                </Text>
              )}
            </FormControl>
          </HStack>
        )}
      </Box>

      <Flex w="100%" justify="space-between" align="center" mt="20px">
        <Button
          type="button"
          borderRadius="21px"
          onClick={() => setStep(step - 1)}
        >
          Previous
        </Button>
        <Button type="submit" borderRadius="21px" colorScheme="green">
          Next
        </Button>
      </Flex>
    </form>
  );
};

export default GroupHealthInsurance;
