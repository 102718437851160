import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { theme } from "../../partials/utils/Contants";
import { useStore } from "../../store/store";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import { useEffect, useState } from "react";

type FormData = {
  con_person_name: string;
  comp_name: string;
  BusinessType: string;
  comp_gstin: string;
  comp_contact: number;
  comp_email: string;
  country: string;
  bus_des: string;
};

type Field = {
  name: keyof FormData;
  label: string;
  isRequired: boolean;
  placeholder: string;
  type: string;
  field: "input" | "select" | "textarea";
  list?: string[];
  max?: number;
};

const CreateCompanyFrom = ({ editData }: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormData>({
    defaultValues: {
      country: "India",
    },
  });
  const toast = useToast();
  const { step, setStep, countries, businessType } = useStore();
  const [companyId, setCompanyId] = useState<string | number>();
  const CompanyDetailFields: Field[] = [
    {
      name: "con_person_name",
      label: "Contact Person Name",
      isRequired: true,
      placeholder: "Enter contact person name",
      type: "text",
      field: "input",
      max: 255,
    },
    {
      name: "comp_name",
      label: "Company Name",
      isRequired: true,
      placeholder: "Enter company name",
      type: "text",
      field: "input",
    },
    {
      name: "BusinessType",
      label: "Business Type",
      isRequired: true,
      placeholder: "Please select business type",
      type: "text",
      field: "select",
      list: businessType,
    },
    {
      name: "comp_gstin",
      label: "Company GSTIN",
      isRequired: false,
      placeholder: "Enter company GSTIN",
      type: "text",
      field: "input",
      max: 255,
    },
    {
      name: "comp_contact",
      label: "Company Contact",
      isRequired: true,
      placeholder: "Enter company contact",
      type: "number",
      field: "input",
      max: 10,
    },
    {
      name: "comp_email",
      label: "Company Email",
      isRequired: true,
      placeholder: "Enter email",
      type: "email",
      field: "input",
    },
    {
      name: "country",
      label: "Country",
      isRequired: true,
      placeholder: "Please Select Country",
      type: "text",
      field: "select",
      list: countries,
    },
    {
      name: "bus_des",
      label: "Business Description",
      isRequired: true,
      placeholder: "",
      type: "text",
      field: "textarea",
    },
  ];

  const onSubmit = handleSubmit((data) => {
    let Body: any = { ...data };
    if (editData && companyId) {
      Body = { ...data, comp_id: companyId };
    }
    useFetch
      .post("company", Body)
      .then((response) => {
        const msg = editData?.id
          ? `Bid with Bid ID #${response?.data?.company.id} updated successfully`
          : `Bid initiated with Bid ID #${response?.data?.company.id}`;
        toast({
          title: msg,
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        localStorage.setItem("comp_id", response?.data?.company?.id);
        setStep(4);
      })
      .catch((error) => {
        console.log("error", error);
        toast({
          title: error?.response?.data?.message || "Something went wrong",
          description: "",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  });
  function fetchlatestCompany() {
    useFetch
      .get("get-user-company")
      .then((response) => {
        const data = response?.data?.company;
        if (data) {
          setValue("BusinessType", data?.business_type);
          setValue("comp_contact", data?.mobile);
          setValue("bus_des", data?.description);
          setValue("comp_name", data?.company_name);
          setValue("country", data?.country);
          setValue("comp_email", data?.email);
          setValue("con_person_name", data?.contact_person_name);
          setValue("comp_gstin", data?.gstin);
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  }

  useEffect(() => {
    if (!editData && !editData?.id) {
      fetchlatestCompany();
    } else {
      setCompanyId(editData?.id);
      setValue("BusinessType", editData?.business_type);
      setValue("comp_contact", editData?.c_contact);
      setValue("bus_des", editData?.c_description);
      setValue("comp_name", editData?.c_name);
      setValue("country", editData?.country);
      setValue("comp_email", editData?.c_email);
      setValue("con_person_name", editData?.c_p_name);
      setValue("comp_gstin", editData?.c_gstin);
    }
  }, [editData]);
  useEffect(() => {
    if (!editData?.id && localStorage.getItem("comp_id")) {
      const comp_id = localStorage.getItem("comp_id");
      if (comp_id) setCompanyId(comp_id);
    }
  }, []);

  return (
    <form
      onSubmit={onSubmit}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "30px",
      }}
    >
      <SimpleGrid columns={2} gap="20px" mt="15px">
        {CompanyDetailFields.map((field) => (
          <FormControl key={field.name} isInvalid={Boolean(errors[field.name])}>
            <Flex align="start">
              <FormLabel me="3px" color="rgba(35,28,99,.7)" fontWeight="600">
                {field.label}
              </FormLabel>{" "}
              {field.isRequired && (
                <Text color="red.500" fontWeight="500">
                  *
                </Text>
              )}
            </Flex>
            {field.field === "textarea" ? (
              <Textarea
                placeholder={field.placeholder}
                {...register(field.name, {
                  required: field.isRequired,
                })}
                title={field.isRequired ? "Please fill out this field" : ""}
                bg="white"
              />
            ) : field.field === "input" ? (
              <Input
                type={field.type}
                bg="white"
                borderRadius="21px"
                placeholder={field.placeholder}
                color={theme.color.INPUT_FONT}
                {...register(field.name, {
                  required: field.isRequired,
                  ...(field.name === "comp_contact" && {
                    validate: (value) =>
                      String(value).length === 10 ||
                      "Phone number must be 10 digits long",
                  }),
                  ...(field.name === "comp_email" && {
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Please enter a valid email address",
                    },
                  }),
                })}
                title={field.isRequired ? "Please fill out this field" : ""}
              />
            ) : (
              <Select
                borderRadius="21px"
                bg="white"
                placeholder={field.placeholder}
                {...register(field.name, {
                  required: field.isRequired,
                })}
                title={field.isRequired ? "Please fill out this field" : ""}
              >
                {field.list?.map((item: any) => (
                  <option key={item.id} value={item?.name}>
                    {item?.name}
                  </option>
                ))}
              </Select>
            )}
            {errors[field.name] && (
              <FormErrorMessage>
                {errors[field.name]?.message || `${field.label} is required`}
              </FormErrorMessage>
            )}
          </FormControl>
        ))}
      </SimpleGrid>
      <Flex w="100%" justify="space-between">
        {editData?.id && step === 3 ? (
          <Flex minW="30px" />
        ) : (
          <Button onClick={() => setStep(step - 1)} borderRadius="21px">
            Back
          </Button>
        )}
        <Button
          colorScheme="green"
          borderRadius="21px"
          bg={theme.color.GREEN}
          color="white"
          type="submit"
        >
          Next
        </Button>
      </Flex>
    </form>
  );
};

export default CreateCompanyFrom;
