import { create } from "zustand";

interface StepStore {
  user: object;
  setUser: (newData: any) => void;
  step: number;
  setStep: (newStep: number) => void;
  selectedCategories: string[];
  setSelectedCategories: (categories: string[]) => void;
  handleRemoveCategory: any;
  countries: any[]; // Assuming countries can be any type, adjust as needed
  setCountries: (country: any[]) => void;
  companyType: any; // Example of a any type, adjust as per your requirement
  setCompanyType: (type: any) => void;
  businessType: any[];
  setBusinessType: (type: any) => void;
  occupancy: any[];
  setOccupancy: (type: any) => void;
  product: any[];
  setProduct: (products: any[]) => void;
  selectedBid: any;
  setSelectedBid: (selectedBid: any) => void;
}

export const useStore = create<StepStore>((set: any) => ({
  user: {},
  setUser: (newData: any) => set({ user: newData }),
  step: 1,
  setStep: (newStep: number) => set({ step: newStep }),
  selectedCategories: [],
  setSelectedCategories: (categories) =>
    set({ selectedCategories: categories }),
  handleRemoveCategory: (category: any) =>
    set((state: any) => ({
      selectedCategories: state.selectedCategories.filter(
        (item: any) => item !== category
      ),
    })),
  countries: [],
  setCountries: (country) => set({ countries: country }),
  companyType: [],
  setCompanyType: (type) => set({ companyType: type }),
  businessType: [],
  setBusinessType: (type) => set({ businessType: type }),
  occupancy: [],
  setOccupancy: (type) => set({ occupancy: type }),
  product: [],
  setProduct: (products) => set({ product: products }),
  selectedBid: {},
  setSelectedBid: (newData: any) => set({ selectedBid: newData }),
}));
