import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import { Button, Flex, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import {
  addMissingFieldsToLabelMapping,
  flattenObject,
} from "../../partials/utils/helper";
import {
  fieldCategories,
  fieldsToExcludeBidder,
  labelMapping,
} from "../../partials/utils/tranlator";
import { ReactComponent as PdfIcon } from "../../partials/assets/icons/pdf-icon.svg";

const categorizeFields = (
  data: { [key: string]: any },
  labelMapping: { [key: string]: string },
  fieldCategories: { [category: string]: string[] },
  fieldsToExcludeBidder: string[]
) => {
  const categorizedData: { [category: string]: { key: string; value: any }[] } =
    {};

  Object.entries(data).forEach(([key, value]) => {
    if (value !== null && !fieldsToExcludeBidder.includes(key)) {
      if (!(key in labelMapping)) {
        labelMapping[key] = key.replace(/_/g, " ");
      }

      const category =
        Object.keys(fieldCategories).find((category) =>
          fieldCategories[category].includes(labelMapping[key])
        ) || "Other Details";

      if (!categorizedData[category]) {
        categorizedData[category] = [];
      }
      categorizedData[category].push({ key, value });
    }
  });

  return categorizedData;
};

const BidDetails = ({ id }: any) => {
  const [data, setData] = useState<any>();
  const [categorizedData, setCategorizedData] = useState<any>({});
  const [bidData, setBidData] = useState<any>();

  function fetchBidData() {
    useFetch
      .get(`editbid/${id}`)
      .then((res) => {
        setBidData(res.data?.company);
        const flattenedData = flattenObject(res.data.company);
        addMissingFieldsToLabelMapping(flattenedData, labelMapping);
        setData(flattenedData);
      })
      .catch((error) => {});
  }
  useEffect(() => {
    fetchBidData();
  }, [id]);
  useEffect(() => {
    if (data) {
      setCategorizedData(
        categorizeFields(
          data,
          labelMapping,
          fieldCategories,
          fieldsToExcludeBidder
        )
      );
    }
  }, [data]);
  const downloadFile = async (fileName: string) => {
    try {
      const response = await useFetch.get(
        `download-file?file_name=${fileName}`,
        {
          responseType: "blob",
        }
      );

      const fileBlob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(fileBlob);
      link.download = fileName;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  return (
    <Flex
      w="100%"
      gap="10px"
      direction="column"
      p="0px 14px 25px 0px"
      color="white"
    >
     {bidData?.pdf_file && <Flex w="100%" px="10px" pt="5px" justify="end">
        <Button
          leftIcon={<PdfIcon />}
          size="xs"
          fontSize="12px"
          borderRadius="16px"
          onClick={() =>
            downloadFile(bidData?.pdf_file?.replace("uploads/uploads/", ""))
          }
          colorScheme="green"
        >
          Download RFQ
        </Button>
      </Flex>}
      {Object.entries(categorizedData)
        .sort(([a], [b]) => {
          if (a === "Company Details") return -1;
          if (b === "Company Details") return 1;
          if (a === "Policy Details") return -1;
          if (b === "Policy Details") return 1;
          if (a === "Other Details") return 1;
          if (b === "Other Details") return -1;
          return a.localeCompare(b);
        })
        .map(([category, fields]: any) => {
          if (category && fields?.length >= 1)
            return (
              <Flex
                direction="column"
                key={category}
                gap="12px"
                pb="30px"
                pt="20px"
                borderBottom="1px solid rgba(246, 246, 246, 0.6)"
              >
                <Text fontSize="16px" fontWeight="600">
                  {category}
                </Text>
                <Flex gap="20px" wrap="wrap">
                  {fields?.map(({ key, value }: any) => {
                    if (value || value !== null)
                      return (
                        <Flex key={key} gap="6px" fontSize="12px">
                          <Text
                            textTransform="capitalize"
                            fontWeight="bold"
                            color="rgba(246, 246, 246, 0.60)"
                            whiteSpace="nowrap"
                          >
                            {labelMapping[key] || key}:{" "}
                          </Text>
                          <Text>
                            {value !== null ? value.toString() : "N/A"}
                          </Text>
                        </Flex>
                      );
                  })}
                </Flex>
              </Flex>
            );
        })}
    </Flex>
  );
};

export default BidDetails;
