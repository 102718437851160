import { Navigate, Outlet } from "react-router-dom";
import { LinkItemsClient } from "../../../routes";
import Sidebar from "../../../components/Sidebar";
import { useFetchtoken } from "../../../partials/utils/User";

const PrivateRoutes = () => {
    const token = useFetchtoken();


  return token ? (
    <Sidebar
    sidebaroptions={LinkItemsClient}
    >
      <Outlet />
    </Sidebar>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoutes;
