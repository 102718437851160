import { ReactComponent as HomeIcon } from "./partials/assets/icons/smart_home.svg";
import { ReactComponent as CreatBidIcon } from "./partials/assets/icons/ce__magic-wand.svg";
import { ReactComponent as BidCenterIcon } from "./partials/assets/icons/category-2.svg";
import { ReactComponent as ArchiveIcon } from "./partials/assets/icons/archive.svg";
import { ReactComponent as DocumentIcon } from "./partials/assets/icons/ce__folders.svg";
import { ReactComponent as RolesIcon } from "./partials/assets/icons/accessible.svg";
import { ReactComponent as TeamsIcon } from "./partials/assets/icons/users.svg";

interface LinkItemProps {
  label: string;
  icon: any;
  link: string;
}
export const LinkItemsClient: Array<LinkItemProps> = [
  { label: "Home", icon: HomeIcon, link: "/home" },
  { label: "Create Bid", icon: CreatBidIcon, link: "/create-bid" },
  { label: "Bid Centre", icon: BidCenterIcon, link: "/bid-centre" },
  { label: "Archived Bids", icon: ArchiveIcon, link: "/archived-bids" },
  // { label: "Conversations", icon: ConversationIcon, link: "/conversations" },
  { label: "Document Vault", icon: DocumentIcon, link: "/document-vault" },
  { label: "Roles", icon: RolesIcon, link: "/roles" },
  { label: "Team Members", icon: TeamsIcon, link: "/team-members" },
];
export const LinkItemsBidder: Array<LinkItemProps> = [
  { label: "Home", icon: HomeIcon, link: "/home" },
  { label: "Bid Centre", icon: BidCenterIcon, link: "/bid-centre" },
  { label: "Roles", icon: RolesIcon, link: "/roles" },
  { label: "Teams", icon: TeamsIcon, link: "/team-members" },
];
