import { Spinner, Box, Flex } from "@chakra-ui/react";

const LoadingScreen = () => {
  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      bg="rgba(0, 0, 0, 0.6)"
      zIndex="9999"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Flex direction="column" alignItems="center">
        <Spinner size="xl" color="white" mb={4} />
        <Box color="white" fontSize="xl">
          Loading...
        </Box>
      </Flex>
    </Box>
  );
};

export default LoadingScreen;
