import axios from "axios";
import Cookies from "js-cookie";
const useFetch = axios.create({
  // baseURL: "https://dev.sanctuari.in/sanctuari/public/index.php/api/",
    baseURL: process.env.REACT_APP_BACKEND_URL,
});

useFetch.interceptors.request.use(
  async (config: any) => {
    
    if (Cookies.get('jwt')) {
      const token = Cookies.get('jwt');      
      config.headers!.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (error: any) {
    return Promise.reject(error);
  }
);
useFetch.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: any) {
    if (error?.response?.status === 401) {
      // Cookies.remove('jwt')
      // Cookies.remove('user')
      // logoutHandler();
      // window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

// return () => useFetch.interceptors.response.eject(interceptor);

export default useFetch;
