import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import PrivateRoutes from "./PrivateRoute";
import NotFoundPage from "../../pages/NotFound";
import HomePage from "../../pages/Home";
import Conversations from "../../pages/Conversations";
import BidCentre from "../../pages/BidCentre";
import BidDetails from "../../pages/BidDetails";
import ArchivedBids from "../../pages/ArchivedBids";
import DocumentVault from "../../pages/DocumentVault";
import DefaultVaultListing from "../../pages/DefaultVaultListing";
import DefaultFiles from "../../pages/DefaultVaultListing/DefaultFiles/indes";
import CreatedFolderListing from "../../pages/CreatedFoldersListing";
import CreateBid from "../../pages/CreateBid";
import EditBid from "../../pages/EditBid";
import RolesPage from "../../pages/RolesPage";
import TeamMembers from "../../pages/TeamMembers";
import ClaimListing from "../../pages/ClaimListing";


export const ClientRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* we want to protect these routes */}
        <Route element={<PrivateRoutes />}>
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/conversations" element={<Conversations />} />
        <Route path="/bid-centre" element={<BidCentre />} />
        <Route path="/bid-detail/:id" element={<BidDetails />} />
        <Route path="/archived-bids" element={<ArchivedBids />} />
        <Route path="/document-vault" element={<DocumentVault />} />
        <Route path="/claim-form/:product" element={<ClaimListing />} />
        <Route
          path="/document-vault/default-listing"
          element={<DefaultVaultListing />}
        />
        <Route
          path="/document-vault/default-files"
          element={<DefaultFiles />}
        />
        <Route
          path="/document-vault/created-files"
          element={<CreatedFolderListing />}
        />
        <Route path="/create-bid" element={<CreateBid />} />
        <Route path="/edit-bid/:id" element={<EditBid />} />
        <Route path="/roles" element={<RolesPage />} />
        <Route path="/team-members" element={<TeamMembers />} />
         
        </Route>

        {/* catch all */}
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};
