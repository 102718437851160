import {
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Spinner,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";

import { GoArrowUpRight } from "react-icons/go";
import { FaPlus } from "react-icons/fa";
import { ReactComponent as InfoIcon } from "../../partials/assets/icons/info-circle.svg";

import { theme } from "../../partials/utils/Contants";
import BidCard from "../../components/BidCard";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../store/store";
import SanbotAI from "../SanbotAI";
import noBidImg from "../../partials/assets/images/emoji-no-bid.png";
import CreateBidCard from "../CreateBidCard/CreateBidCard";

const HomeBidCentre = ({
  liveBidsLsit,
  draftedBidsList,
  completedBidsList,
  engagedBidsList,
  isLiveLoading,
  isDraftedLoading,
  isCompletedLoading,
  isEngagedLoading,
  fetchLiveBids,
  fetchCompletedBids,
  fetchDraftedBids,
  fetchEngagedbidBids,
  selectedBid,
  setSelectedBid,
}: any) => {
  const navigate = useNavigate();
  const { setStep, selectedCategories, setSelectedCategories } = useStore();
  const CreateBidData = [
    {
      id: 1,
      title: "Group Health Insurance",
      desc: "A group mediclaim policy provides health insurance coverage to a collective group, such as employees or organization members, covering risks like hospitalization expenses and medical treatments at lower premiums through group bargaining.",
    },
    {
      id: 2,
      title: "Standard Fire",
      desc: "Tailored Insurance covers material damage caused to property and interior by providing complete coverage to the insured.Fire insurance policy's subject matter can be any property with financial value, such as buildings (including plinth and foundation), plants and types of machinery, stocks, furniture, fixtures and fittings, other contents, stocks etc",
    },
    {
      id: 3,
      title: "Employees/Workmen Compensation",
      desc: "Workmen's Compensation Insurance provides coverage for employees who suffer work-related injuries or illnesses. It offers financial protection by compensating for medical expenses, lost wages, and disability benefits. Employers obtain this insurance to fulfill legal obligations and ensure the well-being of their....",
    },
  ];

  const ChatCard = () => {
    return (
      <Flex
        direction="column"
        w="100%"
        gap="10px"
        borderBottom="1px solid #6E6E6E"
        p="2px 5px 20px"
        position="relative"
      >
        <Flex
          w="100%"
          justify="space-between"
          align="center"
          color="#D0EFF1"
          fontSize="16px"
          fontWeight="700"
          mb="3px"
        >
          <Text>Hannah Morgan</Text>
          <Text>1:23 PM</Text>
        </Flex>
        <Text color="#D0EFF1" fontSize="14px" fontWeight="500">
          Revised Bid
        </Text>
        <Text
          color="rgba(255, 255, 255, 0.50)"
          fontSize="12px"
          fontWeight="400"
        >
          Hi Jennifer, I have revised the bid as per your requirements. By
          including maternity in the mediclaim policy can be included with....
        </Text>
      </Flex>
    );
  };
  return (
    <Flex height="100%" width="100%">
      <Flex
        direction="column"
        padding="30px 80px 30px 30px"
        flex={2}
        gap="10px"
      >
        <Flex align="center" w="100%" justify="space-between">
          <Text as="h2" fontSize="24px" fontWeight="500">
            Bid Centre{" "}
          </Text>
          <Button
            rightIcon={<GoArrowUpRight />}
            borderRadius="24px"
            bg="white"
            colorScheme="white"
            color="black"
            border="1px solid #dadada"
            minW="104px"
            _hover={{ filter: "brightness(1.2)", color: "gray.600" }}
            onClick={() => {
              setStep(1);
              navigate("/create-bid");
              setSelectedCategories([]);
            }}
          >
            Launch
          </Button>
        </Flex>
        <Tabs position="relative" variant="unstyled" width="100%">
          <TabList gap="40px">
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              color={theme.color.GRAY_LABEL}
              _selected={{
                color: "black",
              }}
              onClick={() => setSelectedBid({ data: null, action: "" })}
            >
              Live Bids
            </Tab>
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              color={theme.color.GRAY_LABEL}
              _selected={{
                color: "black",
              }}
              onClick={() => setSelectedBid({ data: null, action: "" })}
            >
              Draft Bids
            </Tab>
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              color={theme.color.GRAY_LABEL}
              _selected={{
                color: "black",
              }}
              onClick={() => setSelectedBid({ data: null, action: "" })}
            >
              Engaged Bids
            </Tab>
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              color={theme.color.GRAY_LABEL}
              _selected={{
                color: "black",
              }}
              onClick={() => setSelectedBid({ data: null, action: "" })}
            >
              Completed Bids
            </Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="black"
            borderRadius="1px"
          />
          <TabPanels>
            <TabPanel px="0px">
              {!isLiveLoading && liveBidsLsit?.length ? (
                <Flex direction="column" w="100%" align="center" gap="12px">
                  {liveBidsLsit?.slice(0, 3).map((item: any) => (
                    <BidCard
                      data={item}
                      type="live"
                      fetchData={fetchLiveBids}
                      selectedBid={selectedBid}
                      setSelectedBid={setSelectedBid}
                    />
                  ))}
                </Flex>
              ) : (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  justify="center"
                  minH="200px"
                >
                  {isLiveLoading ? (
                    <Spinner />
                  ) : (
                    <Flex
                      direction="column"
                      align="center"
                      gap="25px"
                      pt="40px"
                    >
                      <Text fontWeight="600">
                        No bids yet. Create your bid now
                      </Text>
                      <Image src={noBidImg} height="182px" width="207px" />
                      <Button
                        borderRadius="24px"
                        bg="black"
                        colorScheme="black"
                        color="white"
                        padding="4px 14px"
                        fontSize="12px"
                        fontWeight="500"
                        w="104px"
                        h="24px"
                        onClick={() => navigate("/create-bid")}
                      >
                        Create Bid
                      </Button>
                    </Flex>
                  )}
                </Flex>
              )}
            </TabPanel>

            <TabPanel px="0px">
              {!isDraftedLoading && draftedBidsList?.length ? (
                <Flex direction="column" w="100%" align="center" gap="12px">
                  {draftedBidsList?.slice(0, 3).map((item: any) => (
                    <BidCard
                      data={item}
                      type="draft"
                      fetchData={fetchDraftedBids}
                    />
                  ))}
                </Flex>
              ) : (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  justify="center"
                  minH="200px"
                >
                  {isDraftedLoading ? (
                    <Spinner />
                  ) : (
                    <Flex
                      direction="column"
                      align="center"
                      gap="25px"
                      pt="40px"
                    >
                      <Text fontWeight="600">
                        No bids yet. Create your bid now
                      </Text>
                      <Image src={noBidImg} height="182px" width="207px" />
                      <Button
                        borderRadius="24px"
                        bg="black"
                        colorScheme="black"
                        color="white"
                        padding="4px 14px"
                        fontSize="12px"
                        fontWeight="500"
                        w="104px"
                        h="24px"
                        onClick={() => navigate("/create-bid")}
                      >
                        Create Bid
                      </Button>
                    </Flex>
                  )}
                </Flex>
              )}
            </TabPanel>

            <TabPanel px="0px">
              {!isEngagedLoading && engagedBidsList?.length ? (
                <Flex direction="column" w="100%" align="center" gap="12px">
                  {engagedBidsList?.slice(0, 3)?.map((item: any) => (
                    <BidCard
                      data={item}
                      type="engaged"
                      fetchData={fetchEngagedbidBids}
                      selectedBid={selectedBid}
                      setSelectedBid={setSelectedBid}
                    />
                  ))}
                </Flex>
              ) : (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  justify="center"
                  minH="200px"
                >
                  {isEngagedLoading ? (
                    <Spinner />
                  ) : (
                    <Flex
                      direction="column"
                      align="center"
                      gap="25px"
                      pt="40px"
                    >
                      <Text fontWeight="600">
                        No bids yet. Create your bid now
                      </Text>
                      <Image src={noBidImg} height="182px" width="207px" />
                      <Button
                        borderRadius="24px"
                        bg="black"
                        colorScheme="black"
                        color="white"
                        padding="4px 14px"
                        fontSize="12px"
                        fontWeight="500"
                        w="104px"
                        h="24px"
                        onClick={() => navigate("/create-bid")}
                      >
                        Create Bid
                      </Button>
                    </Flex>
                  )}
                </Flex>
              )}
            </TabPanel>
            <TabPanel px="0px">
              {!isCompletedLoading && completedBidsList?.length ? (
                <Flex direction="column" w="100%" align="center" gap="12px">
                  {completedBidsList?.slice(0, 3).map((item: any) => (
                    <BidCard
                      data={item}
                      type="completed"
                      fetchData={fetchCompletedBids}
                      selectedBid={selectedBid}
                      setSelectedBid={setSelectedBid}
                    />
                  ))}
                </Flex>
              ) : (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  justify="center"
                  minH="200px"
                >
                  {isCompletedLoading ? (
                    <Spinner />
                  ) : (
                    <Flex
                      direction="column"
                      align="center"
                      gap="25px"
                      pt="40px"
                    >
                      <Text fontWeight="600">
                        No bids yet. Create your bid now
                      </Text>
                      <Image src={noBidImg} height="182px" width="207px" />
                      <Button
                        borderRadius="24px"
                        bg="black"
                        colorScheme="black"
                        color="white"
                        padding="4px 14px"
                        fontSize="12px"
                        fontWeight="500"
                        w="104px"
                        h="24px"
                        onClick={() => navigate("/create-bid")}
                      >
                        Create Bid
                      </Button>
                    </Flex>
                  )}
                </Flex>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
        <Flex direction="column" gap="20px">
          <Text fontSize="24px" fontWeight="500" color="black">
            Create a new bid
          </Text>
          <Grid templateColumns="repeat(auto-fill, 295px)" w="100%" gap="30px">
            {CreateBidData.map((item) => (
              <GridItem w="100%" h="205px" key={item.id}>
                <CreateBidCard
                  item={item}
                  selectedCategories={selectedCategories}
                  setSelectedCategories={setSelectedCategories}
                />
              </GridItem>
            ))}
          </Grid>
        </Flex>
      </Flex>
      <SanbotAI selectedBid={selectedBid} />
      {/* <Flex
        direction="column"
        padding="80px 30px 30px 30px"
        flex={1}
        bg="black"
      >
        <Text fontSize="30px" fontWeight="500" color="white">
          Recent Conversations
        </Text>
        <Tabs position="relative" variant="unstyled" width="100%">
          <TabList gap="40px">
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              
              color="rgba(246, 246, 246, 0.50)"
              _selected={{
                color: "white",
              }}
            >
              All
            </Tab>
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              
              color="rgba(246, 246, 246, 0.50)"
              _selected={{
                color: "white",
              }}
            >
              Read
            </Tab>
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              
              color="rgba(246, 246, 246, 0.50)"
              _selected={{
                color: "white",
              }}
            >
              Unread
            </Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="black"
            borderRadius="1px"
            background="white"
          />
          <TabPanels>
            <TabPanel px="0px">
              <Flex
                direction="column"
                w="100%"
                height="76vh"
                overflowY="auto"
                className="hide-scrollbar"
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                  <ChatCard key={item} />
                ))}
              </Flex>
            </TabPanel>
            <TabPanel px="0px">
              <Flex
                direction="column"
                w="100%"
                height="76vh"
                overflowY="auto"
                className="hide-scrollbar"
              >
                {[1, 2, 3, 4].map((item) => (
                  <ChatCard key={item} />
                ))}
              </Flex>
            </TabPanel>
            <TabPanel px="0px">
              <Flex
                direction="column"
                w="100%"
                height="76vh"
                overflowY="auto"
                className="hide-scrollbar"
              >
                {[1, 2, 3, 4, 5, 6].map((item) => (
                  <ChatCard key={item} />
                ))}
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex> */}
    </Flex>
  );
};

export default HomeBidCentre;
