import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  FormErrorMessage,
  Box,
  SimpleGrid,
  HStack,
  Button,
  useToast,
  Flex,
} from "@chakra-ui/react";
import { useStore } from "../../../store/store";
import "../form.css";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import { flattenObject } from "../../../partials/utils/helper";
import { FaInfoCircle } from "react-icons/fa";

type FormData = {
  Type_of_Liablity: string;
  Coverage_type: string;
  policytype: string;
  insurer: string;
  comp_location: string;
  Location_Type: string;
  Description_of_Operation: string;
  Territory: string;
  Surrounding_areas: string;
  Nature_of_Work1: string;
  Address1: string;
  Surrounding_areas2: string;
  Nature_of_Work2: string;
  Address2: string;
  Type_of_construction: string;
  Age_of_Building: number;
  Address3: string;
  Building_Heightin_meter: string;
  Description_of_elevators: string;
  occupants_and_activities: string;
  Description_of_the_Product: string;
  Business_Turn_Over: number;
  Export: number;
  Destination: string;
  Retroactive_Date: string;
  AOA: number;
  AOY: number;
  Annual_Projected: number;
  Annual_Currently: number;
  Annual_last_year: number;
  Annual_Canada_Projected: number;
  Annual_Canada_Currently: number;
  Annual_USA_Canada_last_year: number;
  Annual_Rest_of_the_world_Projected: number;
  Annual_Rest_of_the_world_Currently: number;
  Annual_Rest_of_the_world_last_year: number;
  Number_of_employees_in_India: number;
  Number_of_employees_Usa_Canada: number;
  Number_of_employees_Rest_world: number;
  Number_of_premises_In_India: number;
  Number_of_premises_USA_Canada: number;
  Number_of_premises_rest_world: number;
  Vendors_Liability_Extension: number;
  Technical_collaborators_liability: number;
  Accidental_Pollution_Extension: number;
  Liability_arisingout_of_Transportation: number;
  arising_Transportation: number;
  Act_of_God_Extension: number;
  Terrorism_Extension: number;
  Goods_kept_in_Custody: number;
  Food_and_Beverages_Extension: number;
  Swimming_pools_Extension: number;
  Sports_facilities_extension: number;
  Personal_Advertising_Injury: number;
  Defense_Costs: number;
  legal_liability_renovation: number;
  Lifts_Elevators_Escalators: number;
  start_date: string;
  end_date: string;
  policy_start_date?: string;
  policy_end_date?: string;
  expected_premium: number;
  sum_insured: number;
  previous_insurer: string;
  previous_policy_expiry: string;
};

const CommercialLiability = ({ product, editData,mode }: any) => {
  const toast = useToast();
  const { step, setStep } = useStore();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      Age_of_Building: 0,
      Business_Turn_Over: 0,
      Export: 0,
      AOA: 0,
      AOY: 0,
      Annual_Projected: 0,
      Annual_Currently: 0,
      Annual_last_year: 0,
      Annual_Canada_Projected: 0,
      Annual_Canada_Currently: 0,
      Annual_USA_Canada_last_year: 0,
      Annual_Rest_of_the_world_Projected: 0,
      Annual_Rest_of_the_world_Currently: 0,
      Annual_Rest_of_the_world_last_year: 0,
      Number_of_employees_in_India: 0,
      Number_of_employees_Usa_Canada: 0,
      Number_of_employees_Rest_world: 0,
      Number_of_premises_In_India: 0,
      Number_of_premises_USA_Canada: 0,
      Number_of_premises_rest_world: 0,
      Vendors_Liability_Extension: 0,
      Technical_collaborators_liability: 0,
      Accidental_Pollution_Extension: 0,
      Liability_arisingout_of_Transportation: 0,
      arising_Transportation: 0,
      Act_of_God_Extension: 0,
      Terrorism_Extension: 0,
      Goods_kept_in_Custody: 0,
      Food_and_Beverages_Extension: 0,
      Swimming_pools_Extension: 0,
      Sports_facilities_extension: 0,
      Personal_Advertising_Injury: 0,
      Defense_Costs: 0,
      legal_liability_renovation: 0,
      Lifts_Elevators_Escalators: 0,
      start_date: new Date().toISOString().split("T")[0],
      end_date: new Date(new Date().setDate(new Date().getDate() + 10))
        .toISOString()
        .split("T")[0],
      expected_premium: 0,
      sum_insured: 0,
    },
  });

  const onSubmit = (data: FormData) => {
const compId = editData?.id ? editData?.company_id : localStorage.getItem("comp_id");    const Body = {
      comp_id: compId.toString(),
      product,
      ...data,
    };
    useFetch
      .post(`bid`, Body)
      .then((res) => {
        toast({
          title: `Bid ${editData?.id ? 'updated' : 'created'} successfully`,
          description: "Bid will be in draft till confirmation",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setStep(5);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (editData) {
      const flattenedData = flattenObject(editData);
      for (let key in flattenedData) {
        if (flattenedData.hasOwnProperty(key)) {
          setValue(key as keyof FormData, flattenedData[key]);
        }
      }
    }
  }, [editData]);
  const AOA = +watch('AOA') ;
  const AOY = +watch('AOY') ;

  useEffect(() => {
    const sum =  AOA + AOY;
    setValue('sum_insured', sum);
  }, [ AOA,AOY]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SimpleGrid columns={2} spacing={4}>
        <FormControl isInvalid={Boolean(errors?.Type_of_Liablity)}>
          <FormLabel>Type of Liability*</FormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Select Liability Type"
            {...register("Type_of_Liablity", { required: true })}
          >
            <option value="Public Liablity">Public Liability</option>
            <option value="Product liablity">Product Liability</option>
          </Select>
          <FormErrorMessage>
            {errors.Type_of_Liablity && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Coverage_type)}>
          <Flex align="start">
              <FormLabel> Coverage Type*</FormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                fontSize='11px'
                  color="gray.500"
                  title={watch('Coverage_type')==='Occurrence'?'Occurrence policies cover incidents that happen during the policy period, regardless of when a claim is made. The trigger for coverage in Occurrence policies is the occurrence of an event, not the timing of the claim report' :"In a Claims-Made policy, coverage is triggered when a claim is made against the insured during the policy period, regardless of when the incident occurred"}
                />
              )}
            </Flex>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Select Coverage Type"
            {...register("Coverage_type", { required: true })}
          >
            <option value="Occurrence">Occurrence</option>
            <option value="Claims Made">Claims Made</option>
          </Select>
          <FormErrorMessage>
            {errors.Coverage_type && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.policytype)}>
          <FormLabel>Policy Type*</FormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Select policy type"
            {...register("policytype", { required: true })}
          >
            <option value="New">New</option>
            <option value="Renewal">Renewal</option>
          </Select>
          <FormErrorMessage>
            {errors.policytype && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.insurer)}>
          <FormLabel>Bidder Preference*</FormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Preference"
            {...register("insurer", { required: true })}
          >
            <option value="Both">Both</option>
            <option value="Brokers">Brokers</option>
            <option value="INS_COMP">Insurance Company</option>
          </Select>
          <FormErrorMessage>
            {errors.insurer && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.comp_location)}>
          <FormLabel>Where is your company located?*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("comp_location", { required: true })}
          />
          <FormErrorMessage>
            {errors.comp_location && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Location_Type)}>
          <FormLabel>Location Type*</FormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Location Type"
            {...register("Location_Type", { required: true })}
          >
            <option value="Single">Single</option>
            <option value="Multiple">Multiple</option>
          </Select>
          <FormErrorMessage>
            {errors.Location_Type && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Description_of_Operation)}>
          <FormLabel>Description of Operation*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Description_of_Operation", { required: true })}
          />
          <FormErrorMessage>
            {errors.Description_of_Operation && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Territory)}>
          <FormLabel>Territory*</FormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Territory"
            {...register("Territory", { required: true })}
          >
            <option value="India Only">India Only</option>
            <option value="Worldwide excluding US Canada">
              Worldwide excluding US Canada
            </option>
            <option value="Worldwide">Worldwide</option>
          </Select>
          <FormErrorMessage>
            {errors.Territory && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <Box borderTop="1px solid" mt={2} gridColumn="span 2">
          <FormLabel color="darkorchid" fontWeight="600">
            Manufacturing Units
          </FormLabel>
        </Box>

        <FormControl>
          <FormLabel>
            Surrounding areas – Industrial/ Agricultural/ Residential
          </FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Surrounding_areas")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Nature of Work</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Nature_of_Work1")}
          />
        </FormControl>

        <FormControl gridColumn="span 2">
          <FormLabel>Address</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Address1")}
          />
        </FormControl>

        <Box borderTop="1px solid" mt={2} gridColumn="span 2">
          <FormLabel color="darkorchid">
            Warehouses/Godowns/Shops/Depots/ Tank Farms/Offices
          </FormLabel>
        </Box>

        <FormControl>
          <FormLabel>
            Surrounding areas – Industrial/ Agricultural/ Residential
          </FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Surrounding_areas2")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Nature of Work</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Nature_of_Work2")}
          />
        </FormControl>

        <FormControl gridColumn="span 2">
          <FormLabel>Address</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Address2")}
          />
        </FormControl>

        <Box borderTop="1px solid" mt={2} gridColumn="span 2">
          <FormLabel color="darkorchid">
            Non-manufacturing premises for business
          </FormLabel>
        </Box>

        <FormControl>
          <FormLabel>Type of construction (Rcc, Tem, Sheds)</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Type_of_construction")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Age of Building</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Age_of_Building")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Address</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Address3")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Building Height in meter/ floor</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Building_Heightin_meter")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            Description of elevators/escalators with make & capacity
          </FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Description_of_elevators")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            Details of other occupants and activities in the building
          </FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("occupants_and_activities")}
          />
        </FormControl>

        <Box borderTop="1px solid" mt={2} gridColumn="span 2">
          <FormLabel color="darkorchid" fontWeight="600">
            Product Information
          </FormLabel>
        </Box>

        <FormControl>
          <FormLabel>Description of the Product</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Description_of_the_Product")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Business Turn Over</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Business_Turn_Over")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Export Turn Over</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Export")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Destination</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Destination")}
          />
        </FormControl>
        <Box borderTop="1px solid" mt={2} gridColumn="span 2">
          <FormLabel color="darkorchid" fontWeight="600">
            Special Conditions
          </FormLabel>
        </Box>
        <FormControl isInvalid={Boolean(errors.Retroactive_Date)}>
          <FormLabel>Retroactive Date*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="date"
            {...register("Retroactive_Date", { required: true })}
          />
          <FormErrorMessage>
            {errors.Retroactive_Date && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.AOA)}>
          <Flex align="start">
              <FormLabel>AOA (Any One Accident)*</FormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                fontSize='11px'
                  color="gray.500"
                  title="Maximum payment amount for a single policy-covered an accident or incident"
                />
              )}
            </Flex>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("AOA", { required: true })}
          />
          <FormErrorMessage>
            {errors.AOA && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.AOY)}>
          <Flex align="start">
          <FormLabel>AOY ( Any One Year)*</FormLabel>

              {mode === "Guided" && (
                <FaInfoCircle
                fontSize='11px'
                  color="gray.500"
                  title="Maximum payout amount that the insurance provider will make for claims filed in a calendar year"
                />
              )}
            </Flex>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("AOY")}
          />
          <FormErrorMessage>
            {errors.AOY && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Annual_Projected)}>
          <FormLabel>Annual turnover In India (Projected)*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Annual_Projected")}
          />
          <FormErrorMessage>
            {errors.Annual_Projected && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Annual_Currently)}>
          <FormLabel>Annual turnover In India (Currently)*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Annual_Currently")}
          />
          <FormErrorMessage>
            {errors.Annual_Currently && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Annual_last_year)}>
          <FormLabel>Annual turnover In India (last year) *</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Annual_last_year")}
          />
          <FormErrorMessage>
            {errors.Annual_last_year && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Annual_Canada_Projected)}>
          <FormLabel>Annual turnover In USA Canada (Projected)*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Annual_Canada_Projected")}
          />
          <FormErrorMessage>
            {errors.Annual_Canada_Projected && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Annual_Canada_Currently)}>
          <FormLabel>Annual turnover In USA Canada (Currently)*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Annual_Canada_Currently")}
          />
          <FormErrorMessage>
            {errors.Annual_Canada_Currently && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Annual_USA_Canada_last_year)}>
          <FormLabel>Annual turnover In USA Canada (last year) *</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Annual_USA_Canada_last_year")}
          />
          <FormErrorMessage>
            {errors.Annual_USA_Canada_last_year && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={Boolean(errors.Annual_Rest_of_the_world_Projected)}
        >
          <FormLabel>
            Annual turnover In Rest of the world (Projected)*
          </FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Annual_Rest_of_the_world_Projected")}
          />
          <FormErrorMessage>
            {errors.Annual_Rest_of_the_world_Projected &&
              "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={Boolean(errors.Annual_Rest_of_the_world_Projected)}
        >
          <FormLabel>
            Annual turnover In Rest of the world (Currently)*
          </FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Annual_Rest_of_the_world_Currently")}
          />
          <FormErrorMessage>
            {errors.Annual_Rest_of_the_world_Projected &&
              "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={Boolean(errors.Annual_Rest_of_the_world_last_year)}
        >
          <FormLabel>
            Annual turnover In Rest of the world (last year) *
          </FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Annual_Rest_of_the_world_last_year")}
          />
          <FormErrorMessage>
            {errors.Annual_Rest_of_the_world_last_year &&
              "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Number_of_employees_in_India)}>
          <FormLabel>Number of employees in India*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Number_of_employees_in_India")}
          />
          <FormErrorMessage>
            {errors.Number_of_employees_in_India && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Number_of_employees_Usa_Canada)}>
          <FormLabel>Number of employees in Usa & Canada*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Number_of_employees_Usa_Canada")}
          />
          <FormErrorMessage>
            {errors.Number_of_employees_Usa_Canada && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Number_of_employees_Rest_world)}>
          <FormLabel>Number of employees in Rest of the world*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Number_of_employees_Rest_world")}
          />
          <FormErrorMessage>
            {errors.Number_of_employees_Rest_world && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Number_of_premises_In_India)}>
          <FormLabel>Number of premises In India*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Number_of_premises_In_India")}
          />
          <FormErrorMessage>
            {errors.Number_of_premises_In_India && "This field is required"}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(errors.Number_of_premises_USA_Canada)}>
          <FormLabel>Number of premises in USA & Canada*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Number_of_premises_USA_Canada")}
          />
          <FormErrorMessage>
            {errors.Number_of_premises_USA_Canada && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Number_of_premises_rest_world)}>
          <FormLabel>Number of premises in rest of the world*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Number_of_premises_rest_world")}
          />
          <FormErrorMessage>
            {errors.Number_of_premises_rest_world && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <Box borderTop="1px solid" mt={2} gridColumn="span 2">
          <FormLabel color="darkorchid">
            Optional Coverages
            <span style={{ fontSize: "11px", color: "gray" }}>
              (Sub limit)
            </span>{" "}
          </FormLabel>
        </Box>

        <FormControl>
          <FormLabel>Vendors Liability Extension</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Vendors_Liability_Extension")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Technical collaborators liability</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Technical_collaborators_liability")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Accidental Pollution Extension</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Accidental_Pollution_Extension")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Liability arising out of Transportation</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("arising_Transportation")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Act of God Extension</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Act_of_God_Extension")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Terrorism Extension</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Terrorism_Extension")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Goods kept in Custody</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Goods_kept_in_Custody")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Food and Beverages Extension</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Food_and_Beverages_Extension")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Swimming pools Extension</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Swimming_pools_Extension")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Sports facilities extension</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Sports_facilities_extension")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Personal & Advertising Injury</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Personal_Advertising_Injury")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>Defense Costs</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Defense_Costs")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            Legal liability arising out of performance of any renovation
          </FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("legal_liability_renovation")}
          />
        </FormControl>

        <FormControl >
          <FormLabel>
            Lifts, Elevators & Escalators Liability Extension
          </FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Lifts_Elevators_Escalators")}
          />
        </FormControl>
        <Box borderTop="1px solid" mt={2} gridColumn="span 2" />
        <FormControl id="start_date" isInvalid={Boolean(errors?.start_date)}>
          <FormLabel>Bid's Start Date*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="date"
            {...register("start_date", {
              required: "Start Date is required",
            })}
          />
          {errors?.start_date && (
            <FormErrorMessage>{errors?.start_date?.message}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl id="end_date" isInvalid={Boolean(errors?.end_date)}>
          <FormLabel>Bid's End Date*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="date"
            {...register("end_date", { required: "End Date is required" })}
          />
          {errors?.end_date && (
            <FormErrorMessage>{errors?.end_date?.message}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl id="policy_start_date">
          <FormLabel>Policy Start Date</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="date"
            {...register("policy_start_date")}
          />
        </FormControl>

        <FormControl id="policy_end_date">
          <FormLabel>Policy End Date</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="date"
            {...register("policy_end_date")}
          />
        </FormControl>

        <FormControl
          id="expected_premium"
          isInvalid={Boolean(errors?.expected_premium)}
        >
          <Flex align="start">
              <FormLabel> Anticipated premium*</FormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                fontSize='11px'
                  color="gray.500"
                  title="Anticipated premium for acquiring this policy."
                />
              )}
            </Flex>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            {...register("expected_premium", {
              required: "Anticipated premium is required",
            })}
          />
          {errors?.expected_premium && (
            <FormErrorMessage>
              {errors?.expected_premium?.message}
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl id="sum_insured" isInvalid={Boolean(errors?.sum_insured)}>
          <FormLabel>Limit of Indemnity*</FormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            {...register("sum_insured", {
              required: "Sum insured is required",
            })}
          />
          {errors?.sum_insured && (
            <FormErrorMessage>{errors?.sum_insured?.message}</FormErrorMessage>
          )}
        </FormControl>
        {watch("policytype") === "Renewal" && (
          <HStack gridColumn="span 2" gap="20px">
            <FormControl
              id="previous_insurer"
              isInvalid={Boolean(errors?.previous_insurer)}
            >
              <FormLabel>Previous Insurer*</FormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                {...register("previous_insurer", {
                  required: "Previous insurer is required",
                })}
              />
              {errors?.previous_insurer && (
                <FormErrorMessage>
                  {errors?.previous_insurer?.message}
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              id="previous_policy_expiry"
              isInvalid={Boolean(errors?.previous_policy_expiry)}
            >
              <FormLabel>Previous policy expiry*</FormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="date"
                {...register("previous_policy_expiry", {
                  required: "Previous policy expiry is required",
                })}
              />
              {errors?.previous_policy_expiry && (
                <FormErrorMessage>
                  {errors?.previous_policy_expiry?.message}
                </FormErrorMessage>
              )}
            </FormControl>
          </HStack>
        )}
      </SimpleGrid>
      <HStack w="100%" justify="space-between" align="center" mt="20px">
        <Button
          type="button"
          borderRadius="21px"
          onClick={() => setStep(step - 1)}
        >
          Previous
        </Button>
        <Button type="submit" borderRadius="21px" colorScheme="green">
          Next
        </Button>
      </HStack>
    </form>
  );
};
export default CommercialLiability;
