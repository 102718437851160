import React, { useEffect, useState } from "react";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import { useParams } from "react-router-dom";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
  Text,
  Icon,
  Button,
  useToast,
  Badge,
} from "@chakra-ui/react";
import { TbGridDots } from "react-icons/tb";
import { FaCloudDownloadAlt } from "react-icons/fa";

const ClaimListing = () => {
  const { product } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const fetchData = useFetch;
  function fetchListingData() {
    fetchData("claimforms")
      .then((response) => {
        const filtered = response?.data?.filter((x: any) =>
          x.product.toLowerCase().includes(product?.toLowerCase())
        );
        setData(filtered);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  }
  useEffect(() => {
    fetchListingData();
  }, []);

  return (
    <Flex
      w="100%"
      direction="column"
      gap="40px"
      bg="ghostwhite"
      padding="20px"
      borderRadius="21px"
    >
      <Text fontSize="1.2rem" fontWeight="700" color="#25396f" ps="5px">
        Claim Forms
      </Text>
      <TableContainer>
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th>
                <TbGridDots />
              </Th>

              <Th>insurer</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((item: any) => (
              <Tr key={item?.id}>
                <Td>
                  <TbGridDots />
                </Td>

                <Td>{item?.name}</Td>
                <Td>
                  <Button
                    bg="green"
                    padding="5px"
                    borderRadius="10px"
                    colorScheme="green"
                    as="a"
                    href={item?.formlink}
                    target="_blank"
                  >
                    <Icon
                      as={FaCloudDownloadAlt}
                      fontSize="20px"
                      color="white"
                    />
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
};

export default ClaimListing;
