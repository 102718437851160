import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Flex, Icon, Image, Text } from "@chakra-ui/react";

import { BsThreeDots } from "react-icons/bs";
import { IoIosArrowForward } from "react-icons/io";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { HiOutlineSparkles } from "react-icons/hi2";
import { GoShare } from "react-icons/go";
import { RxCross1 } from "react-icons/rx";
import FolderIcon from "../../partials/assets/icons/folder.svg";

import useClickOutside from "../../hooks/useClickOutside";
import { theme } from "../../partials/utils/Contants";

import "./thumbnail.css";

const DocumentThumbnail = ({
  title,
  id,
  fileCount,
  data,
  type,
  handleClick,
  handleDownload,
  handleDelete,
}: any) => {
  const [isOpen, setIsOpen] = useState({ title: "", value: false });
  const menuRef = useClickOutside(() => setIsOpen({ title: "", value: false }));
  const extension = data?.url?.split(".");

  const toggleDropdown = () => {
    if (isOpen.value && isOpen.title !== "") {
      setIsOpen({ title: "", value: false });
    } else {
      setIsOpen({ title: title, value: true });
    }
  };
  const handleData = () => {
    if (type === "default") {
      handleClick(data?.id, data?.name);
    } else if (type === "default-listing") {
      handleClick(id, data?.name);
    } else if (type === "created") {
      handleClick(id, data?.name);
    } else {
      handleClick();
    }
  };
  return (
    <Flex
      direction="column"
      w="245px"
      padding="16px"
      gap="20px"
      borderRadius="16px"
      className="thumbnail-bg"
    >
      <Flex align="start" w="100%" justify="space-between">
        <Image
          src={FolderIcon}
          height="48px"
          width="48px"
          alt="folder"
          onClick={handleData}
          cursor="pointer"
        />
        <Flex direction="column" position="relative">
          {isOpen.value ? (
            <Icon
              as={RxCross1}
              fontSize="24px"
              onClick={toggleDropdown}
              cursor="pointer"
            />
          ) : (
            <Icon
              as={BsThreeDots}
              fontSize="24px"
              onClick={toggleDropdown}
              cursor="pointer"
            />
          )}
          {isOpen.value && isOpen.title === title && (
            <Flex
              ref={menuRef}
              direction="column"
              p="20px 0px 10px"
              w="207px"
              bg="black"
              color="white"
              position="absolute"
              top="20px"
              right="2px"
              gap="12px"
              borderRadius="16px"
              zIndex="99"
            >
              <Text color="white" px="20px">
                Actions:
              </Text>
              <Flex direction="column" gap="5px">
                <Flex
                  align="center"
                  w="100%"
                  justify="space-between"
                  color="white"
                  p="10px 20px"
                  _hover={{ bg: "gray.600", color: "gray.100" }}
                  cursor="pointer"
                >
                  <Flex align="center" gap="8px">
                    <Icon as={IoCloudDownloadOutline} fontSize="21px" />
                    <Text
                      fontSize="14px"
                      fontWeight="500"
                      onClick={() =>
                        handleDownload(id, title?.replace("_", " "))
                      }
                    >
                      Download Zip
                    </Text>
                  </Flex>
                  {/* <Icon as={IoIosArrowForward} fontSize="12px" /> */}
                </Flex>
                <Flex
                  align="center"
                  w="100%"
                  justify="space-between"
                  color="white"
                  p="10px 20px"
                  _hover={{ bg: "gray.600", color: "gray.100" }}
                  cursor="pointer"
                >
                  <Flex align="center" gap="8px">
                    <Icon as={GoShare} fontSize="21px" />
                    <Text fontSize="14px" fontWeight="500">
                      Share
                    </Text>
                  </Flex>
                  {/* <Icon as={IoIosArrowForward} fontSize="12px" /> */}
                </Flex>
                <Flex
                  align="center"
                  w="100%"
                  justify="space-between"
                  color="white"
                  p="10px 20px"
                  _hover={{ bg: "gray.600", color: "gray.100" }}
                  cursor="pointer"
                >
                  <Flex align="center" gap="8px">
                    <Icon as={HiOutlineSparkles} fontSize="21px" />
                    <Text fontSize="14px" fontWeight="500">
                      Insights
                    </Text>
                  </Flex>
                  <Flex
                    fontSize="10px"
                    fontWeight="500"
                    borderRadius="12px"
                    bg={theme.color.GREEN}
                    color="#F6F6F6"
                    padding="1px 5px"
                    w="75px"
                    height="18px"
                  >
                    Coming Soon
                  </Flex>
                  {/* <Icon as={IoIosArrowForward} fontSize="12px" /> */}
                </Flex>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Flex>
      <Flex direction="column" gap="2px">
        <Text
          fontSize="16px"
          fontWeight="500"
          color="black"
          textTransform="capitalize"
        >
          {title?.replace("_", " ")}
        </Text>
        <Text
          fontSize="14px"
          fontWeight="400"
          color="rgba(0, 0, 0, 1)"
          onClick={handleData}
          cursor="pointer"
        >
          {fileCount} Files
        </Text>
      </Flex>
    </Flex>
  );
};

export default DocumentThumbnail;
