import {
  Flex,
  FormControl,
  FormLabel,
  Select,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ProductsList } from "../../partials/utils/Contants";
import MoneyForm from "./MoneyForm/MoneyForm";
import BurglaryForm from "./BurglaryForm/BurglaryForm";
import { useStore } from "../../store/store";
import GroupTermLife from "./GroupTermLife/GroupTermLife";
import GroupHealthInsurance from "./GroupHealthInsurance";
import MarineForm from "./MarineForm";
import { MarineList } from "../../partials/utils/BidCreation";

import ProfessionalIndemnity from "./ProfessionalIndemnity";
import GroupPersonalAccident from "./GroupPersonalAccident";
import LiftLiability from "./LiftLiability";
import StandardFire from "./StandardFire";
import WorkmenCompensation from "./WorkmenCompensation";
import DirectorsOfficerInsurance from "./DirectorsOfficersInsurance";
import ContractosAllRisks from "./ContractorsAllRisks";
import CommercialLiability from "./CommercialLiability";
import ContractorssPlantMachinery from "./ContractorsPlantMachinery";
import "./form.css";
import { ModeProps } from "../../pages/CreateBid";
type FormsProps = {
  editData?: any;
  mode?: ModeProps;
};
const Forms = ({ editData, mode }: FormsProps) => {
  const [product, setProduct] = useState("");
  const { selectedCategories } = useStore();
  useEffect(() => {

    setProduct(selectedCategories[0]);
  }, [selectedCategories]);

  const renderProductForm = () => {
    const isMarineProduct = MarineList.some(
      (marine) => marine.title === product
    );

    if (isMarineProduct) {
      setProduct("Specific Marine Insurance");
      // return <MarineForm product={product} />;
    } else if (product === "Standard Fire") {
      setProduct("Standard Fire and Special Peril Policy - Annual");
    }
else if(product === 'Employees/Workmen Compensation Insurance'){
  setProduct('Employees/Workmen Compensation')
}
else if(product === 'Commercial General Liability'){
  setProduct('Commercial General Liability Insurance')
}
    switch (product) {
      case "Burglary":
        return (
          <BurglaryForm product={product} editData={editData} mode={mode} />
        );
      case "Commercial General Liability Insurance":
        return (
          <CommercialLiability
            product={product}
            editData={editData}
            mode={mode}
          />
        );
      case "Contractors All Risk":
        return (
          <ContractosAllRisks
            product={product}
            editData={editData}
            mode={mode}
          />
        );
      case "Contractors Plant and Machinery":
        return (
          <ContractorssPlantMachinery
            product={product}
            editData={editData}
            mode={mode}
          />
        );
      case "Directors & Offficers Insurance":
        return (
          <DirectorsOfficerInsurance
            product={product}
            editData={editData}
            mode={mode}
          />
        );
      case "Employees/Workmen Compensation":
        return (
          <WorkmenCompensation
            product={product}
            editData={editData}
            mode={mode}
          />
        );
      case "Group Health Insurance":
        return (
          <GroupHealthInsurance
            product={product}
            editData={editData}
            mode={mode}
          />
        );
      case "Group Personal Accident":
        return (
          <GroupPersonalAccident
            product={product}
            editData={editData}
            mode={mode}
          />
        );
      case "Group Term Life":
        return (
          <GroupTermLife product={product} editData={editData} mode={mode} />
        );
      case "Lift Liability":
        return (
          <LiftLiability product={product} editData={editData} mode={mode} />
        );
      case "Money":
        return <MoneyForm product={product} editData={editData} mode={mode} />;
      case "Professional Indemnity Insurance":
        return (
          <ProfessionalIndemnity
            product={product}
            editData={editData}
            mode={mode}
          />
        );
      case "Specific Marine Insurance":
        return <MarineForm product={product} editData={editData} mode={mode} />;
      case "Standard Fire and Special Peril Policy - Annual":
        return (
          <StandardFire product={product} editData={editData} mode={mode} />
        );
      default:
        return null;
    }
  };
  useEffect(() => {
    if (editData) {
      setProduct(editData?.product);
    }
  }, [editData]);
  return (
    <Flex direction="column" gap="20px" w="100%">
      <FormControl w="100%">
        <FormLabel me="3px" color="rgba(35,28,99,.7)" fontWeight="600">
          Product *
        </FormLabel>

        <Select
          bg="white"
          borderRadius="21px"
          value={product}
          onChange={(e) => setProduct(e.target.value)}
          w="100%"
        >
          {ProductsList.map((item) => (
            <option value={item}>{item}</option>
          ))}
        </Select>
      </FormControl>
      {renderProductForm()}
    </Flex>
  );
};

export default Forms;
