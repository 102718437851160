import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
  Text,
  useDisclosure,
  Modal,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  ModalBody,
  Input,
  Select,
  FormErrorMessage,
  Button,
  useToast,
  Icon,
  ModalContent,
  ModalCloseButton,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import { useForm } from "react-hook-form";
import { RiEditCircleLine } from "react-icons/ri";
import { FaRegTrashCan } from "react-icons/fa6";
import { TbUserBolt } from "react-icons/tb";
import moment from "moment";

type FormValues = {
  name: string;
  email: string;
  country: string;
  location: string;
  mobile: number;
  role: string;
};
const TeamMembers = () => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormValues>();
  const toast = useToast();
  const [selectedItem, setSelectedItem] = useState<any>();
  const [searchText, setSearchText] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
  } = useDisclosure();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rolesList, setRolesList] = useState<any>([]);
  const [countryList, setCountryList] = useState([]);
  const [teamMembersList, setTeamMembersList] = useState([]);
  function fetchRolesList() {
    useFetch
      .get(`teams/add-member`)
      .then((res) => {
        setRolesList(res?.data?.role);
        setCountryList(res?.data?.country);
      })
      .catch((error) => console.log(error));
  }
  function fetchTeamMembers() {
    setIsLoading(true);
    useFetch
      .get(`teams`)
      .then((res) => {
        setIsLoading(false);
        setTeamMembersList(res?.data?.users);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }
  const onSubmit = (data: any) => {
    setIsSubmitting(true);
    if (selectedItem?.id) {
      const body = { ...data, id: selectedItem?.id };
      useFetch
        .post(`updateuser`, body)
        .then((res) => {
          setIsSubmitting(false);
          toast({
            title: `Updated successfully`,
            description: "",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onClose();
          setSelectedItem(null);
          fetchTeamMembers();
        })
        .catch((error) => {
          setIsSubmitting(false);
          console.log(error);
          toast({
            title: `Something went wrong`,
            description: "",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        });
    } else {
      const body = { ...data };
      useFetch
        .post(`storeuser`, body)
        .then((res) => {
          setIsSubmitting(false);
          toast({
            title: `Your invite to ${data?.email} has been seen sent successfully`,
            description: "",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          onClose();
          fetchTeamMembers();
        })
        .catch((error) => {
          setIsSubmitting(false);
          console.log(error);
          toast({
            title: `Something went wrong`,
            description: "",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        });
    }
  };

  const handleDelete = () => {
    setIsSubmitting(true);
    useFetch
      .get(`user/delete/${selectedItem?.id}`)
      .then((res) => {
        setIsSubmitting(false);

        toast({
          title: `Deleted Successfully.`,
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onDeleteClose();
        fetchTeamMembers();
        setSelectedItem(null);
      })
      .catch((error) => {
        setIsSubmitting(false);
        console.log(error);
        toast({
          title: `Something went wrong`,
          description: "",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });
  };
  const filteredData = useMemo(
    () =>
      (searchText === ""
        ? [...teamMembersList]
        : teamMembersList.filter((team: any) =>
            team?.name?.toLowerCase().includes(searchText.toLowerCase())
          )
      ).sort((a: any, b: any) =>
        a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase())
      ),
    [teamMembersList, searchText]
  );
  useEffect(() => {
    fetchRolesList();
    fetchTeamMembers();
  }, []);
  useEffect(() => {
    if (selectedItem?.id) {
      setValue("name", selectedItem?.name);
      setValue("email", selectedItem?.email);
      setValue("country", selectedItem?.country || selectedItem?.location);
      const role = rolesList?.find(
        (r: any) => r.name === selectedItem?.rolename
      )?.id;
      setValue("role", role);
    }
  }, [selectedItem]);
  const handleNew = () => {
    reset();
    onOpen();
  };
  return (
    <>
      <Flex
        w="100%"
        direction="column"
        gap="40px"
        padding="20px"
        borderRadius="21px"
      >
        <Flex w="100%" justify="space-between" align="center">
          <Text fontSize="1.2rem" fontWeight="700" color="#25396f" ps="5px">
            Team Management
          </Text>
          <Button
            rightIcon={<TbUserBolt />}
            borderRadius="24px"
            bg="black"
            colorScheme="black"
            color="white"
            // padding="0px 11px"
            fontSize="14px"
            fontWeight="500"
            // w="142px"
            // h="24px"
            onClick={handleNew}
          >
            Add new member
          </Button>
        </Flex>
        {!isLoading && teamMembersList?.length ? (
          <Flex
            w="100%"
            direction="column"
            gap="40px"
            padding="20px"
            borderRadius="21px"
          >
            <Input
              type="text"
              w="100%"
              borderRadius="21px"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search...."
            />
            <TableContainer borderRadius="21px" bg="gray.50">
              <Table variant="striped" colorScheme="gray">
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Role</Th>
                    <Th>country/Location</Th>
                    <Th>Created at</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredData?.map((item: any) => (
                    <Tr key={item?.id}>
                      <Td>{item?.name}</Td>
                      <Td>{item?.rolename}</Td>
                      <Td>{item?.country || item?.location}</Td>
                      <Td>{moment(item?.created_at).format("DD MMMM YYYY")}</Td>
                      <Td>
                        <Flex align="center" gap="10px">
                          <Icon
                            as={RiEditCircleLine}
                            fontSize="18px"
                            cursor="pointer"
                            onClick={() => {
                              setSelectedItem(item);
                              onOpen();
                            }}
                          />
                          <Icon
                            as={FaRegTrashCan}
                            fontSize="18px"
                            cursor="pointer"
                            onClick={() => {
                              setSelectedItem(item);
                              onDeleteOpen();
                            }}
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
        ) : (
          <Flex w="100%" align="end" justify="center" h="30vh">
            {isLoading ? (
              <Spinner size="lg" />
            ) : (
              <Text>No Team Members found</Text>
            )}
          </Flex>
        )}
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius="21px" pb="35px">
          <ModalHeader>Add Team Member</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex direction="column" gap="12px" align="center">
                <FormControl isInvalid={Boolean(errors.name)}>
                  <FormLabel htmlFor="name">Name</FormLabel>
                  <Input
                    id="name"
                    placeholder="Enter name"
                    {...register("name", { required: "Name is required" })}
                    borderRadius="21px"
                  />
                  {errors.name && (
                    <FormErrorMessage>{errors.name.message}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={Boolean(errors.email)}>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    id="email"
                    placeholder="Enter email"
                    borderRadius="21px"
                    {...register("email", {
                      required: "Email is required",
                    })}
                  />
                  {errors.email && (
                    <FormErrorMessage>{errors.email.message}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={Boolean(errors.mobile)}>
                  <FormLabel htmlFor="mobile">Contact Number*</FormLabel>
                  <Input
                    id="mobile"
                    type="number"
                    placeholder="Enter Contact Number"
                    {...register("mobile", {
                      required: "Mobile no. is required",
                      validate: (value) =>
                        String(value).length === 10 ||
                        "Phone number must be 10 digits long",
                    })}
                    borderRadius="21px"
                  />
                  {errors.mobile && (
                    <FormErrorMessage>{errors.mobile.message}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={Boolean(errors.country)}>
                  <FormLabel htmlFor="country">Country*</FormLabel>
                  <Select
                    id="country"
                    borderRadius="21px"
                    placeholder="Select country"
                    {...register("country", {
                      required: "Please Select Country",
                    })}
                  >
                    {countryList?.map((item: any) => (
                      <option key={item?.name} value={item?.name}>
                        {item?.name}
                      </option>
                    ))}
                  </Select>

                  {errors.country && (
                    <FormErrorMessage>
                      {errors.country.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={Boolean(errors.location)}>
                  <FormLabel htmlFor="location">Location*</FormLabel>
                  <Input
                    id="location"
                    type="text"
                    placeholder="Enter Contact Person name"
                    {...register("location", {
                      required: "Location is required",
                    })}
                    borderRadius="21px"
                  />
                  {errors.location && (
                    <FormErrorMessage>
                      {errors.location.message}
                    </FormErrorMessage>
                  )}
                </FormControl>
                <FormControl isInvalid={Boolean(errors.role)}>
                  <FormLabel htmlFor="role">Role</FormLabel>
                  <Select
                    id="role"
                    borderRadius="21px"
                    placeholder="Select Role"
                    {...register("role", { required: "Role is required" })}
                  >
                    {rolesList?.map((item: any) => (
                      <option key={item?.id} value={item?.id}>
                        {item?.name}
                      </option>
                    ))}
                  </Select>
                  {errors.role && (
                    <FormErrorMessage>{errors.role.message}</FormErrorMessage>
                  )}
                </FormControl>
                <Button
                  type="submit"
                  w="fit-content"
                  borderRadius="21px"
                  colorScheme="green"
                  mt="12px"
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Submit
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
        <ModalOverlay />
        <ModalContent borderRadius="21px" pb="35px">
          <ModalCloseButton />
          <ModalBody pt="50px">
            <Flex direction="column" align="center">
              <Text fontWeight="600">
                Are you sure you want to Delete this Team member
              </Text>
              {selectedItem?.name && (
                <Text>
                  <b>Name :</b> {selectedItem?.name}
                </Text>
              )}
              {selectedItem?.email && (
                <Text>
                  <b>Email :</b> {selectedItem?.email}
                </Text>
              )}
            </Flex>
            <Flex align="center" gap="20px" w="full" justify="center" mt="20px">
              <Button borderRadius="21px" onClick={onDeleteClose}>
                Cancel
              </Button>
              <Button
                borderRadius="21px"
                colorScheme="red"
                onClick={handleDelete}
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Delete
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TeamMembers;
