import React, { useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Checkbox,
  VStack,
  HStack,
  Button,
  Divider,
  Text,
  useToast,
  Flex,
} from "@chakra-ui/react";
import {
  useForm,
  Controller,
  FieldValues,
  SubmitHandler,
} from "react-hook-form";
import { useStore } from "../../../store/store";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import "../form.css";
import { flattenObject } from "../../../partials/utils/helper";
import { FaInfoCircle } from "react-icons/fa";
interface FormValues {
  Commodity_Type: string;
  Commodity_name_other?: string;
  What_type_of_Cover_you_want: string;
  policytype: string;
  insurer: string;
  comp_location: string;
  Mode_of_Transport: string;
  Cargo_Descriptipns: string;
  Mode_of_Transport_other?: string | null;
  Packaging: string;
  All_risk_benefit: boolean;
  Additional_Benefits: boolean;
  Loss_of_Profit: boolean;
  Basic_Cover: boolean;
  Fire_and_lightning: boolean;
  War: boolean;
  SRCC: boolean;
  Theft_Pilferage_Non_Delivery: boolean;
  ICC_A: boolean;
  ICC_B: boolean;
  ICC_C: boolean;
  ITC_A: boolean;
  Waiver_of_Subrogation: boolean;
  Forms_of_Cargo: string;
  Machinery: string;
  Chemicals: string;
  Prevent_Pollution: string;
  cargo_carried: string;
  Other_Details: string;
  Sum_Insured_Specific: number;
  Sum_Insured_Annual: number;
  Turnover_Annual_Policy: number;
  Per_bottom_Limit: number;
  Limit_Per_Location: number;
  Basis_of_Valuation: string;
  Declare_the_value: number;
  Incidental_expense: number;
  start_date: string;
  end_date: string;
  policy_start_date: string;
  policy_end_date: string;
  Transit_from: string;
  Transit_to: string;
  expected_premium: number;
  sum_insured: number;
  previous_insurer: string;
  previous_policy_expiry: string;
}

const MarineForm = ({ product, editData, mode }: any) => {
  const toast = useToast();
  const { step, setStep } = useStore();
  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      Commodity_Type: "",
      What_type_of_Cover_you_want: "",
      policytype: "",
      insurer: "",
      comp_location: "",
      Mode_of_Transport: "",
      Cargo_Descriptipns: "",
      Packaging: "",
      All_risk_benefit: false,
      Basic_Cover: false,
      Fire_and_lightning: false,
      War: false,
      SRCC: false,
      Theft_Pilferage_Non_Delivery: false,
      ICC_A: false,
      ICC_B: false,
      ICC_C: false,
      ITC_A: false,
      Waiver_of_Subrogation: false,
      Forms_of_Cargo: "",
      Machinery: "",
      Chemicals: "",
      Prevent_Pollution: "",
      cargo_carried: "",
      Other_Details: "",
      Sum_Insured_Specific: 0,
      Sum_Insured_Annual: 0,
      Turnover_Annual_Policy: 0,
      Per_bottom_Limit: 0,
      Limit_Per_Location: 0,
      Basis_of_Valuation: "",
      Declare_the_value: 0,
      Incidental_expense: 0,
      start_date: new Date().toISOString().split("T")[0],
      end_date: new Date(new Date().setDate(new Date().getDate() + 10))
        .toISOString()
        .split("T")[0],
      policy_start_date: "",
      policy_end_date: "",
      Transit_from: "",
    },
  });

  const onSubmit = (data: FormValues) => {
    const compId = editData?.id
      ? editData?.company_id
      : localStorage.getItem("comp_id");
    const Body = {
      comp_id: compId.toString(),
      product,
      ...data,
    };
    useFetch
      .post(`bid`, Body)
      .then((res) => {
        toast({
          title: `Bid ${editData?.id ? "updated" : "created"} successfully`,
          description: "Bid will be in draft till confirmation",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setStep(5);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (editData) {
      const flattenedData = flattenObject(editData);
      for (let key in flattenedData) {
        if (flattenedData.hasOwnProperty(key)) {
          setValue(key as keyof FormValues, flattenedData[key]);
        }
      }
    }
  }, [editData]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={4} align="stretch">
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.Commodity_Type)}>
            <Flex align="start">
              <FormLabel htmlFor="Commodity_Type">Commodity Type*</FormLabel>

              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Cargo category type"
                />
              )}
            </Flex>
            <Controller
              name="Commodity_Type"
              control={control}
              rules={{ required: "Commodity Type is required" }}
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  placeholder="Select Commodity"
                  {...field}
                >
                  <option value="A/C, Coolers Fridge Tv Tape Recorder Electronic Appliances">
                    A/C, Coolers Fridge Tv Tape Recorder Electronic Appliances
                  </option>
                  <option value="Agriculture Machines">
                    Agriculture Machines
                  </option>
                  <option value="All Metal Wares including Utensils">
                    All Metal Wares including Utensils
                  </option>
                  <option value="All Plastic Goods and sheets">
                    All Plastic Goods and sheets
                  </option>
                  <option value="Apparel, Accessories - Fabric, Cloth, Yarn And Other Materials">
                    Apparel, Accessories - Fabric, Cloth, Yarn And Other
                    Materials
                  </option>
                  <option value="Audio Devices - Audio, Visual Sound Equipment - General Commercial Equipment And Parts And Accessories">
                    Audio Devices - Audio, Visual Sound Equipment - General
                    Commercial Equipment And Parts And Accessories
                  </option>
                  <option value="Auto Spares Auto Parts">
                    Auto Spares Auto Parts
                  </option>
                  <option value="Bagged Cargo">Bagged Cargo</option>
                  <option value="Ball Bearings">Ball Bearings</option>
                  <option value="Bolds, Nuts, Files Other Hardware Items">
                    Bolds, Nuts, Files Other Hardware Items
                  </option>
                  <option value="Butter Milk Products Medicines -Refrigerated / insulated vans">
                    Butter Milk Products Medicines -Refrigerated / insulated
                    vans
                  </option>
                  <option value="Cast Iron Goods Not Forming Part of Machinery">
                    Cast Iron Goods Not Forming Part of Machinery
                  </option>
                  <option value="Chemicals Of All Kinds And Forms Excluding Hazardous Chemicals (In Tins, Drums, Etc)">
                    Chemicals Of All Kinds And Forms Excluding Hazardous
                    Chemicals (In Tins, Drums, Etc)
                  </option>
                  <option value="Computers">Computers</option>
                  <option value="Construction Equipment">
                    Construction Equipment
                  </option>
                  <option value="Cotton, Staple, Coir, Vegetable Man Made Fibre">
                    Cotton, Staple, Coir, Vegetable Man Made Fibres
                  </option>
                  <option value="Crude Oil">Crude Oil</option>
                  <option value="Drugs, Medicines - Non Perishable Drugs And Medicines Excluding Temperature Sensitive Drugs">
                    Drugs, Medicines - Non Perishable Drugs And Medicines
                    Excluding Temperature Sensitive Drugs
                  </option>
                  <option value="Electronic Components - High Value Ic’S Including Dram, Sdram Ic’S, Sram Ic’S, Cpu Ic’S, Math Co Processors">
                    Electronic Components - High Value Ic’S Including Dram,
                    Sdram Ic’S, Sram Ic’S, Cpu Ic’S, Math Co Processors
                  </option>
                  <option value="Electronic Components / Goods, Electronic Equipment And Products - Commercial Workstations And Peripherals, Mainframes, Servers And Similar Equipment">
                    Electronic Components / Goods, Electronic Equipment And
                    Products - Commercial Workstations And Peripherals,
                    Mainframes, Servers And Similar Equipment
                  </option>
                  <option value="Empty Containers">Empty Containers</option>
                  <option value="Finished Leather Goods Footwear Rubber goods">
                    Finished Leather Goods Footwear Rubber goods
                  </option>
                  <option value="Fragile Articles - Refractories AC Sheets Electrodes">
                    Fragile Articles - Refractories AC Sheets Electrodes
                  </option>
                  <option value="Glass, bulbs TV Tubes Porcelain Insulators electrical items">
                    Glass, bulbs TV Tubes Porcelain Insulators electrical items
                  </option>
                  <option value="Handicrafts Hobby Items.(Excluding Fragile Items)">
                    Handicrafts Hobby Items.(Excluding Fragile Items)
                  </option>
                  <option value="Health And Beauty Aids – Other Health Beauty Aids">
                    Health And Beauty Aids – Other Health Beauty Aids
                  </option>
                  <option value="Liquid Cargo / Chemicals - Hazardous">
                    Liquid Cargo / Chemicals - Hazardous
                  </option>
                  <option value="Liquid Cargo / Chemicals - Milk in tankers">
                    Liquid Cargo / Chemicals - Milk in tankers
                  </option>
                  <option value="Liquid Cargo / Chemicals - Non-Hazardous">
                    Liquid Cargo / Chemicals - Non-Hazardous
                  </option>
                  <option value="Lube Oil, Superior Kerosene, HSD Diesel Oil Etc">
                    Lube Oil, Superior Kerosene, HSD Diesel Oil Etc
                  </option>
                  <option value="Machinery Machine Tools Spares and Parts">
                    Machinery Machine Tools Spares and Parts
                  </option>
                  <option value="Metal Bars Tubes Sheets Ingots Plates Structural Materials">
                    Metal Bars Tubes Sheets Ingots Plates Structural Materials
                  </option>
                  <option value="Papers Stationery Goods Books Magazines">
                    Papers Stationery Goods Books Magazines
                  </option>
                  <option value="Paraffin In Liquid Form">
                    Paraffin In Liquid Form
                  </option>
                  <option value="Paraxylene">Paraxylene</option>
                  <option value="Personal Effects Household Goods">
                    Personal Effects Household Goods
                  </option>
                  <option value="Pharmaceutical, toilet Provisions Confectionery Other Packing">
                    Pharmaceutical, toilet Provisions Confectionery Other
                    Packing
                  </option>
                  <option value="Piece goods, garments, gunnies coir products">
                    Piece goods, garments, gunnies coir products
                  </option>
                  <option value="Readymade Garments, Textiles, Fabrics, Hand Knitted Woven, Synthetic Fibre, Woven Fabric (Except Cotton), Cotton Fabric">
                    Readymade Garments, Textiles, Fabrics, Hand Knitted Woven,
                    Synthetic Fibre, Woven Fabric (Except Cotton), Cotton Fabric
                  </option>
                  <option value="Safety And Security Devices">
                    Safety And Security Devices
                  </option>
                  <option value="Sanitaryware Earthern ware Glassware Crockery Chinaware">
                    Sanitaryware Earthern ware Glassware Crockery Chinaware
                  </option>
                  <option value="Silks, Yarns, Made Ups, Raw Silk, Cotton Yarn Including Bales">
                    Silks, Yarns, Made Ups, Raw Silk, Cotton Yarn Including
                    Bales
                  </option>
                  <option value="Sports Goods.">Sports Goods.</option>
                  <option value="Steel Plates, Hot Rolled Sheets, Cold Rolled Sheets, Galvanised Iron / Steel Sheets">
                    Steel Plates, Hot Rolled Sheets, Cold Rolled Sheets,
                    Galvanised Iron / Steel Sheets
                  </option>
                  <option value="Toys (Excluding Electronic Items)">
                    Toys (Excluding Electronic Items)
                  </option>
                  <option value="Wires, Cables etc">Wires, Cables etc</option>
                  <option value="Wooden / Metal Furniture">
                    Wooden / Metal Furniture
                  </option>
                  <option value="Others">Others</option>
                </Select>
              )}
            />
            {errors?.Commodity_Type && (
              <Text color="red.500" fontSize="sm">
                {errors.Commodity_Type.message}
              </Text>
            )}
          </FormControl>
          {watch("Commodity_Type") === "Others" && (
            <FormControl isInvalid={Boolean(errors?.Commodity_name_other)}>
              <FormLabel htmlFor="Commodity_name_other">
                Commodity Name*
              </FormLabel>
              <Input
                type="text"
                {...register("Commodity_name_other", {
                  required: "Commodity type is required",
                })}
                bg="white"
                borderRadius="16px"
              />
              {errors?.Commodity_name_other && (
                <Text color="red.500" fontSize="sm">
                  {errors.Commodity_name_other.message}
                </Text>
              )}
            </FormControl>
          )}
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.What_type_of_Cover_you_want)}>
            <FormLabel htmlFor="What_type_of_Cover_you_want">
              What type of Cover you want? *
            </FormLabel>
            <Controller
              name="What_type_of_Cover_you_want"
              control={control}
              rules={{ required: "Cover you want is required" }}
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  placeholder="What type of Cover you want"
                  {...field}
                >
                  <option value="Inland Transit">Inland Transit</option>
                  <option value="Marine Hull">Marine Hull</option>
                  <option value="Specific Transit">Specific Transit</option>
                  <option value="Export and Import">Export and Import</option>
                </Select>
              )}
            />
            {errors?.What_type_of_Cover_you_want && (
              <Text color="red.500" fontSize="sm">
                {errors.What_type_of_Cover_you_want.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.policytype)}>
            <FormLabel htmlFor="policytype">Policy Type*</FormLabel>
            <Controller
              name="policytype"
              control={control}
              rules={{ required: "Policy Type is required" }}
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  placeholder="Select policy type"
                  {...field}
                >
                  <option value="New">New</option>
                  <option value="Renewal">Renewal</option>
                </Select>
              )}
            />
            {errors?.policytype && (
              <Text color="red.500" fontSize="sm">
                {errors.policytype.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.insurer)}>
            <FormLabel htmlFor="insurer">Bidder Preference*</FormLabel>
            <Controller
              name="insurer"
              control={control}
              rules={{ required: "Bidder Preference is required" }}
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  placeholder="Preference"
                  {...field}
                >
                  <option value="Both">Both</option>
                  <option value="Brokers">Brokers</option>
                  <option value="INS_COMP">Insurance Company</option>
                </Select>
              )}
            />
            {errors?.insurer && (
              <Text color="red.500" fontSize="sm">
                {errors.insurer.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.comp_location)}>
            <FormLabel htmlFor="comp_location">
              Where is your company located?*
            </FormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              id="comp_location"
              {...register("comp_location", {
                required: "Company location is required",
              })}
            />
            {errors?.comp_location && (
              <Text color="red.500" fontSize="sm">
                {errors.comp_location.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.Mode_of_Transport)}>
            <FormLabel htmlFor="Mode_of_Transport">
              Mode of Transport*
            </FormLabel>
            <Controller
              name="Mode_of_Transport"
              control={control}
              rules={{ required: "Modes of transport is required" }}
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  placeholder="Mode of Transport"
                  {...field}
                >
                  <option value="Rail">Rail</option>
                  <option value="Road">Road</option>
                  <option value="Sea">Sea</option>
                  <option value="Air">Air</option>
                  <option value="Others">Others</option>
                </Select>
              )}
            />
            {errors?.Mode_of_Transport && (
              <Text color="red.500" fontSize="sm">
                {errors.Mode_of_Transport.message}
              </Text>
            )}
          </FormControl>

          {watch("Mode_of_Transport") === "Others" && (
            <FormControl isInvalid={Boolean(errors?.Mode_of_Transport_other)}>
              <FormLabel htmlFor="Mode_of_Transport_other">
                Value for Others*
              </FormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                id="Mode_of_Transport_other"
                {...register("Mode_of_Transport_other", {
                  required: "Please mention Others value",
                })}
              />
              {errors?.Mode_of_Transport_other && (
                <Text color="red.500" fontSize="sm">
                  {errors.Mode_of_Transport_other.message}
                </Text>
              )}
            </FormControl>
          )}
          <FormControl isInvalid={Boolean(errors?.Cargo_Descriptipns)}>
            <FormLabel htmlFor="Cargo_Descriptipns">
              Cargo Descriptions*
            </FormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              id="Cargo_Descriptipns"
              {...register("Cargo_Descriptipns", {
                required: "Cargo description is required",
              })}
            />
            {errors?.Cargo_Descriptipns && (
              <Text color="red.500" fontSize="sm">
                {errors.Cargo_Descriptipns.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.Packaging)} w="50%">
            <FormLabel htmlFor="Packaging">Packaging*</FormLabel>
            <Controller
              name="Packaging"
              control={control}
              rules={{ required: "Packaging is required" }}
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  placeholder="Packaging"
                  {...field}
                >
                  <option value="STD & CUSTOMARY">STD & CUSTOMARY</option>
                </Select>
              )}
            />
            {errors?.Packaging && (
              <Text color="red.500" fontSize="sm">
                {errors.Packaging.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <Divider borderColor="gray.300" />

        <Text
          gridColumn="span 2"
          color="darkorchid"
          fontWeight="600"
          fontSize="16px"
        >
          Opted Cover
        </Text>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.All_risk_benefit)}>
            <Controller
              name="All_risk_benefit"
              control={control}
              render={({ field }) => (
                <Flex align="start">
                  <Checkbox
                    isChecked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  >
                    All Risk Benefit
                  </Checkbox>

                  {mode === "Guided" && (
                    <FaInfoCircle
                      fontSize="11px"
                      color="gray.500"
                      title="Insure against all hazards to the cargo and the vessel"
                    />
                  )}
                </Flex>
              )}
            />
            {errors?.All_risk_benefit && (
              <Text color="red.500" fontSize="sm">
                {errors.All_risk_benefit.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.Basic_Cover)}>
            <Controller
              name="Basic_Cover"
              control={control}
              render={({ field }) => (
                <Flex align="start">
                  <Checkbox
                    isChecked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  >
                    Basic Cover
                  </Checkbox>

                  {mode === "Guided" && (
                    <FaInfoCircle
                      fontSize="11px"
                      color="gray.500"
                      title="Address the fundamental risks when cargo is in transit."
                    />
                  )}
                </Flex>
              )}
            />
            {errors?.Basic_Cover && (
              <Text color="red.500" fontSize="sm">
                {errors.Basic_Cover.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.Fire_and_lightning)}>
            <Controller
              name="Fire_and_lightning"
              control={control}
              render={({ field }) => (
                <Flex align="start">
                  <Checkbox
                    isChecked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                  >
                    Fire and lighting
                  </Checkbox>

                  {mode === "Guided" && (
                    <FaInfoCircle
                      fontSize="11px"
                      color="gray.500"
                      title="Protect the cargo from lightning and fire damage."
                    />
                  )}
                </Flex>
              )}
            />
            {errors?.Fire_and_lightning && (
              <Text color="red.500" fontSize="sm">
                {errors.Fire_and_lightning.message}
              </Text>
            )}
          </FormControl>

          {/* <FormControl isInvalid={Boolean(errors?.Loss_of_Profit)}>
            <Controller
              name="Loss_of_Profit"
              control={control}
              render={({ field }) => (
                <Checkbox isChecked={field.value} onChange={(e) => field.onChange(e.target.checked)} >Loss of Profit</Checkbox>
              )}
            />
            {errors?.Loss_of_Profit && (
              <Text color="red.500" fontSize="sm">
                {errors.Loss_of_Profit.message}
              </Text>
            )}
          </FormControl> */}
        </HStack>

        <Divider borderColor="gray.300" />
        {/* Optional Coverages Section */}
        <Text
          gridColumn="span 2"
          color="darkorchid"
          fontWeight="600"
          fontSize="16px"
        >
          Optional Coverages
        </Text>
        <HStack gap={4}>
          <FormControl
            isInvalid={Boolean(errors?.War)}
            display="flex"
            justifyContent="start"
          >
            <Controller
              name="War"
              control={control}
              render={({ field }) => (
                <Checkbox {...field} value="War">
                  War
                </Checkbox>
              )}
            />
            {errors?.War && (
              <Text color="red.500" fontSize="sm">
                {errors.War.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.SRCC)}>
            <Controller
              name="SRCC"
              control={control}
              render={({ field }) => (
                <Flex align="start">
                  <Checkbox {...field} value="SRCC">
                    SRCC
                  </Checkbox>

                  {mode === "Guided" && (
                    <FaInfoCircle
                      fontSize="11px"
                      color="gray.500"
                      title="Risks of Strikes, Riots, and Civil Unrest"
                    />
                  )}
                </Flex>
              )}
            />
            {errors?.SRCC && (
              <Text color="red.500" fontSize="sm">
                {errors.SRCC.message}
              </Text>
            )}
          </FormControl>

          <FormControl
            isInvalid={Boolean(errors?.Theft_Pilferage_Non_Delivery)}
          >
            <Controller
              name="Theft_Pilferage_Non_Delivery"
              control={control}
              render={({ field }) => (
                <Checkbox {...field} value="Theft, Pilferage & Non Delivery">
                  Theft, Pilferage & Non Delivery
                </Checkbox>
              )}
            />
            {errors?.Theft_Pilferage_Non_Delivery && (
              <Text color="red.500" fontSize="sm">
                {errors.Theft_Pilferage_Non_Delivery.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.ICC_A)}>
            <Controller
              name="ICC_A"
              control={control}
              render={({ field }) => (
                <Flex align="start">
                  <Checkbox {...field} value="ICC (A)">
                    ICC A
                  </Checkbox>

                  {mode === "Guided" && (
                    <FaInfoCircle
                      fontSize="11px"
                      color="gray.500"
                      title="Comprehensive protection for the insured cargo against physical damage or loss due to any external cause is provided by Institute Cargo Clause A, which covers all named and unnamed perils."
                    />
                  )}
                </Flex>
              )}
            />
            {errors?.ICC_A && (
              <Text color="red.500" fontSize="sm">
                {errors.ICC_A.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.ICC_B)}>
            <Controller
              name="ICC_B"
              control={control}
              render={({ field }) => (
                <Flex align="start">
                  <Checkbox {...field} value="ICC (B)">
                    ICC B
                  </Checkbox>

                  {mode === "Guided" && (
                    <FaInfoCircle
                      fontSize="11px"
                      color="gray.500"
                      title="Institute Cargo Clause B provides protection against common hazards that cargo may encounter when being transported by road, sea, rail, or air. It does this by encompassing a specific list of risks."
                    />
                  )}
                </Flex>
              )}
            />
            {errors?.ICC_B && (
              <Text color="red.500" fontSize="sm">
                {errors.ICC_B.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.ICC_C)} w="100%">
            <Controller
              name="ICC_C"
              control={control}
              render={({ field }) => (
                <Flex align="start">
                  <Checkbox {...field} value="ICC (C)">
                    ICC C
                  </Checkbox>

                  {mode === "Guided" && (
                    <FaInfoCircle
                      fontSize="11px"
                      color="gray.500"
                      title="Basic coverage against particular risks like fire, explosion, collision, stranding, sinking, grounding, and other restricted perils is provided by Institute Cargo Clause C."
                    />
                  )}
                </Flex>
              )}
            />
            {errors?.ICC_C && (
              <Text color="red.500" fontSize="sm">
                {errors.ICC_C.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.ITC_A)} flex={1}>
            <Controller
              name="ITC_A"
              control={control}
              render={({ field }) => (
                <Flex align="start">
                  <Checkbox {...field} value="ITC(A)">
                    ITC A
                  </Checkbox>

                  {mode === "Guided" && (
                    <FaInfoCircle
                      fontSize="11px"
                      color="gray.500"
                      title="Clause A on Inland Transit provides coverage for all risks of goods damage."
                    />
                  )}
                </Flex>
              )}
            />
            {errors?.ITC_A && (
              <Text color="red.500" fontSize="sm">
                {errors.ITC_A.message}
              </Text>
            )}
          </FormControl>

          <FormControl
            isInvalid={Boolean(errors?.Waiver_of_Subrogation)}
            flex={1}
          >
            <Controller
              name="Waiver_of_Subrogation"
              control={control}
              render={({ field }) => (
                <Flex align="start">
                  <Checkbox {...field} value="Waiver of Subrogation">
                    Waiver of Subrogation
                  </Checkbox>

                  {mode === "Guided" && (
                    <FaInfoCircle
                      fontSize="11px"
                      color="gray.500"
                      title="A waiver of subrogation is a clause that allows the insured party to forfeit their right to sue another party for damages."
                    />
                  )}
                </Flex>
              )}
            />
            {errors?.Waiver_of_Subrogation && (
              <Text color="red.500" fontSize="sm">
                {errors.Waiver_of_Subrogation.message}
              </Text>
            )}
          </FormControl>
          <HStack flex={1} />
        </HStack>
        <Divider borderColor="gray.300" />
        <Text
          gridColumn="span 2"
          color="darkorchid"
          fontWeight="600"
          fontSize="16px"
        >
          Cargo details
        </Text>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.Forms_of_Cargo)}>
            <FormLabel htmlFor="Forms_of_Cargo">Forms of Cargo*</FormLabel>
            <Controller
              name="Forms_of_Cargo"
              control={control}
              rules={{ required: "Cargo form is required" }}
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  id="Forms_of_Cargo"
                  placeholder="Forms of Cargo"
                  {...field}
                >
                  <option value="Solid">Solid</option>
                  <option value="Liquid">Liquid</option>
                  <option value="Gaseous">Gaseous</option>
                </Select>
              )}
            />
            {errors?.Forms_of_Cargo && (
              <Text color="red.500" fontSize="sm">
                {errors.Forms_of_Cargo.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.Machinery)}>
            <FormLabel htmlFor="Machinery">Machinery</FormLabel>
            <Controller
              name="Machinery"
              control={control}
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  id="Machinery"
                  placeholder="Machinery"
                  {...field}
                >
                  <option value="Single unit">Single unit</option>
                  <option value="Dismantled">Dismantled</option>
                </Select>
              )}
            />
            {errors?.Machinery && (
              <Text color="red.500" fontSize="sm">
                {errors.Machinery.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.Chemicals)}>
            <Flex align="start">
              <FormLabel htmlFor="Chemicals">Chemical Temperature</FormLabel>

              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Temperature of In case of liquid cargo"
                />
              )}
            </Flex>
            <Controller
              name="Chemicals"
              control={control}
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  id="Chemicals"
                  placeholder="Chemicals"
                  {...field}
                >
                  <option value="Above 60 Degree">Above 60°C and 140°F</option>
                  <option value="Below 60 Degree">Below 60°C and 140°F</option>
                </Select>
              )}
            />
            {errors?.Chemicals && (
              <Text color="red.500" fontSize="sm">
                {errors.Chemicals.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.Prevent_Pollution)}>
            <FormLabel htmlFor="Prevent_Pollution">
              Step to Prevent Pollution from Transportation
            </FormLabel>
            <Controller
              name="Prevent_Pollution"
              control={control}
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  id="Prevent_Pollution"
                  placeholder="Prevent Pollution"
                  {...field}
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Select>
              )}
            />
            {errors?.Prevent_Pollution && (
              <Text color="red.500" fontSize="sm">
                {errors.Prevent_Pollution.message}
              </Text>
            )}
          </FormControl>
        </HStack>

        <Divider borderColor="gray.300" />
        <Text
          gridColumn="span 2"
          color="darkorchid"
          fontWeight="600"
          fontSize="16px"
        >
          Indemnity
        </Text>
        <HStack gap={4}>
          {/* Repeat for other input fields with validation */}
          <FormControl isInvalid={Boolean(errors?.Sum_Insured_Specific)}>
            <FormLabel htmlFor="Sum_Insured_Specific">
              Sum Insured (Specific)*
            </FormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              id="Sum_Insured_Specific"
              type="number"
              {...register("Sum_Insured_Specific", {
                required: "Sum insured ( specific ) is required",
                valueAsNumber: true,
              })}
            />
            {errors?.Sum_Insured_Specific && (
              <Text color="red.500" fontSize="sm">
                {errors.Sum_Insured_Specific.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.Sum_Insured_Annual)}>
            <FormLabel htmlFor="Sum_Insured_Annual">
              Sum Insured Annual
            </FormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              id="Sum_Insured_Annual"
              type="number"
              {...register("Sum_Insured_Annual", {
                required: "Sum insured (Annual) is required",
                valueAsNumber: true,
              })}
            />
            {errors?.Sum_Insured_Annual && (
              <Text color="red.500" fontSize="sm">
                {errors.Sum_Insured_Annual.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.Turnover_Annual_Policy)}>
            <FormLabel htmlFor="Turnover_Annual_Policy">
              Turnover (Annual Policy)*
            </FormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              id="Turnover_Annual_Policy"
              type="number"
              {...register("Turnover_Annual_Policy", {
                required: "Turnover (Annual Policy) is required",
                valueAsNumber: true,
              })}
            />
            {errors?.Turnover_Annual_Policy && (
              <Text color="red.500" fontSize="sm">
                {errors.Turnover_Annual_Policy.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.Per_bottom_Limit)}>
            <Flex align="start">
              <FormLabel htmlFor="Per_bottom_Limit">
                Per Bottom Limit*
              </FormLabel>

              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="The value of goods at one place at a time consider as per bottom limit."
                />
              )}
            </Flex>
            <Input
              bg="white"
              borderRadius="21px"
              id="Per_bottom_Limit"
              type="number"
              {...register("Per_bottom_Limit", {
                required: "Per Bottom Limit is required",
                valueAsNumber: true,
              })}
            />
            {errors?.Per_bottom_Limit && (
              <Text color="red.500" fontSize="sm">
                {errors.Per_bottom_Limit.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.Limit_Per_Location)}>
            <Flex align="start">
              <FormLabel htmlFor="Limit_Per_Location">
                Limit Per Location*
              </FormLabel>

              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="The maximum value of goods stored in one location serves as the primary liability limit when shipping by sea."
                />
              )}
            </Flex>
            <Input
              bg="white"
              borderRadius="21px"
              id="Limit_Per_Location"
              type="number"
              {...register("Limit_Per_Location", {
                required: "Limit per location is required",
                valueAsNumber: true,
              })}
            />
            {errors?.Limit_Per_Location && (
              <Text color="red.500" fontSize="sm">
                {errors.Limit_Per_Location.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.Basis_of_Valuation)}>
            <FormLabel htmlFor="Basis_of_Valuation">
              Basis of Valuation*
            </FormLabel>
            <Controller
              name="Basis_of_Valuation"
              control={control}
              rules={{ required: "Basis of Valuation is required" }}
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  placeholder="Basis of Valuation"
                  {...field}
                >
                  <option value="Market Value">Market Value</option>
                  <option value="Replacement Value">Replacement Value</option>
                  <option value="Agreed Value">Agreed Value</option>
                </Select>
              )}
            />
            {errors?.Basis_of_Valuation && (
              <Text color="red.500" fontSize="sm">
                {errors.Basis_of_Valuation.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.Declare_the_value)}>
            <FormLabel htmlFor="Declare_the_value">
              Declare the Value*
            </FormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              id="Declare_the_value"
              type="number"
              {...register("Declare_the_value", {
                required: "Declare the value is required",
                valueAsNumber: true,
              })}
            />
            {errors?.Declare_the_value && (
              <Text color="red.500" fontSize="sm">
                {errors.Declare_the_value.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.Incidental_expense)}>
            <Flex align="start">
              <FormLabel htmlFor="Incidental_expense">
                Incidental Expense*
              </FormLabel>

              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Extra expenses that might emerge beyond the policy's basic coverage essentially happened during the shipping procedure."
                />
              )}
            </Flex>
            <Input
              bg="white"
              borderRadius="21px"
              id="Incidental_expense"
              type="number"
              {...register("Incidental_expense", {
                required: "Incidental Expense is required",
                valueAsNumber: true,
              })}
            />
            {errors?.Incidental_expense && (
              <Text color="red.500" fontSize="sm">
                {errors.Incidental_expense.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <Divider borderColor="gray.300" />
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.start_date)}>
            <FormLabel htmlFor="start_date">Bid's Start Date*</FormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              id="start_date"
              type="date"
              {...register("start_date", {
                required: "Bid's start date is required",
              })}
            />
            {errors?.start_date && (
              <Text color="red.500" fontSize="sm">
                {errors.start_date.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.end_date)}>
            <FormLabel htmlFor="end_date">Bid's End Date*</FormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              id="end_date"
              type="date"
              {...register("end_date", {
                required: "Bid's end date is required",
              })}
            />
            {errors?.end_date && (
              <Text color="red.500" fontSize="sm">
                {errors.end_date.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.policy_start_date)}>
            <FormLabel htmlFor="policy_start_date">
              Shipment Start Date*
            </FormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              id="policy_start_date"
              type="date"
              {...register("policy_start_date", {
                required: "Shipment start date is required",
              })}
            />
            {errors?.policy_start_date && (
              <Text color="red.500" fontSize="sm">
                {errors.policy_start_date.message}
              </Text>
            )}
          </FormControl>

          <FormControl isInvalid={Boolean(errors?.policy_end_date)}>
            <FormLabel htmlFor="policy_end_date">Shipment End Date*</FormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              id="policy_end_date"
              type="date"
              {...register("policy_end_date", {
                required: "Shipment end date is required",
              })}
            />
            {errors?.policy_end_date && (
              <Text color="red.500" fontSize="sm">
                {errors.policy_end_date.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.Transit_from)}>
            <FormLabel htmlFor="Transit_from">Transit From*</FormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              id="Transit_from"
              type="text"
              {...register("Transit_from", {
                required: "Transit from is required",
              })}
            />
            {errors?.Transit_from && (
              <Text color="red.500" fontSize="sm">
                {errors.Transit_from.message}
              </Text>
            )}
          </FormControl>
          <FormControl isInvalid={Boolean(errors?.Transit_to)}>
            <FormLabel htmlFor="Transit_to">Transit To*</FormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              id="Transit_to"
              type="text"
              {...register("Transit_to", {
                required: "Transit to is required",
              })}
            />
            {errors?.Transit_to && (
              <Text color="red.500" fontSize="sm">
                {errors.Transit_to.message}
              </Text>
            )}
          </FormControl>

          {/* <FormControl isInvalid={Boolean(errors?.Other_Details)}>
            <FormLabel htmlFor="Other_Details">Other Details</FormLabel>
            <Input bg="white"
              borderRadius="21px" id="Other_Details" {...register("Other_Details")} />
            {errors?.Other_Details && (
              <Text color="red.500" fontSize="sm">
                {errors.Other_Details.message}
              </Text>
            )}
          </FormControl> */}
        </HStack>
        <HStack gap={4}>
          <FormControl isInvalid={Boolean(errors?.expected_premium)}>
            <Flex align="start">
              <FormLabel> Anticipated premium*</FormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Anticipated premium for acquiring this policy."
                />
              )}
            </Flex>
            <Input
              bg="white"
              borderRadius="21px"
              id="expected_premium"
              type="number"
              {...register("expected_premium", {
                required: "Anticipated premium is required",
              })}
            />
            {errors?.expected_premium && (
              <Text color="red.500" fontSize="sm">
                {errors.expected_premium.message}
              </Text>
            )}
          </FormControl>
          <FormControl isInvalid={Boolean(errors?.sum_insured)}>
            <FormLabel htmlFor="sum_insured">Total coverages*</FormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              id="sum_insured"
              type="number"
              {...register("sum_insured", {
                required: "Total coverages is required",
              })}
            />
            {errors?.sum_insured && (
              <Text color="red.500" fontSize="sm">
                {errors.sum_insured.message}
              </Text>
            )}
          </FormControl>

          {/* <FormControl isInvalid={Boolean(errors?.Other_Details)}>
          <FormLabel htmlFor="Other_Details">Other Details</FormLabel>
          <Input bg="white"
              borderRadius="21px"
            id="Other_Details"
            {...register('Other_Details')}
          />
          {errors?.Other_Details && (
            <Text color="red.500" fontSize="sm">
              {errors.Other_Details.message}
            </Text>
          )}
        </FormControl> */}
        </HStack>
        {watch("policytype") === "Renewal" && (
          <HStack gap="4">
            <FormControl
              id="previous_insurer"
              isInvalid={Boolean(errors?.previous_insurer)}
            >
              <FormLabel>Previous Insurer*</FormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                {...register("previous_insurer", {
                  required: "Previous insurer is required",
                })}
              />
              {errors?.previous_insurer && (
                <Text color="red.500" fontSize="sm">
                  {errors?.previous_insurer?.message}
                </Text>
              )}
            </FormControl>

            <FormControl
              id="previous_policy_expiry"
              isInvalid={Boolean(errors?.previous_policy_expiry)}
            >
              <FormLabel>Previous policy expiry*</FormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="date"
                {...register("previous_policy_expiry", {
                  required: "Previous policy expiry is required",
                })}
              />
              {errors?.previous_policy_expiry && (
                <Text color="red.500" fontSize="sm">
                  {errors?.previous_policy_expiry?.message}
                </Text>
              )}
            </FormControl>
          </HStack>
        )}
        <HStack w="100%" justify="space-between" align="center" mt="20px">
          <Button
            type="button"
            borderRadius="21px"
            onClick={() => setStep(step - 1)}
          >
            Previous
          </Button>
          <Button type="submit" borderRadius="21px" colorScheme="green">
            Next
          </Button>
        </HStack>
      </VStack>
    </form>
  );
};

export default MarineForm;
