import { ChangeEvent, useEffect, useRef, useState } from "react";
import { IoCloudDownloadOutline } from "react-icons/io5";
import {
  Button,
  Flex,
  Icon,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Text,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import DocumentThumbnail from "../../components/Thumbnails/DocumentThumbnail";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import { useLocation, useNavigate } from "react-router-dom";
import FileThumnails from "../../components/Thumbnails/FileThumnails";
import { theme } from "../../partials/utils/Contants";

const DefaultVaultListing = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const fetchData = useFetch;
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState<any>();
  const [name, setName] = useState<any>("");
  const [file, setFile] = useState<any>();
  const [defaultList, setDefaultList] = useState<any[]>([]);

  useEffect(() => {
    const fetchListing = async () => {
      setIsLoading(true);
      const searchParams = new URLSearchParams(search);
      const idValue = searchParams.get("id");
      setId(idValue);
      const nameURL = searchParams.get("name");
      setName(nameURL);
      if (idValue) {
        fetchListingData(idValue);
      }
    };

    fetchListing();
  }, [search]);
  function fetchListingData(id: string) {
    fetchData(`default-listing/${id}`)
      .then((response) => {
        setIsLoading(false);
        setDefaultList(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }
  const handleClick = (id: string, name: string) => {
    if (
      name?.toLocaleLowerCase() === "mandate" ||
      name?.toLocaleLowerCase() === "rfq"
    ) {
      fetchData
        .get(`download-default-zip/${id}`, {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "default.zip");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    } else {
      navigate(
        `/document-vault/default-files?id=${id}&name=${name.replace("_", " ")}`
      );
    }
  };
  const handleSingleFolderZip = async (id: number) => {
    try {
      const response = await useFetch.get(`download-default-zip/${id}`, {
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));

      const a = document.createElement("a");
      a.href = url;
      a.download = `request#${id}.zip`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      toast({
        title: `Downloaded successfully`,
        description: "",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the zip file:", error);
      toast({
        title: `Error downloading the zip file`,
        description: "",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleDownloadCategoryZip = async (
    id: number,
    category: string,
    type: string
  ) => {
    try {
      const response = await useFetch.get(
        `default-documets/${category}/${id}`,
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const a = document.createElement("a");
      a.href = url;
      a.download = `request#${id}.${type}`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      toast({
        title: `Downloaded successfully`,
        description: "",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the zip file:", error);
      toast({
        title: `Error downloading the zip file`,
        description: "",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleDelete = (id: number, file: string) => {
    const body = {
      id,
      file,
    };
    useFetch
      .post(`default-documets/delete`, body)
      .then((res) => {
        toast({
          title: `Deleted successfully`,
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.log("error", error);
        toast({
          title: `Error Deleting the document`,
          description: "",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };
  return (
    <Flex
      direction="column"
      w="100%"
      gap="30px"
      padding="35px"
      overflowY="auto"
    >
      <Flex w="100%" justify="space-between" align="center">
        <Text fontSize="30px" fontWeight="500" textTransform="capitalize">
          {name || ""}
        </Text>
        <Flex align="center" gap="20px">
          <Button
            borderRadius="21px"
            bg="black"
            size="sm"
            color="white"
            colorScheme="black"
            _hover={{ filter: "opacity(0.75)" }}
            leftIcon={<IoCloudDownloadOutline />}
            //   onClick={onOpen}
            //   ref={btnRef}
            fontSize="0.9rem"
            fontWeight="500"
            onClick={() => navigate(`/claim-form/${name}`)}
          >
            Claim Form
          </Button>
          <Button
            borderRadius="21px"
            bg="black"
            size="sm"
            color="white"
            colorScheme="black"
            _hover={{ filter: "opacity(0.75)" }}
            leftIcon={<IoCloudDownloadOutline />}
            fontSize="0.9rem"
            fontWeight="500"
            onClick={() => handleSingleFolderZip(id)}
          >
            Zip all
          </Button>
        </Flex>
      </Flex>
      <Flex position="relative" w="100%">
        {/* <Icon as={CiSearch} fontSize="18px" position="absolute" /> */}
        <Input
          borderRadius="16px"
          type="text"
          w="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          placeholder="🔍 Search"
          fontSize="12px"
          fontWeight="500"
        />
      </Flex>
      <Flex w="100%" justify="space-between">
        <Text fontSize="24px" fontWeight="500">
          Files
        </Text>
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<IoIosArrowDown />}
            borderRadius="16px"
            bg="white"
            border="1px solid #dadada"
            _active={{
              bg: "black",
              color: "white",
              border: "1px solid black",
            }}
          >
            Filter
          </MenuButton>
          <MenuList
            bg="black"
            color="white"
            padding="25px 5px 15px 15px"
            gap="20px"
            borderRadius="16px"
          >
            <Text p="0px 10px 10px 15px">Filter by:</Text>
            {["By Entity", "By Policy", "By Bid"].map((item) => (
              <MenuItem
                bg="black"
                color="rgba(246, 246, 246, 0.50)"
                fontSize="14px"
                fontWeight="400"
              >
                <Flex align="center" justify="space-between" gap="8px" w="100%">
                  <Text fontSize="14px" fontWeight="500">
                    {item}
                  </Text>
                  <Icon as={IoIosArrowForward} fontSize="12px" />
                </Flex>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Flex>
      {!isLoading && defaultList?.length ? (
        <Flex direction="column" w="100%" gap="20px">
          {defaultList?.map((d: any) => (
            <Flex direction="column" w="100%" gap="20px">
              <Flex align="center" w="100%" justify="space-between">
                <Text fontSize="20px" fontWeight="600" color="blue.800">
                  Request #{d?.id}
                </Text>
                <Button
                  borderRadius="21px"
                  bg="black"
                  // size="sm"
                  color="white"
                  colorScheme="black"
                  _hover={{ filter: "opacity(0.75)" }}
                  leftIcon={<IoCloudDownloadOutline />}
                  fontSize="0.9rem"
                  fontWeight="500"
                  onClick={() => handleSingleFolderZip(d?.id)}
                >
                  Zip
                </Button>
              </Flex>
              <SimpleGrid minChildWidth="245px" gap="24px" w="100%">
                {d?.data?.map((item: any) => (
                  <DocumentThumbnail
                    title={item?.name}
                    fileCount={item?.fileCount}
                    data={item}
                    type="default-listing"
                    handleClick={handleClick}
                    handleDownload={handleDownloadCategoryZip}
                    handleDelete={handleDelete}
                    id={d?.id}
                  />
                ))}
                {/* <Flex
            w="244px"
            height="180px"
            borderRadius="12px"
            border="1px dotted #6E6E6E"
            padding="16px"
            gap="20px"
            direction="column"
            align="center"
            color={theme.color.WHITE}
            bg="black"
            onClick={handleUploadClick}
            cursor="pointer"
          >
            <input
              ref={fileInputRef}
              style={{ display: "none" }}
              type="file"
              onChange={handleFileChange}
              accept="*"
            />
            <Text fontWeight="500" fontSize="16px">
              Upload Files
            </Text>
            <Icon as={IoCloudDownloadOutline} fontSize="28px" />
            <Text fontWeight="400" fontSize="10px" textAlign="center">
              Click to browse or drag and drop your files here to upload. Max
              file size: 25 MB.{" "}
            </Text>
          </Flex> */}
              </SimpleGrid>
            </Flex>
          ))}
        </Flex>
      ) : (
        <Flex
          direction="column"
          w="100%"
          align="center"
          gap="12px"
          justify="center"
          minH="200px"
        >
          {isLoading ? <Spinner /> : <Text>No Folders Aavailable</Text>}
        </Flex>
      )}
    </Flex>
  );
};

export default DefaultVaultListing;
