import { useNavigate } from "react-router-dom";
import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

import { RxCross1 } from "react-icons/rx";
import { FiEye, FiArchive } from "react-icons/fi";
import { RiEditCircleLine } from "react-icons/ri";
import { FaCircleCheck, FaRegTrashCan } from "react-icons/fa6";
import { BsFillInfoCircleFill } from "react-icons/bs";

import { theme } from "../../partials/utils/Contants";
import { useRef, useState } from "react";
import useClickOutside from "../../hooks/useClickOutside";

import { createPortal } from "react-dom";
import moment from "moment";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import "./BidCard.css";
type BidCardProps = {
  data: any;
  type: "draft" | "live" | "engaged" | "completed" | "inactive";
  fetchData?: any;
  fetchAfterComplete?: any;
  selectedBid?: any;
  setSelectedBid?: any;
  showActions?: boolean
};
const ActionDropdown = [
  { icon: FiEye, label: "Preview", event: "test" },
  { icon: RiEditCircleLine, label: "Edit", event: "test" },
  { icon: FiArchive, label: "Archive", event: "test" },
  { icon: FiEye, label: "Proposals", event: "test" },
  { icon: FaCircleCheck, label: "Mark as Complete", event: "test" },
];
const filterActions = (type: BidCardProps["type"]) => {
  switch (type) {
    case "live":
      return ActionDropdown.filter((action) =>
        ["Preview", "Edit", "Archive"].includes(action.label)
      );
    case "engaged":
      return ActionDropdown.filter((action) =>
        ["Preview", "Proposals", "Mark as Complete"].includes(action.label)
      );
    case "draft":
      return ActionDropdown.filter((action) =>
        ["Edit", "Archive"].includes(action.label)
      );
    case "completed":
      return ActionDropdown.filter((action) =>
        ["Preview"].includes(action.label)
      );
    default:
      return [];
  }
};
const BidCard = ({
  data,
  type,
  fetchData,
  selectedBid,
  setSelectedBid,
  fetchAfterComplete,
  showActions=true
}: BidCardProps) => {
  const toast = useToast();
  const {
    isOpen: isDeleteOpen,
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
  } = useDisclosure();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpen, setIsOpen] = useState({ id: "", value: false });
  const buttonRef = useRef<any>(null);
  const menuRef = useClickOutside(() => setIsOpen({ id: "", value: false }));
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });

  const filteredActions = filterActions(type);

  const mouseClick = (e: any) => {
    e.stopPropagation();
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setMenuPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX - 100,
      });
    }
  };
  const toggleDropdown = () => {
    if (isOpen.value) {
      setIsOpen({ id: "", value: false });
    } else {
      setIsOpen({ id: data?.id || "1", value: true });
    }
  };
  const handleCompletBid = (companyId: string, userId: string) => {
    useFetch
      .get(`completebid/${companyId}/${userId}`)
      .then((response) => {
        toast({
          title: "Successfully Marked as completed",
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        fetchAfterComplete();
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: "Something went wrong",
          description: "Please try again",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };
  const handleDelete = () => {
    setIsSubmitting(true);
    useFetch
      .get(`deletebid/${data?.id}`)
      .then((res) => {
        setIsSubmitting(false);

        toast({
          title: "Bid Deleted Successfully",
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        fetchData();
      })
      .catch((error) => {
        setIsSubmitting(false);

        console.log("error", error);
        toast({
          title: "Something went wrong",
          description: "Please try again",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };
  const showDetail = (data: any) => {
    setSelectedBid({
      id: data?.id,
      bidId: data?.bid_id,
      action: "requirements",
    });
    toggleDropdown();
  };
  const showProposals = (data: any) => {
    setSelectedBid({ id: data?.id, bidId: data?.bid_id, action: "proposals" });
    toggleDropdown();
  };
  const showRequests = (data: any) => {
    setSelectedBid({
      id: data?.id,
      bidId: data?.bid_id,
      action: "requests",
    });
    // toggleDropdown();
  };
  return (
    <Flex
      key={data}
      direction="column"
      gap="17px"
      width="100%"
      className={
        selectedBid?.data?.id === data.id ? "bid-card-selected" : "bid-card-bg"
      }
      // bg={selectedBid?.data?.id===data.id ? 'black': ''}
      padding="12px"
      position="relative"
      // onClick={
      //   type === "engaged"
      //     ? setSelectedBid({ data: data, action: "proposal" })
      //     : null
      // }
      // cursor={type === "engaged" ? "pointer" : "default"}
    >
      <Flex width="100%" justify="space-between" align="center">
        <Flex width="100%" align="center" gap="8px">
          <Flex
            bg={type === "draft" ? theme.color.YELLOW : theme.color.GREEN}
            height="15px"
            width="15px"
            borderRadius="full"
          />
          <Text
            fontSize="11px"
            fontWeight="400"
            color={
              selectedBid?.data?.id === data.id
                ? "rgba(131, 131, 131, 1)"
                : "rgba(0, 0, 0, 0.50)"
            }
          >
            {type === "live"
              ? "Active"
              : type === "completed"
              ? "Completed"
              : "Paused"}{" "}
            |{" "}
            {data?.start_date
              ? moment(data?.start_date).format("D MMMM, yyyy")
              : "N/A"}{" "}
            -{" "}
            {data?.end_date
              ? moment(data?.end_date).format("D MMMM, yyyy")
              : "N/A"}
          </Text>
        </Flex>
        <Text
          bg="black"
          color={selectedBid?.data?.id === data.id ? "white" : "#F6F6F6"}
          shadow="md"
          padding="2px 10px 3px 10px"
          borderRadius="12px"
          height="21px"
          width="auto"
          fontWeight="500"
          textAlign="center"
          whiteSpace="nowrap"
          border={
            selectedBid?.data?.id === data.id
              ? "1px solid rgba(131, 131, 131, 1)"
              : "1px solid #F6F6F6"
          }
          fontSize="11px"
          // onClick={() => navigate(`/bid-list/${data.id}`)}
          onClick={() => showRequests(data)}
          cursor="pointer"
        >
          {data?.bidders || "0"} {data?.bidders > 1 ? "bidders" : "bidder"}
        </Text>
      </Flex>
      <Flex width="100%" align="center" gap="8px">
        <Text
          fontSize="15px"
          fontWeight="700"
          color={selectedBid?.data?.id === data.id ? "white" : "black"}
          whiteSpace="nowrap"
        >
          #{data?.id} {data?.product} |
        </Text>
        <Text
          fontSize="12px"
          fontWeight="700"
          color={
            selectedBid?.data?.id === data.id
              ? "rgba(131, 131, 131, 1)"
              : theme.color.GRAY_LABEL
          }
          whiteSpace="nowrap"
        >
          Expected Premium: ₹{data?.expected_premium}
        </Text>
      </Flex>
      <Flex width="100%" align="center" justify="space-between">
        <Flex width="100%" align="center" gap="8px">
          <Flex
            padding="4px 14px"
            align="center"
            justify="center"
            borderRadius="12px"
            bg="white"
            fontSize="10px"
            fontWeight="500"
            color="black"
          >
            Type: {data?.policy_type}
          </Flex>
          <Flex
            padding="4px 14px"
            align="center"
            justify="center"
            borderRadius="12px"
            bg={type === "draft" ? theme.color.YELLOW : theme.color.GREEN}
            fontSize="10px"
            fontWeight="500"
            color="#F6F6F6"
            textTransform="capitalize"
          >
            {type === "draft" ? "Drafted" : type}
            {/* Match: {data % 2 === 0 ? "Good" : "Fair"} */}
          </Flex>
        </Flex>
        {false ? (
          <Button
            height="24px"
            // w="152px"
            color={
              selectedBid?.data?.id === data.id
                ? "rgba(131, 131, 131, 1)"
                : "black"
            }
            border="1px solid"
            borderColor={
              selectedBid?.data?.id === data.id
                ? "rgba(131, 131, 131, 1)"
                : theme.color.GRAY_LABEL
            }
            rightIcon={<FaCircleCheck />}
            borderRadius="24px"
            fontSize="12px"
            fontWeight="500"
            onClick={() => handleCompletBid(data?.id, data?.bidder_id)}
          >
            Mark as complete
          </Button>
        ) : (
         <>
         {showActions? <Flex direction="column" position="relative">
            <Flex
              ref={buttonRef}
              w="99px"
              transition="all 0.2s"
              borderRadius="24px"
              borderWidth="1px"
              _hover={{ bg: "gray.700", color: "white" }}
              _focus={{ boxShadow: "outline" }}
              _expanded={{ bg: "black", color: "white" }}
              bg={isOpen.value ? "black" : "white"}
              color={isOpen.value ? "white" : "black"}
              onClick={(e) => {
                toggleDropdown();
                mouseClick(e);
              }}
              justify="space-evenly"
              align="center"
              cursor="pointer"
              zIndex={isOpen ? 1000 : "auto"}
            >
              <Text fontSize="12px" fontWeight="500">
                Actions{" "}
              </Text>
              {isOpen.value ? (
                <Icon as={RxCross1} fontSize="14px" />
              ) : (
                <Icon as={IoIosArrowDown} fontSize="16px" />
              )}
            </Flex>
            {isOpen.value &&
              createPortal(
                <Flex
                  ref={menuRef}
                  direction="column"
                  p="20px 0px 10px"
                  w="207px"
                  bg="black"
                  color="white"
                  position="absolute"
                  top={`${menuPosition.top}px`}
                  left={`${menuPosition.left}px`}
                  gap="12px"
                  borderRadius="16px"
                  zIndex="1000"
                >
                  <Text color="white" px="20px" fontSize="15px">
                    Actions:
                  </Text>
                  <Flex direction="column" gap="6px">
                    {filteredActions?.map((item: any) => {
                      if (
                        data?.bidders >= 1 &&
                        (item.label === "Archive" || item.label === "Edit")
                      )
                        return;
                      return (
                        <Flex
                          align="center"
                          w="100%"
                          justify="space-between"
                          color="white"
                          p="10px 20px"
                          _hover={{ bg: "gray.600", color: "gray.100" }}
                          cursor="pointer"
                          onClick={() => {
                            if (item.label === "Preview") {
                              showDetail(data);
                            } else if (item.label === "Archive") {
                              onDeleteOpen();
                            } else if (item.label === "Edit") {
                              navigate(`/edit-bid/${data?.id}`);
                            } else if (item.label === "Proposals") {
                              showProposals(data);
                            } else if (item.label === "Mark as Complete") {
                              handleCompletBid(data?.id, data?.bidder_id);
                            }
                          }}
                        >
                          <Flex align="center" gap="8px">
                            <Icon as={item.icon} fontSize="16px" />
                            <Text fontSize="12px" fontWeight="500">
                              {item.label}
                            </Text>
                          </Flex>
                          {/* <Icon as={IoIosArrowForward} fontSize="12px" /> */}
                        </Flex>
                      );
                    })}
                  </Flex>
                </Flex>,
                document.body
              )}
          </Flex> : <Flex minW='40px' />}
         </>
        )}
      </Flex>
      <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
        <ModalOverlay />
        <ModalContent borderRadius="21px" pb="35px">
          <ModalCloseButton />
          <ModalBody pt="50px">
            <Flex direction="column" align="center">
              <Text fontWeight="600">
                Are you sure you want to Delete this Bid
              </Text>
              <Text fontSize="14px">
                Once deleted, this bid will be inactivated!
              </Text>
            </Flex>
            <Flex align="center" gap="20px" w="full" justify="center" mt="20px">
              <Button borderRadius="21px" onClick={onDeleteClose}>
                Cancel
              </Button>
              <Button
                borderRadius="21px"
                colorScheme="red"
                onClick={handleDelete}
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
              >
                Delete
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default BidCard;
