import Cookies from "js-cookie";
import { useMemo } from "react";
import useFetch from "./Interceptor/Interceptor";

export const useFetchtoken = () => {
  return useMemo(() => {
    const token = Cookies.get("jwt");
    return token ? token : null;
  }, []);
};
export const useFetchUser = () => {
  return useMemo(() => {
    const user = Cookies.get("user");
    return user ? JSON.parse(user) : null;
  }, [Cookies.get("user")]);
};
export const fetchProfileStatus = async () => {
  try {
    const res = await useFetch.get("bidder/check-profile");
    return res?.data?.status === "incomplete" ? false : true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
// export const fetchUserRole = async () => {
//   try {
//     const res = await useFetch.get("dashboard");
//     return res.data?.data?.user_type;
//   } catch (error) {
//     console.error(error);
//   }
// };
