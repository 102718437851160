import { Navigate, Outlet } from "react-router-dom";
import { useFetchtoken } from "../partials/utils/User";

const PublicRoutes = () => {
  const token = useFetchtoken();

  return !token ? <Outlet /> : <Navigate to="/" />;
  // return false ? <Outlet /> : <Navigate to="/home" />;
};
export default PublicRoutes;
