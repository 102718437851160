export const theme = {
  color: {
    NAVY_BLUE: "rgba(62, 57, 84, 1)",
    GRAY_FONT: "rgb(131 128 128 / 81%)",
    GRAY_LABEL: "rgba(8, 8, 8, 0.50)",
    GREEN: "rgba(29, 191, 115, 1)",
    YELLOW: "#F7990B",
    WHITE: "rgba(246, 246, 246, 1)",
    INPUT_FONT: "#2C3C4B",
  },
};

export const ProductsList = [
  "Burglary",
  "Commercial General Liability Insurance",
  "Contractors All Risk",
  "Contractors Plant and Machinery",
  "Directors & Offficers Insurance",
  "Employees/Workmen Compensation",
  "Group Health Insurance",
  "Group Personal Accident",
  "Group Term Life",
  "Lift Liability",
  "Money",
  "Professional Indemnity Insurance",
  "Specific Marine Insurance",
  "Standard Fire and Special Peril Policy - Annual"
];

