import { Button, Flex, Text } from "@chakra-ui/react";
import { TbFileStar } from "react-icons/tb";

import BidCard from "../../components/BidCard";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useFetch from "../../partials/utils/Interceptor/Interceptor";

const ArchivedBids = () => {
  const navigate = useNavigate();
  const [archivedBidsList, setArchivedBidsList] = useState([]);
  function fetchArchivedBids() {
    useFetch
      .get(`inactivebid`)
      .then((res) => {
        setArchivedBidsList(res?.data?.data?.inactive);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  useEffect(() => {
    fetchArchivedBids();
  }, []);
  return (
    <Flex height="100%" width="100%">
      <Flex
        direction="column"
        padding="30px 80px 30px 30px"
        flex={2}
        gap="40px"
      >
        <Flex align="center" w="100%" justify="space-between">
          <Text as="h2" fontSize="24px" fontWeight="500">
            Archived Bids
          </Text>
          <Button
            rightIcon={<TbFileStar />}
            borderRadius="24px"
            bg="black"
            colorScheme="black"
            color="#F6F6F6"
            w="143px"
            height="24px"
            fontSize="10px"
            fontWeight="500"
            onClick={() => navigate("/create-bid")}
          >
            Create New Bid
          </Button>
        </Flex>
        <Flex
          direction="column"
          w="100%"
          align="center"
          gap="12px"
          height="82vh"
          overflowY="auto"
          className="hide-scrollbar"
        >
          {archivedBidsList.map((item) => (
            <BidCard data={item} type="inactive" showActions={false} />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ArchivedBids;
