export const handleLogoutLocalStorage = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("data");
  localStorage.removeItem("profilepercentage");
  localStorage.clear();
};
export const flattenObject = (obj: any, res: any = {}) => {
  for (let key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      flattenObject(obj[key], res);
    } else {
      res[key] = obj[key];
    }
  }
  return res;
};
export const addMissingFieldsToLabelMapping = (
  data: { [key: string]: any },
  labelMapping: { [key: string]: string }
) => {
  Object.keys(data).forEach((key) => {
    if (!(key in labelMapping)) {
      labelMapping[key] = key.replace(/_/g, " "); // Adds the key as label, replacing underscores with spaces
    }
  });
};
export const addCommas = (num: any) =>
  num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const removeNonNumeric = (num: any) =>
  num?.toString()?.replace(/[^0-9]/g, "");